import React, { Component } from 'react';
import { Card, Container } from "reactstrap";
import { GOOGLE_CLIENT_ID } from '../../common/constants';
import { LOGIN_URL } from '../../common/url';
import { GoogleLogin, GoogleLogout } from '@dump-work/react-google-login';
import { withNavigateHook } from '../../common/navigate';


class Login extends Component {
    constructor(props, context) {
        super(props, context);
        this.processGoogleLoginSuccess = this.processGoogleLoginSuccess.bind(this);
    }

    // On login failure
    responseGoogle(googleUser) {

    }

    async processGoogleLoginSuccess(googleUser) {
        //alert("prevPath="+ JSON.stringify(this.props));
        var payload = {};
        var user = {};
        user.type = "google";
        var profile = googleUser.profileObj;
        user.id_token = googleUser.tokenObj.id_token;
        payload.user = user;

        let loginObject = {};
        loginObject.name = profile.name;
        loginObject.email = profile.email;
        loginObject.picture = profile.imageUrl;
        // this.props.login(loginObject);
        var myHeaders = new Headers();
        myHeaders.append('mydata', JSON.stringify(payload));

        fetch(LOGIN_URL,
            {
                method: 'POST',
                headers: myHeaders,
                cache: 'default',
                mode: 'cors',
                credentials: 'include',
                body: JSON.stringify(payload)
            }).then(response => {
                return response.json();
            }).then(jsonResponse => {
                //alert(JSON.stringify(jsonResponse, null, 4));
                var isFirstTimeUser = jsonResponse.new_user;
                if (typeof isFirstTimeUser != 'undefined') {
                    loginObject.isFirstTimeUser = isFirstTimeUser;
                }
                // Redirect value needs to be changed to '/Portfolio' 

                //sessionStorage.setItem('loginObject', JSON.stringify(loginObject));
                this.props.login(loginObject);
                this.props.navigation({ pathname: '/WatchList', loginObject: loginObject });
            }).catch(function (err) {
                alert(err.toString());
                console.log(err);
            });
    }

    render() { 
        let containerClassValue = "my-5 mx-auto";
        return (
            <Container className={containerClassValue}>
                <Card className="xl-6 lg-4 sm-12 my-1 justify-content-md-center">
                    <div className="mx-auto mt-5 mb-5">
                        {this.props.isLoggedIn ?
                            <GoogleLogout
                                clientId={GOOGLE_CLIENT_ID + '.apps.googleusercontent.com'}
                                buttonText="Logout"
                                onLogoutSuccess={this.processLogout}
                            ></GoogleLogout> :
                            <GoogleLogin
                                clientId={GOOGLE_CLIENT_ID + '.apps.googleusercontent.com'}
                                buttonText="Login with Google"
                                onSuccess={this.processGoogleLoginSuccess}
                                onFailure={this.processGoogleLoginFailure}
                                cookiePolicy={'single_host_origin'}
                            />
                        }
                    </div>
                </Card>
            </Container>
        );
    }
}

export default withNavigateHook(Login);