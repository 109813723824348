import React, { Component } from "react";
import { Chart } from "react-google-charts";
import { Badge, Col, Row, Spinner } from "reactstrap";
import { GRAPH_TIME_PERIODS } from "../../common/constants";
import { isMobile } from "react-device-detect";
import { SHORT_DAYS, SHORT_MONTHS } from "../../common/timeUtil";

export default class GoogleChart extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            selectedPeriod: GRAPH_TIME_PERIODS[0]
        }
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (this.state.selectedPeriod === nextState.selectedPeriod &&
            this.props.ticker === nextProps.ticker &&
            this.props.historical === nextProps.historical &&
            this.props.current === nextProps.current) {
            return false;
        }
        return true;
    }

    componentDidUpdate = () => {
        /**
        let renderKey = ['1D', '5D'].includes(this.state.selectedPeriod) ? 'current' : 'historical';
        if (renderKey === 'historical' && this.props[renderKey].data.length < 1) {
            this.props.updateHistorical();
        }
        */
    }

    updateChartPeriod = period => {
        if (!['1D', '1W', '4W', '8W'].includes(period) && this.props.historical.data.length < 1) {
            this.props.updateHistorical();
        }
        this.setState({ selectedPeriod: period });
    }

    render = () => {
        let current_periods = ['1D', '1W', '4W', '8W'];
        let period = this.state.selectedPeriod;
        let renderKey = current_periods.includes(period) ? 'current' : 'historical';
        let data = this.props[renderKey].data;
        let index = this.props[renderKey].index[period];
        let chart = '';
        if (data.length > 1) {
            let slicedData = data;
            if (period !== 'MAX') {
                slicedData = slicedData.slice(index, slicedData.length);
            }
            if (current_periods.includes(period)) {
                let arr = [];
                for (let i = 0; i < slicedData.length; i++) {
                    let dt = slicedData[i][0];
                    let dtStr = (period === '1W' ? SHORT_DAYS[dt.getDay()] + ' ' :
                        ['4W', '8W'].includes(period) ? SHORT_MONTHS[dt.getMonth()] + ' ' + dt.getDate() + ' ' : '')
                        + dt.getHours().toString().padStart(2, '0') + ':' + dt.getMinutes().toString().padStart(2, '0');
                    arr.push([dtStr, slicedData[i][1]]);
                }
                slicedData = arr;
            }
            else if (['6M', 'YTD', '1Y'].includes(period)) {
                let arr = [];
                for (let i = 0; i < slicedData.length; i++) {
                    let dt = slicedData[i][0];
                    let dtStr = SHORT_MONTHS[dt.getMonth()] + ' ' + dt.getDate();
                    arr.push([dtStr, slicedData[i][1]]);
                }
                slicedData = arr;
            }
            /**
            if (slicedData.length > 1000) {
                let skipPoints = Math.ceil(slicedData.length / 500);
                let arr = [];
                let j = 0;
                for (let i = 0; i < slicedData.length; i += skipPoints) {
                    arr.push(slicedData[i]);
                    j = i;
                }
                if (j !== (slicedData.length - 1))
                    arr.push(slicedData[slicedData.length - 1]);
                slicedData = arr;
            }
            */
            let chartColor = '#50C878';
            if (slicedData[0][1] > slicedData[slicedData.length - 1][1]) {
                chartColor = '#FF2400';
            }
            //let ticksCount = period === '1D' ? 10 : period === '5D' ? 5 : 7;
            //let tickerGap = Math.round(slicedData.length / ticksCount);
            let chartLeft = isMobile ? '12%' : '7%';
            let chartOptions = {
                /**
                viewWindow: {
                    min: slicedData[0][0],
                    max: slicedData[slicedData.length - 1][0]
                },
                */
                chartArea: { left: chartLeft, width: '90%', height: '90%' },
                colors: [chartColor],
                legend: 'none',
                curveType: 'function',
                theme: 'material',
                hAxis: {
                    slantedText: false,
                    //showTextEvery: tickerGap,
                    //format: period === 'MAX' ? 'MMM dd, yyyy' : '',
                    //type: 'date'
                }
            };
            let chartData = [[['3Y', '5Y', 'MAX'].includes(period) ? { type: 'date', label: 'Date' } : 'Date', 'Price']].concat(slicedData);
            let loadRender = <Row className="h-100 justify-content-center"><Col xl={1} className="border my-auto">
                <Spinner color="primary" animation="border" role="status" />
            </Col></Row>;
            chart = <Chart
                height={isMobile ? "300px" : "450px" }
                width="100%"
                chartType="AreaChart"
                loader={loadRender}
                options={chartOptions}
                data={chartData} />;
        }
        return <Row className={"border px-0 rounded " + (isMobile ? "" : "mx-0")}>
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className="py-0 text-center mx-auto">
                <Row>
                    <Col></Col>
                    {GRAPH_TIME_PERIODS.map(period => (
                        <Col xl="auto" lg="auto" md="auto" sm="auto" xs="auto" className="px-0">
                            <Badge color={this.state.selectedPeriod === period ? 'success' : 'light'}
                            className={this.state.selectedPeriod === period ? "" : "text-dark"} href="#"
                                onClick={() => this.updateChartPeriod(period)} pill>
                                {period}
                            </Badge></Col>
                    ))}
                    <Col></Col>
                </Row>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className="px-0 py-0 my-0">{chart}</Col>
        </Row>;
    }
}
