import React, { Component } from "react";
import {
    Modal, ModalHeader, ModalBody, Button, Row, Col, Input, Label
} from 'reactstrap';

export default class CreatePortfolio extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            name: 'My Portfolio',
            amount: 10000
        }
    }

    create_portfolio = () => {
        if (this.state.name.length > 0 && this.state.amount > 0) {
            this.props.createPortfolio(this.state.name,this.state.amount);
        }
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.create_portfolio();
        }
    }

    render() {
        let closeCreatePortfolioWindow = this.props.closeCreatePortfolioWindow;

        return (
            <Modal isOpen={true} toggle={() => closeCreatePortfolioWindow()} size="sm" centered={true}>
                <ModalHeader className="justify-content-center"> Create portfolio</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={3}><Label size="sm">Name: </Label></Col>
                        <Col className="my-2">
                            <Input type="text" size="sm" value={this.state.name}
                                onChange={(e) => this.setState({ name: e.target.value })}
                                id="portfolio" placeholder="portfolio Name"
                                onKeyDown={(e) => this.handleKeyDown(e)} />
                        </Col>
                    </Row>
                    <Row>
                    <Col xl={3}><Label size="sm">Amount: </Label></Col>
                        <Col>
                        <Input type="number" size="sm" value={this.state.amount}
                            onChange={(e) => this.setState({ amount: e.target.value })}
                            id="portfolio" placeholder="portfolio amount"
                            onKeyDown={(e) => this.handleKeyDown(e)} />
                        </Col>
                        <Col className="mx-2" xl={2}>
                            <Button color="primary" size="sm" onClick={this.create_portfolio} style={{ marginBottom: '1rem' }}>+</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}