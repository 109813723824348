import React, { Component } from 'react';
import { Row, Col, Table, Nav, NavItem, NavLink, Input, Spinner, Badge, Card, CardText } from 'reactstrap';
import { number_full_to_short_form, formatPercent } from '../../common/numberUtil';
import { ANNUAL, FINANCIAL_METRICS, METRICS_ORDER, BOLD_METRICS, PERCENT_METRICS, QUARTERLY, LINK_METRICS } from './constants';
import classnames from 'classnames';
import { DISPLAY_NAME_MAP } from '../../common/constants';
import { isMobile } from 'react-device-detect';

export default class FinancialsDisplay extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            show_spinner: false,
            quarterlySelected: true,
            selectedMetricIndex: 0,
            currency_selected: "USD"
        };
    }

    componentDidUpdate = () => {
        /*
          let period = this.state.quarterlySelected ? QUARTERLY : ANNUAL;
          if (Object.keys(this.props[period]).length < 1) {
              this.props.fetchFinancials(null, period);
          }
          */
    }

    switchMetricPeriod = async () => {
        let period = !this.state.quarterlySelected ? QUARTERLY : ANNUAL;
        this.setState({ quarterlySelected: !this.state.quarterlySelected });
        if (Object.keys(this.props[period]).length < 1) {
            this.props.fetchFinancials(null, period);
        }
    }

    renderMetric = (metricName, metric, currency_multiplier, showBold = true) => {
        let dataArr = [];
        if (metric || null) {
            dataArr = metric.split(',');
        } else
            return;

        if (!['Date', 'date', '10K'].includes(metricName)) {
            let nonZeroMetricFound = false;
            for (let i = 0; i < dataArr.length; i++) {
                if (!isNaN(dataArr[i]) && (dataArr[i] > 0 || dataArr[i] < 0)) {
                    nonZeroMetricFound = true;
                    break;
                }
            }
            if (!nonZeroMetricFound)
                return '';
        }
        if (this.state.currency_selected !== "USD")
            currency_multiplier = 1;

        let formatData = data => {
            return isNaN(data) ?
                LINK_METRICS.includes(metricName) ? <a href={data} target="_blank" rel="noopener noreferrer">
                    <span className="text-success">{metricName}</span></a> : data
                : data === 0 ? '' :
                    PERCENT_METRICS.includes(metricName) ?
                        data > 0 ? <span className="text-success"> {formatPercent(data)} </span> :
                            <span className="text-danger"> {formatPercent(data)} </span> :
                        number_full_to_short_form(data * currency_multiplier);
        }

        return (showBold && BOLD_METRICS.includes(metricName)) ?
            <tr className="table-secondary text-nowrap">
                <th>{metricName}</th>
                {dataArr.map(data => (<th>{formatData(data)}</th>))}
            </tr> :
            <tr className="text-nowrap">
                <th>{metricName}</th>
                {dataArr.map(data => (<td>{formatData(data)}</td>))}
            </tr>;
    }

    render = () => {
        if (this.state.show_spinner)
            return <Row className="mt"> <Col xl={12} md={12} xs={12} align="center">
                <div height="500px" align="center">
                    <Spinner color="primary" animation="border" role="status" />
                </div>
            </Col>
            </Row>

        let period = this.state.quarterlySelected ? QUARTERLY : ANNUAL;
        let data = this.props[period];
        if (Object.keys(data).length < 1)
            return '';
        let financialData = data[FINANCIAL_METRICS[this.state.selectedMetricIndex]];
        if (!(financialData || null))
            return '';

        let currency_multiplier = "exchange rate" in data ? data["exchange rate"] : 1;
        let renderData = METRICS_ORDER[FINANCIAL_METRICS[this.state.selectedMetricIndex]].map((metric, i) => (
            this.renderMetric(metric, metric in financialData ? financialData[metric] : financialData['date'], currency_multiplier,
                this.state.selectedMetricIndex !== 0 || i === 0)
        ));
        let currencyRender = "";
        if ("currency" in data) {
            currencyRender = <Row>
                <Col className="mx-o px-0">
                    <Badge color={this.state.currency_selected === "Other" ? "primary" : "secondary"} style={{ "cursor": "pointer" }}
                        onClick={(e) => this.setState({ "currency_selected": "Other" })} pill >
                        {data.currency}
                    </Badge>
                </Col>
                <Col className="mx-o px-0">
                    <Badge color={this.state.currency_selected === "USD" ? "primary" : "secondary"} style={{ "cursor": "pointer" }}
                        onClick={(e) => this.setState({ "currency_selected": "USD" })} pill>
                        USD
                    </Badge>
                </Col>
            </Row>;
        }
        return <Row className={"border rounded " + (isMobile ? "" : "mx-0")}>
            <Col xl={12} md={12} sm={12} xs={12} className="mx-0 px-0">
                <Card className={"mb-1 h-100% w-100% bg-secondary"}>
                    <CardText className="px-1 py-0 text-center text-white">
                        Financials
                    </CardText>
                </Card>
            </Col>
            <Col>
                <Row className="border">
                    <Col xl={10} md={8} xs={12} className="px-0">
                        <Nav tabs pills style={isMobile ? { "flex-wrap": "nowrap", "overflow-x": "scroll", "display": "flex" } : {}}>
                            {FINANCIAL_METRICS.map((metric, i) => (
                                <NavItem style={isMobile ? { "flex": "0 0  auto" } : {}}>
                                    <NavLink className={classnames({ active: this.state.selectedMetricIndex === i })} href="#"
                                        onClick={() => { this.setState({ selectedMetricIndex: i }) }} >
                                        {DISPLAY_NAME_MAP[FINANCIAL_METRICS[i]]}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav></Col>
                    <Col xl={1} md={2} xs={6}>{currencyRender}</Col>
                    <Col xl={1} lg={2} md={2} xs={6} >
                        <Row><Col xl={3} xs="auto" className="mx-auto my-auto">
                            <Input type="checkbox" checked={this.state.quarterlySelected}
                                onClick={this.switchMetricPeriod}>
                            </Input>
                            </Col><Col xl={9} xs="auto" className="mx-auto my-auto px-1 py-1">
                            <h6 className="text-primary ">Quarterly</h6></Col></Row>
                    </Col>
                </Row>
                <Row ><Col className="px-0">
                    <Table size="sm" responsive striped hover bordered>
                        {renderData}
                    </Table></Col></Row>
            </Col></Row>;
    }
}
