import React, { Component } from 'react';
import { Card, Input, Row, Col, Badge, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import {
    MKT_CAP_RANGES, INDUSTRIES, SECTORS, INDEX_GROUPS, MULTI_VALUE_METRIC_LIST,
    NAME_TO_NUMBER_FORMAT_METRICS, PERCENT_FORMAT_METRICS
} from '../../common/constants';
import { number_full_to_short_form, number_short_to_full_form } from '../../common/numberUtil';
import "../../css/date-picker.css";
import { DEFAULT_FILTERS, RANGE_METRICS_RENDER_MAP, LIST_METRICS_DISPLAY_NAMES } from './constants';
import { get_default_filters_deep_clone, get_deep_clone } from './util';
import { areTwoDatesEqual } from '../../common/timeUtil';
import { isMobile } from 'react-device-detect';

class StockScreenInput extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            filtersEnabled: {
                return: true,
                beta: true,
                mktCap: true,
                'revenue ttm': true,
                'net income ttm': true,
                'p/e': true,
                'p/s': true,
                sector: false,
                industry: false,
                index_group: false
            },
            selected_duration: '1 year',
            input_data: get_default_filters_deep_clone(),
        }

        let get_list_data = input_list => {
            let max = input_list.length > 60 ? 60 : input_list.length;
            let return_data = {};
            for (let i = 0; i < max; i++) {
                return_data[input_list[i]] = { selected: false };
            }
            return return_data;
        };

        this.state['sector'] = get_list_data(SECTORS);
        this.state['industry'] = get_list_data(INDUSTRIES);
        this.state['index_group'] = get_list_data(INDEX_GROUPS);
        this.state['mktCap'] = get_list_data(Object.keys(MKT_CAP_RANGES));
    }

    onChangeHandler = async (key1, key2, val) => {
        let obj = this.state.input_data;
        for (let i = 0; i < obj.length; i++) {
            let item = obj[i];
            if (key1 in item) {
                if (NAME_TO_NUMBER_FORMAT_METRICS.includes(key1)) {
                    let regExpr = new RegExp('^[0-9]*(.[0-9]+)?(K|M|B|T)?$');
                    if (!regExpr.test(String(val).toUpperCase())) {
                        continue;
                    }
                    val = number_short_to_full_form(val);
                }
                if (!(val instanceof Date) && PERCENT_FORMAT_METRICS.includes(key1)) {
                    val = val / 100;
                }
                item[key1][key2] = val;
            }
        }
        this.setState({ input_data: obj });
    }

    updateDates = async duration => {
        let obj = this.state.input_data;
        for (let i = 0; i < obj.length; i++) {
            if ('return' in obj[i]) {
                let endDate = obj[i].return['end date'];
                if (!areTwoDatesEqual(endDate, new Date())) {
                    await this.onChangeHandler('return', 'end date', new Date());
                    break;
                }
            }
        }
        await this.set_start_date(duration);
        this.construct_screen_stocks_request()
    }

    set_start_date = async (duration) => {
        let date = new Date();
        if (duration === 'YTD') {
            date = new Date(date.getFullYear(), 0, 1);
        } else {
            var duration_fields = duration.split(" ");
            if (duration_fields[1].toLowerCase().startsWith("mon")) {
                let number_of_months = duration_fields[0];
                date.setMonth(date.getMonth() - number_of_months);
            } else if (duration_fields[1].toLowerCase().startsWith("year")) {
                let number_of_years = duration_fields[0];
                date.setFullYear(date.getFullYear() - number_of_years);
            }
        }
        await this.onChangeHandler('return', 'start date', date);
        this.setState({ selected_duration: duration });
    }

    construct_screen_stocks_request = async () => {
        let data = {};
        let input_data = this.state.input_data;
        for (let i = 0; i < input_data.length; i++) {
            let item = input_data[i];
            for (let metric in item) {
                // If metric is disabled then don't add in the request.
                if (this.state.filtersEnabled[metric] === false) {
                    continue;
                }
                let metric_data = get_deep_clone(item[metric], true);
                // Remove all the fields if the values are null.
                for (let metric_field in metric_data) {
                    if (!(metric_data[metric_field] || null)) {
                        delete metric_data[metric_field];
                    }
                }
                // Add metric data to request data if it contains any fields.
                if ((metric_data || null) && Object.keys(metric_data).length > 0) {
                    // If metric is return then start date is must along with either low or high.                 
                    if (metric === "return") {
                        if (!metric_data['start date'] ||
                            (!(metric_data.low || null) && !(metric_data.high || null))) {
                            continue;
                        }
                    }
                    data[metric] = metric_data;
                }
            }
        }

        // If metric is sector or industry or index_group
        for (let index = 0; index < MULTI_VALUE_METRIC_LIST.length; index++) {
            let metric = MULTI_VALUE_METRIC_LIST[index];
            if (this.state.filtersEnabled[metric] === false) {
                continue;
            }
            let multivalue_metric_data = this.state[metric];
            let selected_multivalue_metric_list = [];
            for (let mertic_field in multivalue_metric_data) {
                if (multivalue_metric_data[mertic_field].selected === true) {
                    selected_multivalue_metric_list.push(mertic_field);
                }
            }
            if ((selected_multivalue_metric_list || null) && selected_multivalue_metric_list.length > 0) {
                data[MULTI_VALUE_METRIC_LIST[index]] = selected_multivalue_metric_list;
            }
        }
        this.props.onSubmit(data, this.state.filtersEnabled.return);
    }

    set_return_start_date = async date => {
        this.setReturnDate('start date', date);
    }

    setReturnDate = async (dt_id, dt_to_set) => {
        this.setState({ selected_duration: 'custom' });
        await this.onChangeHandler('return', dt_id, dt_to_set);
        this.construct_screen_stocks_request();
    }

    set_return_end_date = date => {
        this.setReturnDate('end date', date);
    }

    get_default_metric = async (metric_name) => {
        let metric_obj = null;
        DEFAULT_FILTERS.forEach(function (metric) {
            if (metric_name in metric) {
                metric_obj = metric;
            }
        });
        return metric_obj;
    }

    update_mkt_cap_range = async () => {
        let selected_range_values = [];
        let market_cap_badges = this.state.mktCap;
        for (let market_cap_type in market_cap_badges) {
            let market_cap_type_obj = market_cap_badges[market_cap_type];
            if (market_cap_type_obj.selected === true) {
                selected_range_values.push(MKT_CAP_RANGES[market_cap_type].low);
                selected_range_values.push(MKT_CAP_RANGES[market_cap_type].high);
            }
        }

        let mktCap_obj = await this.get_default_metric("mktCap");
        var low = mktCap_obj.mktCap.low;
        var high = mktCap_obj.mktCap.high;
        if (selected_range_values.length > 0) {
            low = Math.min(...selected_range_values);
            high = Math.max(...selected_range_values);
        }
        this.onChangeHandler('mktCap', 'low', low);
        this.onChangeHandler('mktCap', 'high', high);
    }

    set_label = async (key, label) => {
        let obj = this.state[key];
        obj[label].selected = !obj[label].selected;
        this.setState({ key: obj });
        if (key === 'mktCap') {
            await this.update_mkt_cap_range();
        }
        this.construct_screen_stocks_request();
    }

    set_filter_enabled = async (metric, val) => {
        let filtersEnabled = this.state.filtersEnabled;
        filtersEnabled[metric] = val;
        this.setState({ filtersEnabled: filtersEnabled });
        this.construct_screen_stocks_request();
    }

    focusOutHandler = metric => {
        this.construct_screen_stocks_request();
    }

    render = () => {
        let cardClass = "mb-1 bg-light";

        let render_return = () => {
            let return_dates = [
                { 'date_str': 'start date', 'date_func': this.set_return_start_date },
                { 'date_str': 'end date', 'date_func': this.set_return_end_date }
            ];

            let return_durations = ['YTD', '3 Mon', '6 Mon', '1 year', '3 Years', '5 Years', '10 Years'];
            let data = this.state.input_data;
            let return_obj = {}
            for (let i = 0; i < data.length; i++) {
                if (Object.keys(data[i])[0] === 'return') {
                    return_obj = data[i].return;
                }
            }
            let datePickersRender = this.state.filtersEnabled["return"] ?
                return_dates.map(d => (
                    <Col xl={4} md={4} xs={6}>
                        <DatePicker
                            maxDate={new Date()}
                            selected={return_obj[d.date_str]}
                            dateFormat='yyyy-MM-dd'
                            onChange={d.date_func}
                        />
                    </Col>
                ))
                : "";
            let returnCheckBox = <><Input type="checkbox" checked={this.state.filtersEnabled.return}
                onChange={e => this.set_filter_enabled('return', e.target.checked)} />
                <Label><small>Return</small></Label></>;
            let mobileRender = <><Row className="justify-content-center"><Col xs="auto">{returnCheckBox}</Col></Row>
                <Row><Col>Start Date</Col><Col>End Date</Col></Row>
                <Row>{datePickersRender}</Row>
            </>;
            return isMobile ? mobileRender :
                <>
                    {
                        this.state.filtersEnabled["return"] ?
                            <Row className="mb-1">
                                <Col xl={4} md={4} xs={4}></Col>
                                <Col xl={4} md={4} xs={4}>Start date</Col>
                                <Col xl={4} md={4} xs={4}>End Date</Col>
                            </Row>
                            : ""
                    }
                    <Row>
                        <Col xl={4} md={4} xs={4}>
                            <Row>
                                <Col align="left" className="ml-4">
                                    {returnCheckBox}
                                </Col>
                            </Row>
                        </Col>
                        {datePickersRender}
                    </Row>
                    {this.state.filtersEnabled["return"] ?
                        <Row className="mb-3 mt-1" >
                            <Col xl={4} md={4} xs={4}> </Col>
                            <Col xl="auto" xs="auto" md="auto" sm="auto" lg="auto">
                                {return_durations.map(duration => (
                                    <>
                                        <span> </span>
                                        <Badge color={duration === this.state.selected_duration ? "success" : "secondary"}
                                            style={{ cursor: "pointer" }} onClick={() => this.updateDates(duration)} pill>
                                            <small> {duration} </small>
                                        </Badge>
                                    </>
                                ))}
                            </Col>
                        </Row>
                        : ""
                    }
                    <Row> <span>{' '}</span> </Row>
                </>;
        };

        let render_badges = key => {
            return <Row><Col xl="auto" xs="auto" md="auto" sm="auto" lg="auto">
                {Object.keys(this.state[key]).map(label => (<>
                    <Badge style={{ cursor: 'pointer' }} onClick={() => this.set_label(key, label)}
                        color={this.state[key][label].selected === true ? "success" : "secondary"} pill>
                        <small>{label}</small>
                    </Badge><span> </span></>
                ))}
            </Col></Row>;
        };

        let list_metric_render = (key) => {
            return <Row className="mb-2">
                <Col xl={2} md={2} xs={3} className="ml-4">
                    <Label for={LIST_METRICS_DISPLAY_NAMES[key].label}>
                        <Input type="checkbox" checked={this.state.filtersEnabled[key]}
                            onChange={e => this.set_filter_enabled(key, e.target.checked)} />
                        <small>{LIST_METRICS_DISPLAY_NAMES[key].label}</small>
                    </Label>
                </Col>
                <Col className="pl-0 pr-3">{isMobile && !this.state.filtersEnabled[key] ? '' : render_badges(key)}</Col>
            </Row>;
        };

        let get_metric_render_value = (key, val) => {
            let returnVal = val;
            if (NAME_TO_NUMBER_FORMAT_METRICS.includes(key)) {
                returnVal = number_full_to_short_form(returnVal);
            }
            if (PERCENT_FORMAT_METRICS.includes(key))
                returnVal = returnVal * 100;
            return returnVal;
        };

        let get_low_high_label_render_metric = () => {
            for (let metric in RANGE_METRICS_RENDER_MAP) {
                if (this.state.filtersEnabled[metric]) {
                    return metric;
                }
            }
            return "";
        }

        let renderText = <>
            <Card className={cardClass}>
                <Row className="mt-3 mx-0">
                    <Col xl={5} md={12} xs={12}>
                        <h6 size="sm">
                            {render_return()}
                            {this.state.input_data.map((item, index) => (
                                Object.keys(item).map(key => (
                                    <>
                                        {
                                            key === get_low_high_label_render_metric() ?
                                                < Row className="mb-1">
                                                    <Col xl={4} md={4} xs={4}></Col>
                                                    <Col xl={4} md={4} xs={4}>Low</Col>
                                                    <Col xl={4} md={4} xs={4}>High</Col>
                                                </Row>
                                                : ''
                                        }
                                        <Row>
                                            {
                                                key in RANGE_METRICS_RENDER_MAP ?
                                                    <>
                                                        <Col xl={4} md={4} xs={4}>
                                                            <Row className="mb-1">
                                                                <Col className="ml-4" align="left" style={{ valign: 'bottom' }}>
                                                                    {
                                                                        key !== 'return' ?
                                                                            <Input type="checkbox" checked={this.state.filtersEnabled[key]}
                                                                                onChange={e => this.set_filter_enabled(key, e.target.checked)} /> : ''
                                                                    }
                                                                    {
                                                                        key === 'return' && this.state.filtersEnabled[key] === false ?
                                                                            '' : <Label><small>{RANGE_METRICS_RENDER_MAP[key].label}</small></Label>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        {
                                                            this.state.filtersEnabled[key] ?
                                                                [['low', 'low_id'], ['high', 'high_id']].map(x => (
                                                                    <Col xl={4} md={4} xs={4} className="px-2">
                                                                        <Input type={['return', 'beta', 'p/e', 'p/s'].includes(key) ? 'number' : 'text'} size="sm" style={{ "font-size": "12px" }}
                                                                            value={get_metric_render_value(key, item[key][x[0]])}
                                                                            id={RANGE_METRICS_RENDER_MAP[key][x[1]]} placeholder={x[0]}
                                                                            onChange={(e) => { this.onChangeHandler(key, x[0], e.target.value) }}
                                                                            onBlur={(e) => this.focusOutHandler(key)} />
                                                                    </Col>
                                                                ))
                                                                : ""
                                                        }
                                                    </>
                                                    : ''
                                            }
                                        </Row>
                                        <Row>
                                            {
                                                key === 'mktCap' && this.state.filtersEnabled[key] ?
                                                    <>
                                                        <Col>   <Row className={"mb-2"}>
                                                            <Col xl={4} md={4} > </Col>
                                                            <Col> {render_badges('mktCap')} </Col>
                                                        </Row>
                                                        </Col>
                                                    </>
                                                    : ''
                                            }
                                        </Row>
                                    </>
                                ))
                            ))}
                        </h6>
                    </Col>
                    <Col xl={7} md={12} xs={12}>
                        {this.state.input_data.map((item, index) => (
                            Object.keys(item).map(key => (
                                key in LIST_METRICS_DISPLAY_NAMES ? list_metric_render(key) : ''
                            ))
                        ))}
                    </Col>

                </Row>
            </Card></>;

        return renderText;
    }
}

export default StockScreenInput;