import React, { Component } from 'react';
import { IMG_CONTAINER } from '../../common/url';
import { Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';

export default class AutoCompleteDisplay extends Component {

    renderItem(item) {
        var matchedText = this.props.matchedText;
        var ticker = item['symbol'];
        let itemName = item['companyName'];
        itemName = itemName.substring(0, 50);
        let itemName1 = itemName;
        let itemName2 = "";
        let itemName3 = "";
        let imgSource = IMG_CONTAINER + ticker + '.png';
        let index = itemName.toLowerCase().indexOf(matchedText.toLowerCase());
        if (index >= 0) {
            itemName1 = itemName.substring(0, index);
            itemName2 = itemName.substring(index, index + matchedText.length);
            itemName3 = itemName.substring(index + matchedText.length);
        }
        return (<>
            <Col xl="1" lg="1" md="1" sm="1" xs="6" className={isMobile ? "text-right" : ""}>
                <object type="image/jpg" data={imgSource} style={{ width: '25px', height: '25px' }} aria-label="Image" />
            </Col>
            <Col xl="2" lg="2" md="2" sm="2" xs="6"><strong>{ticker}</strong></Col>
            <Col xl="9" lg="9" md="9" sm="9" xs="12" className={"text-truncate " + (isMobile ? "text-center" : "")}>
                {itemName1}<strong>{itemName2}</strong>{itemName3}
            </Col>
        </>);
    }

    itemClickHandler = item => {
        this.props.setSelectedText(item.symbol);
    }

    render = () => {
        if (!this.props.data || this.props.data.length < 1) {
            return '';
        }
        let rowClassName = "border rounded " + (isMobile ? "ml-1" : "ml-0");
        let rowWidth = isMobile ? '91%' : '96%';
        return <Row style={{ position: 'absolute', zIndex: 2, width: rowWidth }} className={rowClassName}>
            <Col className="border-primary">
                {this.props.data.map((item, index) => (
                    <Row className={index === this.props.highlightedIndex ? "bg-secondary text-white px-2" : "bg-light text-secondary px-2"}
                        onMouseEnter={() => this.props.setHighlightedIndex(index)}
                        onClick={() => this.itemClickHandler(item)} >
                        {this.renderItem(item)}
                    </Row>
                ))}
            </Col>
        </Row>
    }
}