import React, { Component } from "react";
import { sendAmplitudeData, AMPLITUDE_EVENT_TYPES } from '../../common/amplitude';
import { MKT_CAP_RANGES, PAGE_NAMES } from "../../common/constants";
import { IMG_CONTAINER, PRICES_URL } from "../../common/url";
import { executeRestRequest } from "../../common/restService";
import { Row, Col, Badge, Spinner } from "reactstrap";
import { number_full_to_short_form, formatNumber } from '../../common/numberUtil';
import { isMobile } from "react-device-detect";
import { getStockNames } from "../../common/storageUtil";
import { Helmet } from "react-helmet";
import { withRouter } from '../../common/router';
import { withNavigateHook } from '../../common/navigate';

class Movers extends Component {

    DURATION_LABELS = {
        '1d': 'Today',
        '1w': '1 Week',
        '1m': '1 Month',
        '6m': '6 Months',
        'ytd': 'YTD',
        '1y': '1 Year',
        '3y': '3 Years'
    };

    constructor(props, context) {
        super(props, context);
        sendAmplitudeData(AMPLITUDE_EVENT_TYPES.PAGE_VIEW, { 'name': PAGE_NAMES.MOVERS });
        this.state = {
            show_spinner: false,
            duration_index: 0,
            stockNames: {},
            duration_prices: {},
            filter_record_count: 30,
            min_mktcap: MKT_CAP_RANGES['Large Cap'].low,
            max_mktcap: MKT_CAP_RANGES['Mega Cap'].high,
            mktCap_bages_selected: [false, false, true, true]
        }
    }

    componentDidMount = async () => {
        let durations = Object.keys(this.DURATION_LABELS);
        let duration = durations[this.state.duration_index];
        this.setState({ show_spinner: true });
        let pricesResponse = await executeRestRequest({ url: PRICES_URL + '/' + duration, method: 'GET' });

        let prices = pricesResponse.data;
        /** 
        prices.data.sort((a, b) => {            
            let aChange = (a[2] - a[1]) * 1.0 / a[1];
            let bChange = (b[2] - b[1]) * 1.0 / b[1];
            return bChange - aChange;
        });
        */
        let duration_prices = this.state.duration_prices;
        duration_prices[duration] = prices
        let stockNames = await getStockNames();
        this.setState({ duration_prices: duration_prices, stockNames: stockNames, show_spinner: false });
    }

    filterPrices = () => {
        let durations = Object.keys(this.DURATION_LABELS);
        let duration = durations[this.state.duration_index];
        let data = this.state.duration_prices[duration].data;
        let filter_record_count = this.state.filter_record_count;
        let min_mktcap = this.state.min_mktcap;
        let max_mktcap = this.state.max_mktcap;
        let top_indices = [];        
        let bottom_indices = [];
        if (data || null) {
            let topCount = 0;
            for (let i = 0; i < data.length && topCount < filter_record_count; i++) {
                if (data[i][2] > 0 && data[i][1] > 0) {
                    let change = data[i][2] - data[i][1];
                    if ((change || null) || change > 0) {
                        let mktCap = data[i][3];
                        if ((mktCap || null) && mktCap > min_mktcap && mktCap < max_mktcap) {
                            top_indices.push(i);
                            topCount++;
                        }
                    }
                }
            }

            let bottomCount = 0;
            for (let j = data.length - 1; j > 0 && bottomCount < filter_record_count; j--) {
                if (data[j][2] > 0 && data[j][1] > 0) {
                    let change = data[j][2] - data[j][1];
                    if ((change || null) && change < 0) {
                        let mktCap = data[j][3];
                        if ((mktCap || null) && mktCap > min_mktcap && mktCap < max_mktcap) {
                            bottom_indices.push(j);
                            bottomCount++;
                        }
                    }
                }
            }
        }

        return {
            top_stocks_indices: top_indices,
            bottom_stocks_indices: bottom_indices
        };
    }

    apply_label = async i => {
        if (this.state.duration_index !== i) {
            this.setState({ duration_index: i });
            let durations = Object.keys(this.DURATION_LABELS);
            let duration = durations[i];
            if (!(duration in this.state.duration_prices)) {
                this.setState({ show_spinner: true });
                let pricesResponse = await executeRestRequest({ url: PRICES_URL + '/' + durations[i], method: 'GET' });
                let prices = pricesResponse.data;
                let duration_prices = this.state.duration_prices;
                duration_prices[duration] = prices
                this.setState({ duration_prices: duration_prices, show_spinner: false });
            }
        }
    }

    render_duration_badges = () => {
        return <Row><Col xl="auto" xs="auto" md="auto" sm="auto" lg="auto">
            {Object.keys(this.DURATION_LABELS).map((label, i) => (<>
                <Badge style={{ cursor: 'pointer' }} onClick={() => this.apply_label(i)}
                    color={this.state.duration_index === i ? "success" : "secondary"} pill>
                    <small>{this.DURATION_LABELS[label]}</small>
                </Badge><span> </span></>
            ))}
        </Col></Row>;
    }

    render_mktcap_badges = () => {
        return <Row><Col xl="auto" xs="auto" md="auto" sm="auto" lg="auto">
            {Object.keys(MKT_CAP_RANGES).map((label, i) => (<>
                <Badge style={{ cursor: 'pointer' }} onClick={() => this.inverse_badge(i)}
                    color={this.state.mktCap_bages_selected[i] === true ? "success" : "secondary"} pill>
                    <small>{label}</small>
                </Badge><span> </span></>
            ))}
        </Col></Row>;
    }

    inverse_badge = i => {
        let badge_status = this.state.mktCap_bages_selected;
        let true_count = badge_status.filter(Boolean).length;
        if (true_count === 1 && badge_status[i] === true)
            return;
        badge_status[i] = !badge_status[i];
        let first_true = -1;
        let last_true = -1;
        for (let i = 0; i < badge_status.length; i++) {
            if (badge_status[i]) {
                if (first_true === -1)
                    first_true = i;
                else
                    last_true = i;
            }
        }
        if (last_true !== -1) {
            for (let i = first_true; i <= last_true; i++)
                badge_status[i] = true;
        } else {
            last_true = first_true;
        }
        let keys = Object.keys(MKT_CAP_RANGES);
        this.setState({
            mktCap_bages_selected: badge_status,
            min_mktcap: MKT_CAP_RANGES[keys[first_true]].low,
            max_mktcap: MKT_CAP_RANGES[keys[last_true]].high
        });
    }

    render = () => {

        if (this.state.show_spinner)
            return <Row><Col>
                <Row><Col className="d-flex justify-content-center mb-3">{this.render_duration_badges()}</Col></Row>
                <Row><Col className="d-flex justify-content-center mb-3">{this.render_mktcap_badges()}</Col></Row>
                <Row>
                    <Col className="mx-1" xl={12} md={12} xs={12} align="center">
                        <div height="500px" align="center">
                            <Spinner color="primary" animation="border" role="status" />
                        </div>
                    </Col>
                </Row>
            </Col></Row>;

        let durations = Object.keys(this.DURATION_LABELS);
        let duration = durations[this.state.duration_index];
        if (!(this.state.duration_prices[duration] || null))
            return '';
        let data = this.state.duration_prices[duration].data;

        let renderPrice = (price, change_value) => {
            let priceText = (price || null) ? price : '';
            if ((price || null) && !isNaN(price)) {
                priceText = formatNumber(price);
            }
            let changeText = '0';
            let changePercentText = '0%';
            let changeClass = "py-1 my-auto h-100% w-100% "
            if (change_value || null) {
                changeText = change_value;
                if (!isNaN(change_value)) {
                    change_value = Number(change_value);
                    changeText = change_value.toFixed(2);
                    if (change_value > 0) {
                        changeText = "+" + changeText;
                        changeClass += "bg-success text-white";
                    } else if (change_value < 0) {
                        changeClass += "bg-danger text-white";
                    }
                    if (!isNaN(price)) {
                        let change_percent = change_value / (price - change_value) * 100.0;
                        change_percent = change_percent.toFixed(1);
                        changePercentText = "(" + change_percent + "%)";
                    }
                }
            };
            let finalChange = changeText + ' ' + changePercentText;
            return <Row>
                <Col className="text-center my-auto px-0" xl={5}><h6>{priceText}</h6></Col>
                <Col xl={7}>
                    <Row className={changeClass + " text-center rounded border"}>
                        <Col xl="auto" md="auto" className="mx-auto px-0"><small>{finalChange}</small></Col>
                    </Row>
                </Col></Row>;
        };

        let stockNames = this.state.stockNames;
        let topStocksDisplay = '';
        let bottomStocksDisplay = '';

        let displayStockRow = (i) => {
            let ticker = data[i][0];
            return <Row>
                {
                    isMobile ? '' :
                        <Col xl={2} xs={2} className="px-0 py-0">
                            <img src={IMG_CONTAINER + ticker + '.jpg'} style={{ maxHeight: '20px', maxWidth: '100%', cursor: 'pointer' }}
                                alt={ticker}
                                onClick={() => this.props.navigation('/home/' + ticker)} />
                        </Col>
                }
                <Col xl={2} xs={isMobile ? 3 : 2} className="px-0">
                    <a href={window.location.protocol + '/home/' + ticker} target="_blank" rel="noopener noreferrer">
                        <strong>{ticker}</strong>
                    </a>
                </Col>
                <Col xl={3} className="text-truncate text-secondary">
                    {isMobile ? <small>{stockNames[ticker]}</small> : stockNames[ticker]}</Col>
                <Col xl={3}>{renderPrice(data[i][2], data[i][2] - data[i][1])}</Col>
                <Col xl={2}>{number_full_to_short_form(data[i][3])}</Col>
            </Row>;
        }
        let obj = this.filterPrices();
        if (obj.top_stocks_indices.length > 0) {
            topStocksDisplay = obj.top_stocks_indices.map(i => (displayStockRow(i)));
        }

        if (obj.bottom_stocks_indices.length > 0) {
            bottomStocksDisplay = obj.bottom_stocks_indices.map(i => (displayStockRow(i)));
        }

        let colDisplay = <h5><Row className="text-primary mb-2 px-0">
            <Col xl={2}>Logo</Col>
            <Col xl={2}>Symbol</Col>
            <Col xl={3}>Name</Col>
            <Col xl={3}>Price</Col>
            <Col xl={2}>MktCap</Col>
        </Row></h5>;
        const metaDesc = "Shows top and bottom 30 mover stocks today or over different timne periods like week, month, 6 months, year to date or one year. Allow to filter by marketcaps - megacap, largecap, midcap and smallcap.";
        return <>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Top and Bottom 30 mover stocks over today, a week, month, this year or one year.</title>
                <meta name="description" content={metaDesc} />
            </Helmet>
        <Row><Col>
            <Row><Col className="d-flex justify-content-center mb-3">{this.render_duration_badges()}</Col></Row>
            <Row><Col className="d-flex justify-content-center mb-3">{this.render_mktcap_badges()}</Col></Row>
            <Row>
                <Col className="mx-1">
                    <Row><Col className="bg-success text-white text-center rounded mt-2"><h5>Top Gainers</h5></Col></Row>
                    {colDisplay}
                    {topStocksDisplay}
                </Col>
                <Col className="mx-1">
                    <Row><Col className="bg-danger text-white text-center rounded mt-2"><h5>Top Losers</h5></Col></Row>
                    {colDisplay}
                    {bottomStocksDisplay}
                </Col>
            </Row>
        </Col></Row>
        </>;
    }
}

export default withNavigateHook(withRouter(Movers));