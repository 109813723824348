import React, { Component } from 'react';
import { Input, Row, Col, Button } from 'reactstrap';
import { TWELVE_HOURS_IN_SECONDS, FULL_LIST_KEY } from '../../common/constants';
import { get_object, set_object } from '../../common/localStorage';
import { get_ticker_list } from '../../common/apiUtil';
import AutoCompleteDisplay from './autoCompleteDisplay';
import ReactDOM from 'react-dom';

export default class TickerAutoComplete extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            stockLookupData: [],
            value: '',
            autocompleteData: [],
            highlightedIndex: -1
        };
    }

    componentDidMount = async () => {
        document.addEventListener('mousedown', this.handleClick, false);

        let stockLookupData = await get_object(FULL_LIST_KEY);
        if (stockLookupData === null) {
            stockLookupData = await get_ticker_list();
        }
        if ((stockLookupData || null) && stockLookupData.length > 100) {
            set_object(FULL_LIST_KEY, stockLookupData, TWELVE_HOURS_IN_SECONDS);
        }
        this.setState({ stockLookupData: stockLookupData });
    }

    handleClick = event => {
        const domNode = ReactDOM.findDOMNode(this);
        if (!domNode || !domNode.contains(event.target)) {
            this.setState({ autocompleteData: [], highlightedIndex: -1 });
        }
    }

    componentWillUnmount = () => {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    onChangeHandler = e => {
        this.setState({
            value: e.target.value
        });
        this.retrieveDataAsynchronously(e.target.value);
    }

    onKeyDownHandler = e => {
        if (e.key === 'Enter') {
            if (this.state.highlightedIndex >= 0 && this.state.autocompleteData.length > 0) {
                let val = this.state.autocompleteData[this.state.highlightedIndex].symbol;
                let stateObjectToSet = {};
                stateObjectToSet.autocompleteData = [];
                stateObjectToSet.highlightedIndex = -1;
                stateObjectToSet.value = '';
                this.setState(stateObjectToSet);
                this.props.onSubmit(val);
            } else {
                if (this.state.value.length > 0) {
                    this.setState({ value: '' });
                    this.props.onSubmit(this.state.value);
                }
            }
        } else if (e.key === 'ArrowDown') {
            let highlightedIndex = this.state.highlightedIndex + 1;
            if (highlightedIndex >= this.state.autocompleteData.length) {
                highlightedIndex = 0;
            }
            this.setState({ highlightedIndex: highlightedIndex });
        } else if (e.key === 'ArrowUp') {
            let highlightedIndex = this.state.highlightedIndex - 1;
            if (highlightedIndex < 0) {
                highlightedIndex = this.state.autocompleteData.length - 1;
            }
            this.setState({ highlightedIndex: highlightedIndex });
        } else if (e.key === 'Escape') {
            this.clearAutoCompleteData();
        }
    }

    clearAutoCompleteData = () => {
        this.setState({
            autocompleteData: [],
            highlightedIndex: -1
        });
    }

    /**
    * Updates the state of the autocomplete data with the remote data obtained via AJAX.
    * 
    * @param {String} searchText content of the input that will filter the autocomplete data.
    * @return {Nothing} The state is updated but no value is returned
    */
    retrieveDataAsynchronously(searchText) {
        if (searchText === '') {
            this.clearAutoCompleteData();
            return;
        }
        searchText = searchText.toLowerCase();
        let data = this.state.stockLookupData;
        let matchedTickers = [];

        for (var i = 0; i < data.length; i++) {
            var item = data[i];
            var ticker = item['symbol'];
            let name = item['companyName'];
            let tickerMatch = ticker.toLowerCase().startsWith(searchText);

            if (tickerMatch === true) {
                let push_item = { ...item };
                push_item['tickerMatch'] = searchText.length * 1.0 / ticker.length;
                matchedTickers.push(push_item);
            } else {
                if (name.includes('-') && !searchText.includes('-'))
                    name = name.replace('-', '');
                name = name.toLowerCase();
                let matchPos = name.indexOf(searchText);
                if (matchPos !== -1) {
                    let push_item = { ...item };
                    push_item['nameMatch'] = searchText.length * 1.0 / name.length;
                    if (matchPos === 0)
                        push_item['startMatch'] = true;
                    matchedTickers.push(push_item);
                }
            }
        }
        matchedTickers.sort((a, b) => {
            if ('tickerMatch' in a) {
                if ('tickerMatch' in b) {
                    if (a.tickerMatch === b.tickerMatch) {
                        return a.mktCap < b.mktCap;
                    } else {
                        return a.tickerMatch < b.tickerMatch;
                    }
                } else {
                    return -1;
                }
            } else {
                if ('tickerMatch' in b) {
                    return 1;
                } else {
                    if (a.nameMatch === b.nameMatch) {                        
                        return a.mktCap < b.mktCap;
                    }
                    else {
                        if ('startMatch' in a)
                            return -1;
                        if ('startMatch' in b)
                            return 1;
                        return a.nameMatch < b.nameMatch;
                    }
                }
            }
        });
        this.setState({
            autocompleteData: matchedTickers.slice(0, 12),
            highlightedIndex: 0
        });
    }

    setSelectedText = text => {
        let stateObjectToSet = {};
        stateObjectToSet.autocompleteData = [];
        stateObjectToSet.highlightedIndex = -1;
        if (!(this.props.retainValue || null))
            stateObjectToSet.value = '';
        this.setState(stateObjectToSet);
        this.props.onSubmit(text);
    };

    setHighlightedIndex = index => {
        this.setState({ highlightedIndex: index });
    };

    render = () => {
        return <Row>
            <Col className="px-0">
                <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Input type='text' size="md" placeholder='Type ticker or company name' value={this.state.value}
                            onKeyDown={(e) => this.onKeyDownHandler(e)}
                            onChange={(e) => this.onChangeHandler(e)} />
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <AutoCompleteDisplay
                            data={this.state.autocompleteData}
                            matchedText={this.state.value}
                            highlightedIndex={this.state.highlightedIndex}
                            setSelectedText={this.setSelectedText}
                            setHighlightedIndex={this.setHighlightedIndex} />
                    </Col>
                </Row>
            </Col>
            {true ? "" :
                <Col xl="auto" lg="auto" md="auto" sm="auto" xs="auto" className="px-0 border">
                    <Button size="md" className="bg-primary" onClick={() => this.props.onSubmit(this.state.value)}>{this.props.buttonName}</Button>
                </Col>
            }
        </Row >;
    }
}