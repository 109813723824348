let timerMap = {};

export const registerCall = async (func, interval, endTime) => {
    let id = -1;
    let timeOutId = -1;
    try {
        id = setInterval(async () => {
            func();
        }, interval * 1000);
        timeOutId = setTimeout(async () => {
            clearInterval(id);
        }, endTime * 1000);
    } catch (e) {
        console.log(e);
    }
    timerMap[id] = timeOutId;
    return id;
};

export const deregisterCall = id => {
    try {
        clearInterval(id);
        clearInterval(timerMap[id]);
    } catch (e) {
        console.log(e);
    }
    return true;
};

export const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
}
