export const LOCAL_SERVER = 'http://localhost:5000/';
export const REMOTE_SERVER = 'https://api.investlucid.com/';
export const SERVER = REMOTE_SERVER;
export const LOGIN_URL = SERVER + "login";
export const LOGOUT_URL = SERVER + "logout";
export const CREATE_OR_GET_WATCHLISTS_URL = SERVER + 'watchlists';
export const watchlist_tickers_add_delete_url = watchlist_id => {
    return SERVER + "watchlist/" + watchlist_id + "/tickers";
};
export const PRE_POST_PRICE_URL = SERVER + 'stock/pre-post-market/';
export const PRE_POST_REALTIME_PRICE_URL = SERVER + 'stock/pre-post-market,realtime/';
export const GET_OR_DELETE_WATCHLIST =  SERVER + 'watchlist/'; 
export const INDEX_URL = SERVER + "indexes";
export const MARKET_STATUS_URL = SERVER + "market-status";
export const PRICES_URL = SERVER + "prices";
export const HISTORICAL_URL = SERVER + 'stock/historical/';
export const CURRENT_URL = SERVER + 'stock/current/';
export const RATINGS_URL = SERVER + 'stock/ratings/';
export const NEWS_URL = SERVER + 'news/';
export const RELATED_STOCKS_URL = SERVER + 'related/';
export const KEY_DATA_URL = SERVER + 'stock/realtime,profile,historical/';
export const METADATA_URL = SERVER + 'stock/metadata/';
export const LATEST_URL = SERVER + 'stock/latest/';
export const PROFILE_URL = SERVER + 'stock/profile/';
export const REALTIME_URL = SERVER + 'stock/realtime/';     
export const FINANCIALS_URL = SERVER + 'financials/';
export const TICKER_LIST_URL = SERVER + 'tickers';
export const TICKER_SEARCH_URL = SERVER + 'ticker-search?';
export const CLOSING_PRICE_URL = SERVER + 'closing-prices/day/';
export const COVID19_URL = SERVER + 'covid19'
export const GET_USER_DETAILS_URL = SERVER + 'user';
export const INDEX_TICKERS_URL = SERVER + 'index-tickers';
export const EARNINGS_URL = SERVER + 'earnings-calendar';
export const ALERTS_URL = SERVER + 'alerts/';
export const VPORTFOLIO_URL = SERVER + 'portfolios';
export const vportfolio_transaction_url = portfolio_id => {
    return SERVER + 'portfolio/' + portfolio_id + '/transaction';
}
export const IMG_CONTAINER = 'https://stocksblob.blob.core.windows.net/images/';
export const IMAGE_URLS = {
    HOME: 'home.png',
    WATCHLIST: 'watchlist.png',
    RESEARCH: 'research.png',
    SORT: 'sort.png',
    ADD: 'add.png',
    MOVE: 'move.png',
    SHARE: 'share.png',
    MOVERS: 'movers.png',
    INDEX: 'index.jpg',
    INSIGHTS: 'insights.jpg',
    COVID_19:'coronavirus.png',
    ALERT: 'alert.png',
    LOGIN: IMG_CONTAINER +'login.png',
    LOGOUT: IMG_CONTAINER + 'logout.png',
    APRIL: IMG_CONTAINER + 'april.png',
    MAY: IMG_CONTAINER + 'may.png',
    JUNE: IMG_CONTAINER + 'june.png',
    CALENDAR: IMG_CONTAINER + 'calendar.png'    
}