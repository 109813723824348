import { DEFAULT_FILTERS, RANGE_METRICS_RENDER_MAP } from "./constants";
import { toJSONLocal } from "../../common/timeUtil";
import { TICKER_SEARCH_URL } from "../../common/url";
import { executeRestRequest } from "../../common/restService";

export function get_default_filters_deep_clone(stripTime = false) {
    let default_filters_clone = [];
    for (let index = 0; index < DEFAULT_FILTERS.length; index++) {
        let metric = DEFAULT_FILTERS[index];
        let metric_clone = get_deep_clone(metric, stripTime);
        default_filters_clone.push(metric_clone);
    }
    return default_filters_clone;
}

export function get_deep_clone(obj, stripTime = false) {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }// give temporary-storage the original obj's constructor
    if (obj instanceof Date) {
        return stripTime ? toJSONLocal(new Date(obj.getTime())) : new Date(obj.getTime());
    }
    var clonedObj = obj.constructor();
    for (var key in obj) {
        clonedObj[key] = get_deep_clone(obj[key], stripTime);
    }
    return clonedObj;
}

export async function filter_stock_data(stockData, filterCondition) {
    let boundMetrics = Object.keys(RANGE_METRICS_RENDER_MAP);
    let listMetrics = ['sector', 'industry'];
    let filter_data = (item, index) => {
        for (let i = 0; i < boundMetrics.length; i++) {
            let metric = boundMetrics[i];
            if (filterCondition[metric]) {
                if (item[metric] == null || item[metric] === '')
                    return false;
                if ((filterCondition[metric].low && (item[metric] < filterCondition[metric].low)) ||
                    (filterCondition[metric].high && (item[metric] > filterCondition[metric].high))) {
                    return false;
                }
            }
        }
        for (let i = 0; i < listMetrics.length; i++) {
            let metric = listMetrics[i];
            if (filterCondition[metric]) {
                if (item[metric] == null || item[metric] === '')
                    return false;
                if (filterCondition[metric].length > 0 &&
                    !filterCondition[metric].includes(item[metric])) {
                    return false;
                }
            }
        }
        if (filterCondition['index_group'] && filterCondition['index_group'].length > 0) {
            let found = false;
            for (let i = 0; i < filterCondition['index_group'].length; i++) {
                if (item[filterCondition['index_group'][i]]) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                return false;
            }
        }
        return true;
    }
    let filteredData = await stockData.filter(filter_data);
    return filteredData
}

export async function addPriceToSales(stockData) {
    let addFunc = (stockObj) => {
        stockObj['p/s'] = '';
        if (stockObj['revenue ttm'] && stockObj['revenue ttm'] > 0 && stockObj['mktCap']) {
            let ps = stockObj['mktCap'] / stockObj['revenue ttm'];
            if (ps < 25)
                stockObj['p/s'] = ps;
        }
    };
    stockData.forEach(addFunc);
}

export async function getTickerSearchData(data) {
    let screenStocksRequest = {
        url: TICKER_SEARCH_URL,
        method: 'POST',
        payload: data
    };
    let screenStocksResponse = await executeRestRequest(screenStocksRequest);
    return screenStocksResponse.data.stocks;
}