/*
  restRequest= {
        url: 'http://localhost:5000/login',
        headers: {}, // optional headers
        method: 'POST',
        payload: payload   // Present If post or put method.
  }

 restRequest= {
        url: 'http://localhost:5000/login',
        method: 'GET'       
  }

*/
export async function executeRestRequest(restRequest) {

    // alert("Request="+JSON.stringify(restRequest));
    var restResponse = {}
    var url = restRequest.url;
    if (url == null || typeof url == 'undefined') {
        restResponse.errorMessage = 'URL cannot be empty or null.';
        return restResponse;
    }

    var method = restRequest.method;
    if (method == null || typeof method == 'undefined') {
        restResponse.errorMessage = 'method cannot be empty or null.';
        return restResponse;
    }

    method = method.toUpperCase();
    if (method !== 'POST' && method !== 'GET' && method !== 'DELETE' && method !== 'PUT' && method !== 'PATCH') {
        restResponse.errorMessage = 'Invalid method:' + method;
        return restResponse;
    }

    var parameters = {
        method: method,
        mode: 'cors',
        credentials: 'include'
    }

    // Add payload to body
    if (method === 'POST' || method === 'PUT' || method === 'DELETE' || method === 'PATCH') {
        var payload = restRequest.payload;
        if (typeof payload != 'undefined') {
            parameters.body = JSON.stringify(payload);
        }
    }

    if (typeof restRequest.headers !== 'undefined') {
        parameters.headers = restRequest.headers;
    }
    await fetch(url, parameters).then(response => {
        return response.json()
    }).then(jsonResponse => {
        restResponse.data = jsonResponse;
    }).catch(function (err) {
        restResponse.errorMessage = err.toString();
        console.log(err);
    });
    return restResponse;
}