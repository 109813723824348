export async function is_market_open() {
    var est_time = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
    var time_parts = est_time.split(',')[1].split(':')

    var hour = time_parts[0];
    var minutes = time_parts[1];
    var pm = time_parts[2].toUpperCase().indexOf('P');

    var date_parts = est_time.split(',')[0].split('/')
    var est_day = new Date(date_parts[2], date_parts[0] - 1, date_parts[1])
    var day = est_day.getDay();

    if (day > 0 && day < 6) {  // Mon - Fri                
        if (pm < 0) { // am
            if (hour < 9 || (hour === 9 && minutes < 30)) {
                return false;
            }
        } else {
            if (hour > 4) {
                ;
                return false;
            }
            if (hour === 4 && minutes > 5) // 5 mins buffer
            {
                return false;
            }

        }
    } else if (day === 0 || day > 5) {
        return false;
    }
    return true;
}

export function toJSONLocal(date) {
    var local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
}

export function areTwoDatesEqual(d1, d2) {
    return d1.getFullYear() === d2.getFullYear()
        && d1.getDate() === d2.getDate()
        && d1.getMonth() === d2.getMonth();
}

export function addDaystoDate(dt, days) {
    let result = new Date(dt);
    result.setDate(result.getDate() + days);
    return result;
}

export function daysBetweenTwoDates(d1, d2) {
    return Math.ceil(Math.abs(d2 - d1) / (1000 * 60 * 60 * 24));
}

export function isDateInCurrentWeek(dt) {
    if (dt || null) {
        let today = new Date();
        // getDay() function returns current day of the week (0..6) 
        var firstDateOfCurrentWeek = today.getDate() - today.getDay();

        // Set current week first day to currentWeekDay
        today.setDate(firstDateOfCurrentWeek);
        // Chec;k date is within in next 6 days range 
        for (var dayCount = 1; dayCount <= 6; dayCount++) {
            today.setDate(today.getDate() + 1);
            // Javascript month number will start from 0. So add 1 to get correct month number
            if (today.getFullYear() === dt.getFullYear() &&
                today.getMonth() === dt.getMonth() && today.getDate() === dt.getDate()) {
                return true;
            }
        }
    }

    return false;
}

export function isGivenStringDateisToday(stringDate) {
    // Example string date 2020-04-30
    if (stringDate || null) {
        let dateParts = stringDate.split("-");
        let year = parseInt(dateParts[0]);
        let month = parseInt(dateParts[1]);
        let date = parseInt(dateParts[2]);
        let today = new Date();

        // Javascript month number will start from 0. So add 1 to get correct month number
        if (today.getFullYear() === year && today.getMonth() + 1 === month && today.getDate() === date) {
            return true;
        }
    }
    return false;
}

export const SHORT_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const SHORT_MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];