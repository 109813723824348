import React, { Component } from "react";
import { executeRestRequest } from "../../common/restService";
import {
    REALTIME_URL,
    CREATE_OR_GET_WATCHLISTS_URL,
    watchlist_tickers_add_delete_url,
    IMG_CONTAINER,
    GET_OR_DELETE_WATCHLIST,
    MARKET_STATUS_URL,
    IMAGE_URLS
} from "../../common/url";
import { Row, Col, Badge, Spinner } from "reactstrap";
import TickerAutoComplete from "../../components/tickerAutoComplete/tickerAutoComplete";
import CreateWatchList from "./createWatchList";
import ShareWatchList from "./shareWatchList";
import ReactDOM from 'react-dom';
import { getStockNames, getEarningsData } from "../../common/storageUtil";
import StockPriceDisplay from "../../components/stock/stockPriceDisplay";
import { registerCall, deregisterCall } from '../../common/timerUtil';
import { DropdownMenu, UncontrolledDropdown, DropdownToggle, DropdownItem, Navbar, Nav } from "reactstrap";
import { TICKER_SORT_OPTIONS, SORT_IMAGE_URL } from "./constants";
import { AMPLITUDE_EVENT_TYPES, sendAmplitudeData } from "../../common/amplitude";
import { PAGE_NAMES } from "../../common/constants";
import { setWindowTitle } from "../../common/apiUtil";
import { INVEST_LUCID_TITLE } from "../../common/constants";
import { isMobile } from "react-device-detect";
import { isDateInCurrentWeek, daysBetweenTwoDates } from '../../common/timeUtil';
import { Helmet } from "react-helmet";
import { withRouter } from '../../common/router';
import { withNavigateHook } from '../../common/navigate';

class WatchList extends Component {

    constructor(props, context) {
        super(props, context);
        sendAmplitudeData(AMPLITUDE_EVENT_TYPES.PAGE_VIEW, { 'name': PAGE_NAMES.WATCHLIST });
        this.state = {
            show_spinner: false,
            stockNames: {},
            tickerPrices: {},
            watchList: {},
            activeWatchlistId: null,
            editMode: false,
            displayCreateWatchlistWindow: false,
            displayShareWatchlistWindow: false,
            watchListRefreshIntervalId: "",
            sortTickersBy: "change",
            earnings: {},
            title: 'My Watchlist'
        }
    }

    componentDidMount = async () => {

        // Read the watch_list_name value from the URL. This is to handle the case when back button is clicked in the browser.
        this.setState({ show_spinner: true });

        let stateObjToSet = {};
        let earnings = await getEarningsData();        
        if ('data' in earnings && 'symbols' in earnings.data)
            stateObjToSet['earnings'] = earnings.data.symbols;
        let stockNames = await getStockNames();
        stateObjToSet['stockNames'] = stockNames;
        let watch_list_name = this.props.router.params.name;
        let watchListObj = await this.refreshPortfolio(stockNames, watch_list_name);
        stateObjToSet = { ...stateObjToSet, ...watchListObj };
        stateObjToSet['show_spinner'] = false;
        this.setState(stateObjToSet);
        try {
            this.props.history.push('/watchlist/' + this.state.watchList[this.state.activeWatchlistId].name);
        } catch (err) { }

        // Register for auto refresh of tickers
        let marketStatusResponse = await executeRestRequest({ url: MARKET_STATUS_URL, method: 'GET' });
        let marketStatusData = marketStatusResponse.data;
        if ((marketStatusData || null) && marketStatusData['is open']) {
            let marketClosingTime = marketStatusData['to close time'];
            let refreshIntervalTime = 20  // 10 Seconds
            let fiveMinutesBufferTime = 5 * 60;
            let watchListRefreshIntervalId = registerCall(this.startAutoRefresh, refreshIntervalTime, marketClosingTime + fiveMinutesBufferTime);
            this.setState({ watchListRefreshIntervalId: watchListRefreshIntervalId });
        }
        setWindowTitle(INVEST_LUCID_TITLE);
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentDidUpdate = async () => {
        if (this.state.activeWatchlistId === null || this.state.watchList.length < 1)
            return;

        let url_watch_list_name = this.props.router.params.name;
        let activeWatchListName = this.state.watchList[this.state.activeWatchlistId].name;
        let watchLists = this.state.watchList;
        for (let watchListId in watchLists) {
            let watchList = watchLists[watchListId];
            if (watchList.name === url_watch_list_name && url_watch_list_name !== activeWatchListName) {
                this.setState({ activeWatchlistId: parseInt(watchList.id) })
                return;
            }
        }
        setWindowTitle(INVEST_LUCID_TITLE);
    }

    startAutoRefresh = () => {
        try {
            if (this.state.activeWatchlistId === null)
                return;
            this.update_watchlist_prices();
        } catch (e) {
            console.log(e);
        }
    }

    stopAutoRefresh = () => {
        if (this.state.watchListRefreshIntervalId !== "")
            deregisterCall(this.state.watchListRefreshIntervalId);
    }


    componentWillUnmount = () => {
        document.removeEventListener('mousedown', this.handleClick, false);
        this.stopAutoRefresh();
    }

    setSortTickersBy = (sortByType) => {
        this.setState({ sortTickersBy: sortByType })

    }

    handleClick = event => {
        const domNode = ReactDOM.findDOMNode(this);
        if (!domNode || !domNode.contains(event.target)) {
            if (this.state.editMode) {
                this.setState({ editMode: false });
            }
        }
    }

    refreshPortfolio = async (stockNames, watch_list_name = null) => {
        let returnObj = {};
        let requestDetails = {
            method: 'GET',
            url: CREATE_OR_GET_WATCHLISTS_URL
        }
        let data = (await executeRestRequest(requestDetails))['data'];
        if ((data || null) && data.length > 0) {
            let watchLists = {};
            let activeId = this.state.activeWatchlistId;
            for (let i = 0; i < data.length; i++) {
                let elem = data[i];
                watchLists[elem.id] = elem;
                if (watch_list_name !== null && elem.name === watch_list_name) {
                    activeId = elem.id
                }
            }

            if (activeId === null) {
                activeId = data[0].id;
            }
            let pricesObj = {};
            pricesObj[activeId] = await this.getTickerPricesObj(watchLists, activeId, stockNames);
            returnObj['watchList'] = watchLists;
            returnObj['activeWatchlistId'] = activeId;
            returnObj['tickerPrices'] = pricesObj;
        }
        return returnObj;
    }

    getTickerPricesObj = async (watchLists, id, stockNames) => {
        let tickerPriceObjs = await this.get_ticker_prices(watchLists[id].tickers);
        let earnings = this.state.earnings;
        for (let i = 0; i < tickerPriceObjs.length; i++) {
            let symbol = tickerPriceObjs[i].symbol;
            tickerPriceObjs[i]['name'] = stockNames[symbol];
            if (symbol in earnings) {
                let temp_dt = earnings[symbol].date;
                let dt = new Date(temp_dt);
                let now = new Date();
                let today = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
                //today.setHours(0, 0, 0, 0);
                let days = daysBetweenTwoDates(today, dt);
                if(dt.getTime() === today.getTime()) {
                    tickerPriceObjs[i]['earnings'] = 'ET'
                }
                else if (dt >= today && days === 1) {
                    tickerPriceObjs[i]['earnings'] = 'ETM';
                } else if (dt < today && days === 1) {
                    tickerPriceObjs[i]['earnings'] = 'EY';
                } else if (isDateInCurrentWeek(dt) === true) {
                    tickerPriceObjs[i]['earnings'] = 'EW';
                }
            }
        }
        return tickerPriceObjs;
    }

    update_watchlist_prices = async () => {
        let stockNames = this.state.stockNames;
        let tickerPriceObjs = await this.getTickerPricesObj(this.state.watchList, this.state.activeWatchlistId, stockNames);
        let tickerPrices = this.state.tickerPrices;
        tickerPrices[this.state.activeWatchlistId] = tickerPriceObjs;
        this.setState({ tickerPrices: tickerPrices });
    }

    get_ticker_prices = async (ticker_list) => {
        let tickerPriceObjs = [];
        if (ticker_list.length > 0) {
            let requestDetails = {
                method: 'GET',
                url: REALTIME_URL + ticker_list.join(',')
            }
            let data = (await executeRestRequest(requestDetails))['data'];
            if ((data || null) && data['tickers']) {
                let tickers = data.tickers;
                let stockNames = this.state.stockNames;
                for (let i = 0; i < ticker_list.length; i++) {
                    let ticker = ticker_list[i];
                    let tickerPriceObj = { 'symbol': ticker };
                    if (stockNames[ticker]) {
                        tickerPriceObj['name'] = stockNames[ticker];
                    }
                    if (tickers[ticker]) {
                        Object.assign(tickerPriceObj, tickers[ticker].realtime);
                    }
                    tickerPriceObjs.push(tickerPriceObj);
                }
            }
        }
        return tickerPriceObjs;
    }

    toggleEditMode = () => {
        this.setState({ editMode: !this.state.editMode });
    }

    addTickerHandler = async (ticker) => {
        this.performOperation(ticker, 'PUT');
    }

    removeTickerHandler = async (ticker) => {
        this.performOperation(ticker, 'DELETE');
    }

    performOperation = async (ticker, oper) => {
        let url = watchlist_tickers_add_delete_url(this.state.activeWatchlistId);
        let requestData = {
            url: url,
            method: oper,
            payload: { 'tickers': [ticker] }
        }
        let responseData = await executeRestRequest(requestData);
        // Set result in App.js file. This is required for research and home page.
        this.props.refreshWatchList(responseData.data.watchlists)
        let res = await this.refreshPortfolio(this.state.stockNames);
        this.setState(res);
    }

    switchToWatchList = async id => {
        let tickerPrices = this.state.tickerPrices;
        if (!(tickerPrices[id] || null)) {
            tickerPrices[id] = await this.getTickerPricesObj(this.state.watchList, id, this.state.stockNames);
        }
        this.setState({
            activeWatchlistId: id,
            tickerPrices: tickerPrices,
        });
        this.props.navigation('/watchlist/' + this.state.watchList[id].name);
    }

    closeCreateWatchlistWindow = async () => {
        this.setState({ displayCreateWatchlistWindow: false })
    }

    closeShareWatchlistWindow = async () => {
        this.setState({ displayShareWatchlistWindow: false })
    }

    createWatchlist = async (watchlistName) => {
        let requestData = {
            url: CREATE_OR_GET_WATCHLISTS_URL,
            method: 'POST',
            payload: { name: watchlistName }
        };
        let res = await executeRestRequest(requestData);
        res = await this.refreshPortfolio(this.state.stockNames);
        res['displayCreateWatchlistWindow'] = false;
        this.setState(res);
    }

    redirectToHomePage = async (ticker) => {
        this.props.navigation('/home/' + ticker);
    }

    removeWatchlist = async () => {
        let watchLists = this.state.watchList;
        if (Object.keys(watchLists) === 1)
            return;
        let requestData = {
            url: GET_OR_DELETE_WATCHLIST + this.state.activeWatchlistId,
            method: 'DELETE'
        };
        let res = await executeRestRequest(requestData);
        if ('errorMessage' in res)
            return;
        let activeWatchlistId = this.state.activeWatchlistId;
        delete watchLists[activeWatchlistId];
        if (activeWatchlistId > 0)
            activeWatchlistId--;
        else
            activeWatchlistId++;
        this.setState({
            activeWatchlistId: activeWatchlistId,
            watchList: watchLists,
            editMode: false
        });
    }

    shareWatchList = (email) => {
        let activeWatchlistIdTickerList = this.state.watchList[this.state.activeWatchlistId].tickers;
        let tickers = activeWatchlistIdTickerList.join(", ")
        tickers = tickers + "    ";
        let watchlistName = this.state.watchList[this.state.activeWatchlistId].name;
        window.location = 'mailto:' + email + '?subject=Tickers from watchlist: ' + watchlistName + '&body=' + tickers + window.location;
    }

    render = () => {

        let delImg = IMG_CONTAINER + 'delete.png';
        let removeImage = this.state.editMode ? <object type="image/jpg" data={delImg} className="rounded-circle" onClick={(e) => this.removeWatchlist()}
            style={{ maxHeight: '15px', maxWidth: '15px', float: 'right', cursor: 'pointer' }} aria-label="Edit" /> : '';

        let watchListRender = Object.keys(this.state.watchList).map(id => (
            <Col xl="auto" lg="auto" md="auto" sm="auto" xs="auto">
                <Row>
                    <Col className="pr-1 pt-0" style={{ position: 'absolute', zIndex: 2 }}>{parseInt(id) === this.state.activeWatchlistId ? removeImage : ''}</Col>
                    <Col className="px-0">
                        <Badge style={{ "cursor": "pointer" }}
                        className={this.state.activeWatchlistId === parseInt(id) ? "mr-2" : "mr-2 text-dark"}
                        color={parseInt(id) === this.state.activeWatchlistId ? "success" : "light"}
                            href="" onClick={(e) => this.switchToWatchList(parseInt(id))} pill>
                            {this.state.watchList[id].name}
                        </Badge>
                    </Col>
                </Row>
            </Col>
        ));

        let tickerPrices = this.state.tickerPrices[this.state.activeWatchlistId];
        let renderSortIcon = false;

        let tickersRender = '';
        let sortTickersBy = this.state.sortTickersBy;
        if (tickerPrices || null) {
            tickerPrices.sort((a, b) => {
                if (sortTickersBy === 'change') {
                    let x = 0, y = 0;
                    if (a['Latest Price'] !== 0 && a['Latest Change'] !== 0)
                        x = Math.abs(a['Latest Change'] * 1.0 / a['Latest Price']);
                    if (b['Latest Price'] !== 0 && b['Latest Change'] !== 0)
                        y = Math.abs(b['Latest Change'] * 1.0 / b['Latest Price']);
                    return x > y ? -1 : 1;
                } else if (sortTickersBy === 'name' || sortTickersBy === 'symbol') {
                    return a[sortTickersBy].localeCompare(b[sortTickersBy]);
                } else {
                    return 1;
                }
            });
            tickersRender = tickerPrices.map(tickerPriceObj => (
                <Col xl={3} md={4} sm={6} xs={12} className="px-1">
                    <StockPriceDisplay tickerPriceObj={tickerPriceObj}
                        editMode={this.state.editMode}
                        removeTicker={this.removeTickerHandler}
                        redirectToHomePage={this.redirectToHomePage}
                    />
                </Col>
            ));

            if (tickerPrices.length > 0) {
                renderSortIcon = true;
            }
        }

        let imgSource = IMG_CONTAINER + 'edit.png';
        return <>
            <Helmet onChangeClientState={(newState) => 
                this.setState({ title: 
                    (this.state.activeWatchlistId === null || this.state.watchList.length < 1) ?
                    this.state.title : this.state.watchList[this.state.activeWatchlistId].name + ' watchlist'}) }>
                <meta charSet="utf-8" />
                <title>{this.state.title}</title>
                <meta name="description" content="Invest Lucid watchlist" />
            </Helmet>
            <Row className="mx-1">
                <Col>
                    <Row className="my-1">
                        <Col xl={11} md={10} sm={8} xs={12}>
                            <Row style={isMobile ? { "flex-wrap": "nowrap", "overflow-x": "scroll", "display": "flex" } : {}}>
                                {watchListRender}
                            </Row>
                        </Col>
                        <Col xl={1} md={2} sm={4} xs={12}>
                            <Row className="float-end" >
                                <Col className="px-0 ms-0" onClick={() => this.setState({ displayCreateWatchlistWindow: true })}>
                                    <img src={IMG_CONTAINER + IMAGE_URLS.ADD} style={{ maxHeight: '20px', maxWidth: '20px', cursor: 'pointer' }} title="Create"></img>
                                </Col>
                                <Col className="px-1" onClick={() => this.setState({ displayShareWatchlistWindow: true })}>
                                    <img src={IMG_CONTAINER + IMAGE_URLS.SHARE} style={{ maxHeight: '20px', maxWidth: '20px', cursor: 'pointer' }} title="Create"></img>
                                </Col>
                                <Col className="px-0" onClick={() => this.toggleEditMode() }>
                                    <img src={imgSource} style={{ maxHeight: '20px', maxWidth: '20px', cursor: 'pointer' }} title="Create"></img>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xl={7} md={10} xs={12}><TickerAutoComplete onSubmit={this.addTickerHandler} buttonName="Add" /></Col>
                    </Row>
                    {renderSortIcon === true ?
                        <Row>
                            <Col className="py-0 px-0" >
                                <Navbar className="navigation__navbar navbar-dark bg-white float-end py-0 px-0" light expand="md">
                                    <Nav className="mr-auto" navbar>
                                        <UncontrolledDropdown nav inNavbar>
                                            <DropdownToggle className="text-primary" nav caret>
                                                <object type="image/jpg"
                                                    data={SORT_IMAGE_URL} style={{ height: '10px', width: '10px', cursor: 'pointer' }} aria-label="Toggle" />
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                {TICKER_SORT_OPTIONS.map((sort_option, i) => (
                                                    <DropdownItem>
                                                        <div onClick={e => this.setSortTickersBy(sort_option.sort_by)}
                                                            className={this.state.sortTickersBy === sort_option.sort_by ? 'text-success' : 'text-primary'} >
                                                            {sort_option.sort_by}
                                                        </div>
                                                    </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </Nav>
                                </Navbar>
                            </Col>
                        </Row>
                        : ""
                    }
                    <Row>
                        {this.state.show_spinner ?
                            <Col height="500px" align="center" vAlign="center" className="mt-4">
                                <Spinner color="primary" animation="border" role="status" />
                            </Col>
                            : <Col><Row>{tickersRender}</Row></Col>
                        }
                    </Row>
                    {this.state.displayCreateWatchlistWindow ?
                        <CreateWatchList
                            createWatchlist={this.createWatchlist}
                            closeCreateWatchlistWindow={this.closeCreateWatchlistWindow}
                        /> : ''
                    }

                    {this.state.displayShareWatchlistWindow ?
                        <ShareWatchList
                            shareWatchList={this.shareWatchList}
                            closeShareWatchlistWindow={this.closeShareWatchlistWindow}
                        /> : ''
                    }

                </Col>
            </Row>
        </>
    }
}
export default withNavigateHook(withRouter(WatchList));