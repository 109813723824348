export const EARNINGS_TIME_LOOKUP = {
    'TNS': '',
    'TAS': '',
    'BMO': 'Before',
    'AMC': 'After',
    'bmo': 'Before',
    'amc': 'After'
}

export const WEEK_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

export const WEEK_DAYS_SHORT = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];