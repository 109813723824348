import React, { Component } from "react";
import {
    Modal, ModalHeader, ModalBody, Button, Row, Col, Input
} from 'reactstrap';

export default class ShareWatchList extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            email: ''
        }
    }

    share_watchlist = () => {
        if (this.state.email.length > 0) {
            this.props.closeShareWatchlistWindow();
            this.props.shareWatchList(this.state.email);
        }
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.share_watchlist();
        }
    }

    render() {
        let closeShareWatchlistWindow = this.props.closeShareWatchlistWindow;

        return (
            <Modal isOpen={true} toggle={() => closeShareWatchlistWindow()} size="md" centered={true}>
                <ModalHeader>Email to share watchList</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={10}>
                            <Input type="text" size="sm" value={this.state.email}
                                onChange={(e) => this.setState({ email: e.target.value })}
                                id="email" placeholder="Email id"
                                onKeyDown={(e) => this.handleKeyDown(e)} />
                        </Col>
                        <Col xl={2}>
                            <Button color="primary" size="sm" onClick={this.share_watchlist} style={{ marginBottom: '1rem' }}> Share</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}