import React, { Component } from 'react';
import { Table, Badge, Row, Col, Card } from 'reactstrap';
import { number_full_to_short_form, formatNumber } from '../../common/numberUtil';
import { INDEX_GROUPS, MKT_CAP_RANGES, CURRENCY_FORMAT_METRICS, PERCENT_FORMAT_METRICS, DECIMAL_FORMAT_METRICS, INDEX_BADGE_COLORS } from '../../common/constants';
import { IMG_CONTAINER, REALTIME_URL, IMAGE_URLS } from '../../common/url';
import { METRICS_NAME_LOOKUP, SORTABLE_METRICS, METRICS_DISPLAY_ORDER } from './constants';
import { executeRestRequest } from '../../common/restService';
import LucidDropDownMenu from '../dropdownmenu/dropDownMenu';
import { addTickerToWatchList } from '../../common/apiUtil';

class ResultsTableRender extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            prices: {}
        }
    }

    metric_col_class_map = {
        'logo': 'col-1',
        'symbol': 'col-1 mr-2',
        'realtime': 'col-1',
        'return': 'col-1',
        'mktCap': 'col-1',
        'p/e': 'col-1',
        'p/s': 'col-1',
        'revenue ttm': 'col-1',
        'net income ttm': 'col-1',
        'sector': 'col-2',
        'beta': 'col-1',
    }

    construct_header_rows(sort_metric) {
        let imgSource = IMG_CONTAINER + (this.props.sort_ascending ? "up_arrow" : "down_arrow") + ".png";

        let get_header_badge = (metric, clickEvent) => {
            let sortIcon = sort_metric === metric ?
                <img src={imgSource} style={{ height: '12px' }} alt="Sort" /> : '';
            return clickEvent ?
                <Col onClick={() => this.props.sort(metric)} style={{ cursor: 'pointer' }} className="px-0 mx-0">
                    <Badge>{METRICS_NAME_LOOKUP[metric]}</Badge>
                    {sortIcon}
                </Col> :
                <Col className="px-0"><Badge>{METRICS_NAME_LOOKUP[metric]}</Badge>{sortIcon}</Col>;
        };

        var header_rows =
            <tr className="d-flex text-nowrap">
                <th className={this.metric_col_class_map['logo'] + " px-0"}>
                    <Col className="px-0 mx-0"><Badge>Logo</Badge></Col>
                </th>

                {METRICS_DISPLAY_ORDER.map((metric, index) => (
                    <th className={this.metric_col_class_map[metric] + " px-0"}>
                        {
                            get_header_badge(metric, SORTABLE_METRICS.includes(metric))
                        }
                    </th>
                ))}
            </tr>;
        return header_rows;
    }

    get_number_of_decimal_points(value) {
        return value < 1000 ? 1 : 0
    }

    redirectToHomePage = async (ticker) => {
        this.props.history.push('/home/' + ticker);
    }

    render_rows = (row) => {

        const upStyle = {
            color: 'green',
            fontSize: 17
        }
        const downStyle = {
            color: 'red',
            fontSize: 17
        }

        let record = JSON.parse(JSON.stringify(row));
        let begin_price = 'begin price';
        let end_price = 'end price';
        let stock_return = 'return';
        let range = 'range'
        if (!(begin_price in record)) {
            begin_price = begin_price + ' 1 yr';
            end_price = end_price + ' 1 yr';
            stock_return = stock_return + ' 1 yr';
            range = 'range 1 yr'
        }
        if (begin_price in record) {
            let start_price_decimal_count = this.get_number_of_decimal_points(record[begin_price]);
            let end_price_decimal_count = this.get_number_of_decimal_points(record[end_price])
            record[range] = record[stock_return] ? <Row className="justify-content-start">
                <Col xl={4} className="justify-content-end">{record[begin_price].toFixed(start_price_decimal_count)}</Col>
                <Col xl={2}>&#x2192;</Col>
                <Col xl={4} className="justify-content-start">{record[end_price].toFixed(end_price_decimal_count)}</Col>
            </Row> : '';
            delete record[begin_price];
            delete record[end_price];
        }

        let get_industry_sector_badges = (record, index) => {
            let badges = [];
            if (record['sector']) {
                badges.push(<><Badge color="primary">{record['sector']}</Badge><span> </span></>);
            }
            if (record['industry']) {
                badges.push(<><Badge color="info">{record['industry']}</Badge><span> </span></>);
            }
            return badges;
        }

        let get_index_group = record => {
            for (let i = 0; i < INDEX_GROUPS.length; i++) {
                if (record[INDEX_GROUPS[i]]) {
                    return <Badge color={INDEX_BADGE_COLORS[i]}>{INDEX_GROUPS[i]}</Badge>;
                }
            }
            return '';
        };

        let get_mkt_cap_group = mktCap => {
            for (let key in MKT_CAP_RANGES) {
                if (MKT_CAP_RANGES[key].low <= record['mktCap'] &&
                    mktCap <= MKT_CAP_RANGES[key].high) {
                    return <Badge color={MKT_CAP_RANGES[key].color}>{key}</Badge>;
                }
            }
        };

        let get_return_render = (val, rangeVal) => {
            let className = val > 0 ? "text-success" : "text-danger";
            let arrow_button = val > 0 ? <>&#x2191;</> : <>&#x2193;</>;
            let arrow_button_style = val > 0 ? upStyle : downStyle;
            return <>
                <Row className={className + " mx-auto"}>
                    <Col style={arrow_button_style}>{arrow_button}{' '}{(val * 100.0).toFixed(1) + '% '}</Col>
                </Row>
                <Row><Col>{rangeVal}</Col></Row>
            </>;
        }

        let renderSymbol = () => {
            let tickerLink = window.location.protocol + '/home/' + record['symbol'];
            return <td className={this.metric_col_class_map['symbol']}>
                <Row>
                    <Col className="px-0">
                        <a href={tickerLink} target="_blank" rel="noopener noreferrer"><strong>{record['symbol']}</strong></a>
                    </Col>
                    <Col className="px-0">{get_index_group(record)}</Col>
                </Row>
                <Row><Col className="text-truncate px-0"><small>{this.props.stockNames[record['symbol']] ?
                    this.props.stockNames[record['symbol']].slice(0, 25) : ''}</small></Col></Row>
            </td>;
        };

        let renderPrice = () => {
            let data = this.state.prices[record['symbol']];
            if (!(data || null)) {
                return <td className={this.metric_col_class_map['realtime']}></td>;
            }
            data = data['realtime'];
            if (!(data || null)) {
                return <td className={this.metric_col_class_map['realtime']}></td>;
            }

            let price = data['Latest Price'];
            let priceText = (price || null) ? price : '';
            if ((price || null) && !isNaN(price)) {
                priceText = formatNumber(price);
            }
            let changeText = '0';
            let changePercentText = '0%';
            let change_value = data['Latest Change'];
            let changeClass = "py-1 my-auto h-100% w-100% "
            if (change_value || null) {
                changeText = change_value;
                if (!isNaN(change_value)) {
                    change_value = Number(change_value);
                    changeText = change_value.toFixed(2);
                    if (change_value > 0) {
                        changeText = "+" + changeText;
                        changeClass += "bg-success text-white";
                    } else if (change_value < 0) {
                        changeClass += "bg-danger text-white";
                    }
                    if (!isNaN(price)) {
                        let change_percent = change_value / (price - change_value) * 100.0;
                        change_percent = change_percent.toFixed(1);
                        changePercentText = "(" + change_percent + "%)";
                    }
                }
            };
            let finalChange = changeText + ' ' + changePercentText;
            return <td className={this.metric_col_class_map['realtime']}>
                <Row><Col className="text-center text-primary"><h6>{priceText}</h6></Col></Row>
                <Row><Col>
                    <Card className={changeClass + " text-center"}>
                        <Row><Col xl="auto" md="auto" className="mx-auto"><small>{finalChange}</small></Col></Row>
                    </Card>
                </Col></Row>
            </td>;
        };


        let watchLists = this.props.watchLists;
        let menu_items = [];
        // If Ticker is not exist in any of the watchlist, then only construct the MENU_ITEMS

        if (watchLists || null) {
            for (let i = 0; i < watchLists.length; i++) {
                if (watchLists[i].tickers.includes(record['symbol'])) {
                    continue;
                }
                menu_items.push({
                    item_name: watchLists[i].name,
                    parammeters: {
                        id: watchLists[i].id,
                        ticker: record['symbol'],
                        resultSetFunction: this.props.refreshWatchList
                    }
                });
            }
        }


        let imgSource = IMG_CONTAINER + record['symbol'] + '.jpg';
        return <tr className="d-flex text-nowrap">
            <td className={this.metric_col_class_map['logo']}>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <img src={imgSource} style={{ maxHeight: '40px', maxWidth: '90%', cursor: 'pointer' }}
                                    alt={record['symbol']}
                                    onClick={() => this.redirectToHomePage(record['symbol'])}
                                /> </Col>
                        </Row>

                        {menu_items.length > 0 ?
                            <Row xl="auto" md="auto" className="mt-0 mb-0 pt-1">
                                <Col xl="auto" md="auto" className="py-0">
                                    <LucidDropDownMenu image_url={IMG_CONTAINER + IMAGE_URLS.ADD}
                                        menu_items={menu_items}
                                        height="18px"
                                        onClickFunction={addTickerToWatchList}
                                        selected_menu_item_name=""
                                        position="left"
                                    />
                                </Col>
                            </Row> : ""
                        }
                    </Col>
                </Row>
            </td>

            {METRICS_DISPLAY_ORDER.map((metric, index) => (
                metric === "symbol" ?
                    renderSymbol()
                    : metric === 'realtime' ? renderPrice()
                        :
                        <td className={this.metric_col_class_map[metric]}>
                            {CURRENCY_FORMAT_METRICS.includes(metric) ?
                                metric === 'mktCap' ?
                                    <><Row><Col>{'$' + number_full_to_short_form(record[metric])}</Col></Row>
                                        <Row><Col>{get_mkt_cap_group(record[metric])}</Col></Row></> :
                                    '$' + number_full_to_short_form(record[metric]) :
                                PERCENT_FORMAT_METRICS.includes(metric) ?
                                    metric.slice(0, 6) === 'return' ?
                                        get_return_render(record[metric], record[range]) :
                                        (record[metric] * 100.0).toFixed(1) + '%' :
                                    DECIMAL_FORMAT_METRICS.includes(metric) ?
                                        record[metric] || null ? record[metric].toFixed(2) : '' :
                                        metric === "sector" ?
                                            get_industry_sector_badges(record, metric).map((badge) => (
                                                <Row><Col>{badge}</Col></Row>
                                            ))
                                            : record[metric]}
                        </td>
            ))}
        </tr>;
    }

    construct_body_rows(data) {
        return data.map((record, index) => (
            <>{this.render_rows(record)}</>
        ));
    }

    fetchPrices = async tickers => {
        let requestDetails = {
            method: 'GET',
            url: REALTIME_URL + tickers.join(',')
        }
        let data = (await executeRestRequest(requestDetails))['data'];
        let prices = this.state.prices;
        for (let i = 0; i < tickers.length; i++) {
            if (!prices[tickers[i]])
                prices[tickers[i]] = {};
        }
        if ((data || null) && data['tickers']) {
            Object.assign(prices, data.tickers);
        }
        this.setState({ prices: prices });
    }

    render = () => {
        let data = this.props.data;
        if ((data || null) && data.length > 0) {
            let tickers = data.map(a => a.symbol);
            const hasAllKeys = tickers.every(item => this.state.prices.hasOwnProperty(item));
            if (!hasAllKeys)
                this.fetchPrices(tickers);
            return <Table size="sm" hover responsive>
                {this.construct_header_rows(this.props.sortMetric)}
                {this.construct_body_rows(data)}
            </Table>;
        }
        return '';
    }
}

export default ResultsTableRender;