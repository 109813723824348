import React, { Component } from "react";
import { Spinner, Row, Col, Badge } from "reactstrap";
import { IMG_CONTAINER } from "../../common/url";
import { formatNumber } from "../../common/numberUtil";
import "./style.css";

export default class StockPriceDisplay extends Component {


    render = () => {
        let tickerPriceObj = this.props.tickerPriceObj;
        let className = this.props.editMode ? "px-0 py-2 mx-2" : "d-none";
        let imgSource = IMG_CONTAINER + 'delete.png';
        let removeImage = <object type="image/jpg" data={imgSource} className="rounded-circle"
            onClick={(e) => this.props.removeTicker(tickerPriceObj.symbol)}
            style={{ maxHeight: '15px', maxWidth: '15px', float: 'right', cursor: 'pointer' }} aria-label="Remove" />;

        return <Row>
            <Col className={className} style={{ position: 'absolute', zIndex: 2 }}>
                <span style={{ float: 'right' }}>{removeImage}</span>
            </Col>
            <Col style={{ position: 'relative', zIndex: 1 }} className="mt-2 text-center h-100% w-100%">
                {this.createText(tickerPriceObj)}
            </Col>
        </Row>;
    }

    createText(data) {
        if (!(data || null)) {
            return <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>;
        }

        let price = data['Latest Price'];
        let priceText = (price || null) ? price : '';
        if ((price || null) && !isNaN(price)) {
            priceText = formatNumber(price);
        }
        let changeText = '0';
        let changePercentText = '0%';
        let change_value = data['Latest Change'];
        let changeClass = "rounded border "
        if (change_value || null) {
            changeText = change_value;
            if (!isNaN(change_value)) {
                change_value = Number(change_value);
                changeText = change_value.toFixed(2);
                if (change_value > 0) {
                    changeText = "+" + changeText;
                    changeClass += "bg-success text-white";
                } else if (change_value < 0) {
                    changeClass += "bg-danger text-white";
                }
                if (!isNaN(price)) {
                    let change_percent = change_value / (price - change_value) * 100.0;
                    change_percent = change_percent.toFixed(1);
                    changePercentText = change_percent + "%";
                }
            }
        }
        let name = data.name ? data.name.slice(0, 100) : '';
        let imgSource = IMG_CONTAINER + data.symbol + '.jpg';
        let earningsCol = 'earnings' in data ?
            <Col xl="auto" lg="auto" md="auto" sm="auto" xs="auto">
                <Badge className={ data.earnings === 'EW' ? "badge-primary" : "badge-danger"}
                    data-toggle="tooltip" 
                    title={ data.earnings === 'EW' ? 
                        "Earnings this week" : data.earnings === 'ET' ?
                            "Earnings Today" : data.earnings === 'EY' ?
                                "Earnings Yesterday" : "Earnings Tomorrow" } >
                    {data.earnings}
                </Badge>
            </Col>
            : '';

        return <Row className="mx-0 border rounded" style={{ cursor: 'pointer' }} 
            onClick={() => window.open(window.location.href.split('watchlist')[0] + 'home/' + data.symbol, '_blank')}>
            <Col xl={10} md={9} sm={9} xs={9} className="text-truncate px-1">
                <Row className="my-0">
                    <Col xl={3} md={3} xs={3} className="pt-1">
                        <object type="image/jpg" data={imgSource} style={{ maxHeight: '20px', maxWidth: '60px' }} aria-label="Image" />
                    </Col>
                    <Col xl={4} md={4} xs={4} className="text-start text-primary"><h5>{data.symbol}</h5></Col>
                    <Col xl={5} md={5} xs={5}><h5 className="text-end"><strong>{priceText}</strong></h5></Col>
                </Row>
                <Row className="small my-0 py-0 pl-1">
                    <Col className="text-start text-truncate"><small>{name}</small></Col>
                    {earningsCol}
                </Row>
            </Col>
            <Col xl={2} md={3} sm={3} xs={3} className={changeClass}>
                <small>
                    <Row className="h-50 my-0 "><Col className="py-0 px-0 my-auto" ><strong>{changeText}</strong></Col></Row>
                    <Row className="h-50 my-0 "><Col className=" py-0 px-0 ">{changePercentText}</Col></Row>
                </small>
            </Col>
        </Row>;
    }
}
