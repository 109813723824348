import { get_object, set_object } from "./localStorage";
import { FULL_LIST_KEY, TWELVE_HOURS_IN_SECONDS, EARNINGS_KEY, INDEX_TICKERS_KEY, THREE_DAYS_IN_SECONDS } from "./constants";
import { get_ticker_list } from "./apiUtil";
import { fetchEarningsData, fetchIndexStocks } from "./dataFetch";

export const getStockNames = async () => {
    let stockLookupData = await get_object(FULL_LIST_KEY);
    if (stockLookupData === null) {
        stockLookupData = await get_ticker_list();
    }
    let stockNames = {};
    if ((stockLookupData || null) && stockLookupData.length > 100) {
        set_object(FULL_LIST_KEY, stockLookupData, TWELVE_HOURS_IN_SECONDS);
        for (let i = 0; i < stockLookupData.length; i++) {
            stockNames[stockLookupData[i]['symbol']] = stockLookupData[i]['companyName'];
        }
    }
    return stockNames;
};

export const getEarningsData = async () => {
    let earningsData = await get_object(EARNINGS_KEY);
    if (earningsData === null) {
        earningsData = await fetchEarningsData();
    }
    if ((earningsData || null) && (earningsData.data || null)) {
        set_object(EARNINGS_KEY, earningsData, TWELVE_HOURS_IN_SECONDS);
    }
    return earningsData;
};

export const getTickerEarningsDate = async (ticker) => {
    let earningsData = await getEarningsData();
    if ((earningsData || null) && earningsData.data[ticker]) {
        let backendDate = earningsData.data[ticker].date;
        return new Date(backendDate + 'T00:00');
    }   
    return null;
};

export const getIndexTickers = async () => {
    let indexTickers = await get_object(INDEX_TICKERS_KEY);
    if (indexTickers === null) {
        indexTickers = await fetchIndexStocks();
    }
    if ((indexTickers || null) && (indexTickers.data || null)) {
        set_object(INDEX_TICKERS_KEY, indexTickers, THREE_DAYS_IN_SECONDS);
    }
    return indexTickers;
};
