
export const get_object = key => {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

export const remove_Object =  key => {
    localStorage.removeItem(key);
}

export const set_object = (key, value, ttl) => {
    if ((key || null) && (value || null) && Number.isInteger(ttl) && ttl > 0) {
        // `item` is an object which contains the original value
        // as well as the time when it's supposed to expire
        const item = {
            value: value,
            expiry: new Date().getTime() + ttl
        }
        try {
            localStorage.setItem(key, JSON.stringify(item));
            return true;
        } catch(err) {
            return false;
        }
    }
    return false;
}