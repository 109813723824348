import React, { Component } from 'react';
import { Card, CardText, Row, Col } from 'reactstrap';
import { add_comma_to_number } from '../../common/numberUtil';
import ResultsTableRender from './resultsTableRender';

class StockScreenResultDisplay extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            display_count: 15,
            sort_metric: 'return',
            sort_ascending: false,
        }
    }

    componentDidMount = () => {
        this.scrollListener = window.addEventListener("scroll", e => {
            this.handleScroll(e);
        });
    }

    handleScroll = async () => {
        var lastLi = document.querySelector("Table");
        // If table not found return 
        if (!(lastLi || null) || !(this.props.renderData || null)) {
            return
        }
        var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
        var pageOffset = window.pageYOffset + window.innerHeight;
        let row_count = this.state.display_count;
        let max_rows = this.props.renderData.length;
        if (pageOffset > lastLiOffset && row_count < max_rows) {
            row_count += 10;
            if (row_count > max_rows) {
                row_count = max_rows;
            }
            this.setState({ display_count: row_count });
        }
    }

    sortByMetric = async (metric) => {
        let sortOrder = this.state.sort_ascending;
        if (this.state.sort_metric === metric) {
            sortOrder = !sortOrder;
        }
        this.setState({
            sort_ascending: sortOrder,
            sort_metric: metric
        });
    }

    render = () => {
        let response_render = "";
        let renderData = this.props.renderData;
        if ((renderData || null) && renderData.length > 0) {
            let metric = this.state.sort_metric;
            let sortOrder = this.state.sort_ascending;

            const sorted_array = renderData.sort((a, b) => {
                let res = 1;
                let am = a[metric];
                let bm = b[metric];
                if(am && am !== '') {
                    if (bm && bm !== '') {
                        res = am > bm ? 1 : -1;
                    } else {
                        res = 1;
                    }
                } else if (bm && bm !== '') {
                    res = -1;
                }
                return sortOrder ? res : (res * -1);
            });
            //alert(JSON.stringify(sorted_array[0]));
            let resultsCountDisplay = true;
            if ('resultsCountDisplay' in this.props) 
                resultsCountDisplay = this.props.resultsCountDisplay;
            response_render = <div>
                {
                    resultsCountDisplay ?
                        <Card className={"mb-1 h-100% w-100% bg-secondary"}>
                            <CardText className="px-1 py-1 text-center text-white">
                                {add_comma_to_number(renderData.length)}{' '}stocks Found
                    </CardText>
                        </Card> : ''
                }
                <Row className="flex-grow-1"><Col>
                    <ResultsTableRender
                        sortMetric={metric}
                        data={sorted_array.slice(0, this.state.display_count)}
                        stockNames={this.props.stockNames}
                        sort_ascending={this.state.sort_ascending}
                        sort={this.sortByMetric} 
                        refreshWatchList={this.refreshWatchList}
                        {...this.props} />
                </Col></Row>
                <ul></ul>
            </div>;
        } else {
            response_render = <div>Stocks Not Found.</div>
        }
        return <>{response_render}</>;
    }
}

export default StockScreenResultDisplay;