import React, { Component } from "react";
import {
    Modal, ModalHeader, ModalBody, Button, Row, Col, Input
} from 'reactstrap';

export default class CreateWatchList extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            watchlistName: ''
        }
    }

    create_watchlist = () => {
        if (this.state.watchlistName.length > 0) {
            this.props.createWatchlist(this.state.watchlistName);
        }
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.create_watchlist();
        }
    }

    render() {
        let closeCreateWatchlistWindow = this.props.closeCreateWatchlistWindow;

        return (
            <Modal isOpen={true} toggle={() => closeCreateWatchlistWindow()} size="sm" centered={true}>
                <ModalHeader>Create watchList</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={10}>
                            <Input type="text" size="sm" value={this.state.watchlistName}
                                onChange={(e) => this.setState({ watchlistName: e.target.value })}
                                id="watchlist" placeholder="Watchlist Name"
                                onKeyDown={(e) => this.handleKeyDown(e)} />
                        </Col>
                        <Col xl={2}>
                            <Button color="primary" size="sm" onClick={this.create_watchlist} style={{ marginBottom: '1rem' }}>+</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}