import {
    HISTORICAL_URL, REALTIME_URL, PROFILE_URL, FINANCIALS_URL, CURRENT_URL, NEWS_URL,
    INDEX_TICKERS_URL, EARNINGS_URL, ALERTS_URL, VPORTFOLIO_URL, 
    vportfolio_transaction_url, KEY_DATA_URL, RELATED_STOCKS_URL, RATINGS_URL
} from "./url";
import { executeRestRequest } from "./restService";

export const fetchRealtimeData = async (ticker) => {
    let requestData = {
        url: REALTIME_URL + ticker,
        method: 'GET'
    };
    let res = await executeRestRequest(requestData);
    if ('data' in res && 'tickers' in res.data &&
        ticker in res.data.tickers && 'realtime' in res.data.tickers[ticker]) {
        return res.data.tickers[ticker].realtime;
    }
    return {};
}

export const fetchRealtimePrices = async (tickers) => {
    let requestData = {
        url: REALTIME_URL + tickers.join(','),
        method: 'GET'
    };
    let res = await executeRestRequest(requestData);
    if ('data' in res && 'tickers' in res.data) {
        return res.data;
    }
    return {};
}

export const fetchProfileData = async (ticker) => {
    let requestData = {
        url: PROFILE_URL + ticker,
        method: 'GET'
    };
    let res = await executeRestRequest(requestData);
    if ('data' in res && 'tickers' in res.data &&
        ticker in res.data.tickers && 'profile' in res.data.tickers[ticker]) {
        return res.data.tickers[ticker].profile;
    }
    return {};
}

export const fetchFinancialsData = async (ticker, period) => {
    let requestData = {
        url: FINANCIALS_URL + period + '/all/' + ticker,
        method: 'GET'
    };
    let res = await executeRestRequest(requestData);
    if ('data' in res) {
        return res.data;
    }
    return {};
}

export const fetchHistoricalDailyPrices = async ticker => {
    let requestData = {
        url: HISTORICAL_URL + ticker,
        method: 'GET'
    };
    let res = await executeRestRequest(requestData);
    if ('data' in res && 'tickers' in res.data &&
        ticker in res.data.tickers && 'historical' in res.data.tickers[ticker]) {
        return res.data.tickers[ticker].historical;
    }
    return [];
}

export const fetchCurrentMinutePrices = async ticker => {
    let requestData = {
        url: CURRENT_URL + ticker,
        method: 'GET'
    };
    let res = await executeRestRequest(requestData);
    if ('data' in res && 'tickers' in res.data &&
        ticker in res.data.tickers && 'current' in res.data.tickers[ticker]) {
        return res.data.tickers[ticker].current;
    }
    return [];
}

export const fetchStockNews = async ticker => {
    let requestData = {
        url: NEWS_URL + ticker,
        method: 'GET'
    };
    let res = await executeRestRequest(requestData);
    if ('data' in res)
        return res.data;
    return [];
}

export const fetchStockRatings = async ticker => {
    let requestData = {
        url: RATINGS_URL + ticker,
        method: 'GET'
    };
    let res = await executeRestRequest(requestData);
    if ('data' in res)
        return res.data;
    return [];
}

export const fetchRelatedStocks = async ticker => {
    let requestData = {
        url: RELATED_STOCKS_URL + ticker,
        method: 'GET'
    };
    let res = await executeRestRequest(requestData);
    if ('data' in res)
        return res.data;
    return {};
}

export const fetchKeyTickerData = async ticker => {
    let requestData = {
        url: KEY_DATA_URL + ticker,
        method: 'GET'
    };
    let res = await executeRestRequest(requestData);
    if ('data' in res && 'tickers' in res.data && ticker in res.data.tickers) {
        return res.data.tickers[ticker];
    }
    return [];
}

export const fetchIndexStocks = async () => {
    let requestData = {
        url: INDEX_TICKERS_URL,
        method: 'GET'
    };
    let res = await executeRestRequest(requestData);
    return res;
}

export const fetchEarningsData = async () => {
    let requestData = {
        url: EARNINGS_URL,
        method: 'GET'
    };
    let res = await executeRestRequest(requestData);
    return res;
}

export const addTickerAlert = async (alert_type, alert_data) => {
    let requestData = {
        url: ALERTS_URL + alert_type,
        method: 'PUT',
        payload: alert_data
    };
    let res = await executeRestRequest(requestData);
    return res;
}

export const deleteTickerAlert = async (alert_type, alert_data) => {
    let requestData = {
        url: ALERTS_URL + alert_type,
        method: 'DELETE',
        payload: alert_data
    };
    let res = await executeRestRequest(requestData);
    return res;
}

export const getUserPortfolios = async () => {
    let requestData = {
        url: VPORTFOLIO_URL,
        method: 'GET'
    }
    let res = await executeRestRequest(requestData);
    return res;
}

export const createVportfolio = async (data) => {
    let requestData = {
        url: VPORTFOLIO_URL,
        method: 'POST',
        payload: data
    };
    //alert(JSON.stringify(requestData));
    let res = await executeRestRequest(requestData);
    //alert(JSON.stringify(res));
    return res;
}

export const submitPortfolioTransaction = async (portfolio_id, data) => {
    let requestData = {
        url: vportfolio_transaction_url(portfolio_id),
        method: 'PUT',
        payload: data
    };
    let res = await executeRestRequest(requestData);
    return res;
}

