import { TICKER_LIST_URL, watchlist_tickers_add_delete_url } from "./url";
import { executeRestRequest } from "../common/restService";

export const get_ticker_list = async () => {
  return await fetch(TICKER_LIST_URL, { mode: 'cors' })
    .then(response => {
      return response.json();
    })
    .then(data => {
      let stock_list = data.symbols;
      let new_list = [];
      for (var i = 0; i < stock_list.length; i++) {
        let item = stock_list[i];
        let ticker = null;
        for (var k in item) {
          ticker = k;
        }
        let new_item = item[ticker];
        new_item['symbol'] = ticker;
        new_list.push(new_item);
      }
      return new_list;
    })
    .catch(err => {
      console.log('-----> In fetch_full_ticker_list <--------');
      console.log(err);
    });
}


export const addTickerToWatchList = async (params) => {
  let watchListId = params.id;
  let ticker = params.ticker;
  let url = watchlist_tickers_add_delete_url(watchListId);
  let requestData = {
    url: url,
    method: 'PUT',
    payload: { 'tickers': [ticker] }
  }
  let res = await executeRestRequest(requestData);
  // This will call refreshWatchList function in APP.js
  params.resultSetFunction(res.data);
}



export const moveTicker = async (params) => {
  let fromWatchListId = params.fromWatchListId;
  let toWatchListId = params.toWatchListId;
  let ticker = params.ticker;
  let url = watchlist_tickers_add_delete_url(fromWatchListId);
  let requestData = {
    url: url,
    method: 'DELETE',
    payload: { 'tickers': [ticker] }
  }
  let res = await executeRestRequest(requestData);

  url = watchlist_tickers_add_delete_url(toWatchListId);
  requestData = {
    url: url,
    method: 'PUT',
    payload: { 'tickers': [ticker] }
  }
  res = await executeRestRequest(requestData);
  // This will call refreshWatchList function in APP.js
  params.resultSetFunction(res.data);

  // Set to watchlist page also.
}


export const setWindowTitle = (title) => {
  document.title = title;
}