

export function number_full_to_short_form(labelValue) {
    // Twelve Zeroes for Trillions
    if(isNaN(labelValue))
        return labelValue;
    let num = Number(labelValue);    
    let negativeNumber = num < 0 ? true : false;
    let returnVal = Math.abs(num) >= 1.0e+12
        ? Math.round((Math.abs(num) / 1.0e+12) * 100) / 100 + "T"
        // Nine Zeroes for Billions
        : Math.abs(num) >= 1.0e+9
            ? Math.round((Math.abs(num) / 1.0e+9) * 100) / 100 + "B"
            // Six Zeroes for Millions 
            : Math.abs(num) >= 1.0e+6
                ? Math.round((Math.abs(num) / 1.0e+6) * 100) / 100 + "M"
                // Three Zeroes for Thousands
                : Math.abs(num) >= 1.0e+3
                    ? Math.round((Math.abs(num) / 1.0e+3) * 100) / 100 + "K"
                    : Number.isInteger(num)
                        ?  num
                        : num.toString().split('.')[1].length < 2
                            ? num 
                            : num < 1000 
                                ? Math.abs(num).toFixed(2) 
                                : Math.abs(num).toFixed(1);
    return negativeNumber ? ('-' + returnVal) : returnVal; 
}

export let add_comma_to_number = num => {
    return num.toLocaleString('en');
}

export const formatNumber = num => {
    if(isNaN(num)) {
        return num;
    }
    return Number(num).toLocaleString("en", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });
}

export const formatPercent = num => {
    return isNaN(num) ? num : String((num * 100.0).toFixed(2)) + '%';
}

export function number_short_to_full_form(number_name) {

    if (!(number_name || null)) {
        return null;
    }
    let number_str = number_name.toString();
    let allowed_number_name_object = {
        K: 1.0e+3, k: 1.0e+3,
        M: 1.0e+6, m: 1.0e+6,
        B: 1.0e+9, b: 1.0e+9,
        T: 1.0e+12, t: 1.0e+12
    };

    let split_by_name = "";

    for (let name_key in allowed_number_name_object) {
        if (number_str.includes(name_key)) {
            split_by_name = name_key;
            break;
        }
    }

    let converted_value = number_name;
    if (split_by_name.length > 0) {
        let number_name_fields = number_str.split(split_by_name);
        converted_value = parseFloat(number_name_fields[0]) * allowed_number_name_object[split_by_name];
    }
    return converted_value;
}

