import React, { Component } from "react";
import { INDEX_GROUPS, INDEX_GROUP_URLS, SEARCH_OBJECT_KEY, TWELVE_HOURS_IN_SECONDS } from '../../common/constants';
import { Row, Col, Badge } from "reactstrap";
import { getTickerSearchData, addPriceToSales } from "./util";
import { get_object, set_object } from "../../common/localStorage";
import { getStockNames } from "../../common/storageUtil";
import StockScreenResultDisplay from "./stockScreenResultDisplay";
import { AMPLITUDE_EVENT_TYPES, sendAmplitudeData } from '../../common/amplitude';
import { PAGE_NAMES } from '../../common/constants';
import { Helmet } from "react-helmet";
import { withNavigateHook } from '../../common/navigate';

class IndexStocksDisplay extends Component {

    constructor(props, context) {
        super(props, context);
        sendAmplitudeData(AMPLITUDE_EVENT_TYPES.PAGE_VIEW, { 'name': PAGE_NAMES.INDEX });
        this.state = {
            show_spinner: false,
            selectedIndexPos: 0,
            stockData: [],
            stockNames: {}
        }
    }

    componentDidMount = async () => {
        this.setState({ show_spinner: true });
        //let exchange = this.props.router.params.exchange;
        let exchange = 'nasdaq';
        let allStockData = await get_object(SEARCH_OBJECT_KEY);
        if (!(allStockData || null)) {
            allStockData = await getTickerSearchData({});
            addPriceToSales(allStockData);
            set_object(SEARCH_OBJECT_KEY, allStockData, TWELVE_HOURS_IN_SECONDS);
        }
        let stockNames = await getStockNames();
        let stockData = [];
        let stateObjToSet = {
            stockData: stockData,
            stockNames: stockNames,
            show_spinner: false
        };
        for (let j = 0; j < INDEX_GROUPS.length; j++) {
            stockData[j] = [];
        }
        for (let i = 0; i < allStockData.length; i++) {
            for (let j = 0; j < INDEX_GROUPS.length; j++) {
                if (allStockData[i][INDEX_GROUPS[j]])
                    stockData[j].push(allStockData[i]);
            }
        }
        if (exchange || null) {
            let selectedIndexPos = INDEX_GROUP_URLS.indexOf(exchange);
            if (selectedIndexPos >= 0 && selectedIndexPos < 3)
                stateObjToSet['selectedIndexPos'] = selectedIndexPos;
            //else
            //    this.props.history.push('/index/' + INDEX_GROUP_URLS[0]);
        } else {
            //this.props.history.push('/index/' + INDEX_GROUP_URLS[0]);
        }
        this.setState(stateObjToSet);
    }

    setIndexPos = async (pos) => {
        this.setState({ selectedIndexPos: pos });
        //this.props.history.push('/index/' + INDEX_GROUP_URLS[pos]);
        this.props.navigation('/index/' + INDEX_GROUP_URLS[pos]);
    }

    render = () => {
        let renderData = this.state.stockData[this.state.selectedIndexPos];
        const metaDesc = "Shows prices, logo, 1 yr returns, revenue, net income, " +
            "sector, industry, price to earnings (p/e) and price to sales (p/s) " +
            "of stocks in " + INDEX_GROUPS[this.state.selectedIndexPos] + " index. " + 
            "It allows to sort stocks by different parameters like ticker symbol, " + 
            "name, market capitalization, beta, returns, p/e, p/s, " + 
            "revenue, net income, sector and industry.";
        const title = "Prices & other details of stocks in " +
            INDEX_GROUPS[this.state.selectedIndexPos] + " index.";
        return <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta name="description" content={metaDesc} />
            </Helmet>
            <Row className="my-3">
                <Col xl="auto" xs="auto" md="auto" sm="auto" lg="auto" className="mx-auto">
                    {INDEX_GROUPS.slice(0, 3).map((label, i) => (<>
                        <Badge style={{ cursor: 'pointer' }} onClick={() => this.setIndexPos(i)}
                            color={this.state.selectedIndexPos === i ? "success" : "secondary"} pill>
                            <small>{label}</small>
                        </Badge><span> </span></>
                    ))}
                </Col>
            </Row>
            <Row style={{ height: '10px' }}></Row>
            {
                (renderData || null) && renderData.length > 0 ?
                    <StockScreenResultDisplay renderData={renderData} stockNames={this.state.stockNames} resultsCountDisplay={false} />
                    : ''
            }
        </>;
    }
}

export default withNavigateHook(IndexStocksDisplay);