import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Collapse, NavbarBrand, Navbar, NavbarToggler, Nav, NavItem, UncontrolledDropdown,
  DropdownToggle, DropdownMenu, DropdownItem, Card, Col
} from "reactstrap";
import { MAIN_MENU, PAGE_NAMES } from './constants';
import { isMobile } from 'react-device-detect';
import { IMAGE_URLS } from '../../common/url'


export default class Menu extends Component {
  constructor(props) {
    super(props);
    let arr = window.location.toString().split('/');
    let lastElem = arr[arr.length - 1].toLowerCase();
    let pageIndex = 0;
    for (let i = 0; i < PAGE_NAMES.length; i++) {
      if (PAGE_NAMES[i] === lastElem)
        pageIndex = i;
    }
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.closeNavbar = this.closeNavbar.bind(this);
    this.state = {
      collapsed: true,
      pageIndex: pageIndex
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  closeNavbar() {
    if (this.state.collapsed !== true) {
      this.toggleNavbar();
    }
  }

  classNameRender = index => {
    let className = "nav-link navigation__navlinks pt-1 pb-0 px-0";
    return this.state.pageIndex === index ?
      className + " text-primary" : className + " text-secondary";
  }

  setPageIndex = (pageIndex) => {
    this.setState({ pageIndex: pageIndex });
    this.closeNavbar();
  }

  render() {
    let render_menu = MAIN_MENU;

    let auth_obj = this.props.isLoggedIn ? ['/logout', 'Logout', IMAGE_URLS.LOGOUT] : ['/login', 'Login', IMAGE_URLS.LOGIN];
    let loginData = this.props.loginData;

    let loginRender = <NavItem>
      {this.props.isLoggedIn ? (
        <UncontrolledDropdown className="text-right" nav inNavbar>
          <DropdownToggle className="text-primary text-right" nav caret>
            <img src={loginData.picture} alt={loginData.name} class="rounded-circle" height="35" width="35" />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem >
              {loginData.name}
            </DropdownItem>
            <DropdownItem>
              {loginData.email}
            </DropdownItem>
            <DropdownItem>
              <Link className="nav-link navigation__navlinks text-primary" to={auth_obj[0]} onClick={this.closeNavbar}>
                <object type="image/jpg" data={auth_obj[2]}
                  style={{ maxHeight: '15px', maxWidth: '15px', cursor: 'pointer' }} aria-label="Image" />
                {'  '}
                {auth_obj[1]}
              </Link>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ) :
          <Link className="nav-link navigation__navlinks text-secondary" to={auth_obj[0]} onClick={this.closeNavbar}>
            <Card className="border-0">
              <Col className={"px-1 py-0 my-0 " + (isMobile ? "text-right" : "text-center") }>
                <object type="image/jpg" data={auth_obj[2]}
                  style={{ maxHeight: '15px', maxWidth: '15px', cursor: 'pointer' }} aria-label="Image" /> </Col>
              <Col className={"px-1 py-0 my-0 " + (isMobile ? "text-right" : "text-center") }> {auth_obj[1]} </Col>
            </Card>
          </Link>
      }
    </NavItem>;

    let companyName = <NavbarBrand href="/Home" className="text-primary font-weight-bold text-center">InvestLucid</NavbarBrand>;
    let mobileMenu = <NavbarToggler onClick={this.toggleNavbar} className="mr-0 px-0 py-0 bg-primary" size="sm" />;

    return (
      <Navbar className="navigation__navbar navbar-dark bg-white py-0 px-0" light expand="sm">
        {companyName}
        {isMobile ? mobileMenu : ''}
        <Collapse isOpen={!this.state.collapsed} navbar>
          <Nav className="mr-auto my-auto ml-2" navbar>
            {render_menu.map((menu, i) => (
              <NavItem>
                <Link className={this.classNameRender(i)} to={menu.path} onClick={() => this.setPageIndex({ i })}>
                  <Card className="border-0">
                    <Col className={"px-1 py-0 my-0 " + (isMobile ? "text-right" : "text-center") }>{menu.icon}</Col>
                    <Col className={"px-1 py-0 my-0 " + (isMobile ? "text-right" : "text-center") }>{menu.name}</Col>
                  </Card>
                </Link>
              </NavItem>
            ))}
            {isMobile ? loginRender : ''}
          </Nav>
        </Collapse>
        {isMobile ? '' : <Nav navbar>{loginRender}</Nav>}
      </Navbar>
    );
  }
}