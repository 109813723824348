import { IMG_CONTAINER, IMAGE_URLS } from '../../common/url';  
 
export const SORT_IMAGE_URL = IMG_CONTAINER + IMAGE_URLS.SORT;
export const TICKER_SORT_OPTIONS = [
    {
        sort_by: 'change'     
    },
    {
        sort_by: 'symbol'       
    }, 
    {
        sort_by: 'name'       
    }, 
];