import React, { Component } from 'react';
import { Row, Col, Badge, Spinner } from 'reactstrap';
import { number_full_to_short_form } from '../../common/numberUtil';
import { PROFILE_METRICS, PRICE_RANGE_PERIODS } from './constants';
import {
    PERCENT_FORMAT_METRICS, CURRENCY_FORMAT_METRICS, DECIMAL_FORMAT_METRICS, NUMERIC_FORMAT_METRICS,
    DISPLAY_NAME_MAP, INDEX_GROUPS, INDEX_BADGE_COLORS, TOOLTIP, COUNTRY_CODES
} from '../../common/constants';
import { IMG_CONTAINER, IMAGE_URLS } from '../../common/url';
import LucidDropDownMenu from '../../components/dropdownmenu/dropDownMenu';
import { isMobile } from 'react-device-detect';
import { isDateInCurrentWeek, daysBetweenTwoDates } from '../../common/timeUtil';


export const SORT_IMAGE_URL = IMG_CONTAINER + IMAGE_URLS.SORT;

export default class ProfileDisplay extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            show_spinner: false
        }
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (JSON.stringify(nextState) === JSON.stringify(this.state) &&
            JSON.stringify(nextProps) === JSON.stringify(this.props)) {
            return true;
        }
        return true;
    }

    displayMetric = (name, value, link, indexBadges) => {
        if (name === 'lastDiv' && value <= 0)
            return '';

        let displayValue = value;
        if (name === 'country') {
            if (value === 'US')
                return '';
            displayValue = COUNTRY_CODES[value];
        }
        if (PERCENT_FORMAT_METRICS.includes(name) && displayValue !== '' && !isNaN(displayValue))
            displayValue = (displayValue * 100.0).toFixed(1) + '%';
        if (CURRENCY_FORMAT_METRICS.includes(name) && displayValue !== '' && !isNaN(displayValue))
            displayValue = '$' + number_full_to_short_form(displayValue);
        if (NUMERIC_FORMAT_METRICS.includes(name) && displayValue !== '' && !isNaN(displayValue))
            displayValue = number_full_to_short_form(displayValue);
        if (DECIMAL_FORMAT_METRICS.includes(name) && displayValue !== '' && !isNaN(displayValue))
            displayValue = displayValue.toFixed(2);

        let displayName = name;
        if (Object.keys(DISPLAY_NAME_MAP).includes(name))
            displayName = DISPLAY_NAME_MAP[displayName];

        if (name === 'lastDiv' && !isNaN(this.props.price)) {
            let divYield = (value / this.props.price * 100.0).toFixed(2) + '%';
            displayValue = displayValue + ' (' + divYield + ')';
        }

        if (name === 'earnings_date' && (value || null)) {
            displayValue = value.split('T')[0];
            //displayValue = new Date(value + 'T00:00').toDateString();
            //displayValue = displayValue.substr(0, displayValue.length - 5);
        }
        let renderData = displayValue;

        if (link !== null) {
            renderData = <a href={link} target="_blank" rel="noopener noreferrer">
                <span className="text-success">{displayValue}</span>
            </a>;
        }
        let imgSource = IMG_CONTAINER + 'question.png';
        let watchListName = this.props.watchList;
        let watchLists = this.props.watchLists;
        let menu_items = [];
        // If Ticker is not exist in any of the watchlist, then only construct the MENU_ITEMS
        if (watchListName === '') {
            if (watchLists || null) {
                for (let i = 0; i < watchLists.length; i++) {
                    menu_items.push({
                        item_name: watchLists[i].name,
                        parammeters: {
                            id: watchLists[i].id,
                            ticker: this.props.ticker
                        }
                    });
                }
            }
        }

        let earningsDateMessage = '';
        if (name === 'earnings_date' && (value || null)) {
            let dt = new Date(value.split('T')[0]);
            //displayValue = dt.toDateString();
            //displayValue = displayValue.substr(0, displayValue.length - 5);
            let today = new Date();
            today.setHours(0, 0, 0, 0);
            let days = daysBetweenTwoDates(today, dt);
            if (dt >= today && [0, 1].includes(days)) {
                earningsDateMessage = ['Today', 'Tomorrow'][days];
            } else if (dt < today && days === 1) {
                earningsDateMessage = 'Yesterday';
            } else if (isDateInCurrentWeek(dt) === true) {
                earningsDateMessage = 'This Week';
            }
        }

        return <Row>
            <Col className="text-dark text-truncate" xl={4} md={4} xs={5}>
                {isMobile ? <small>{displayName}</small> : displayName}
                {
                    name in TOOLTIP && !isMobile ?
                        <><span> </span>
                            <object type="image/jpg" data={imgSource}
                                style={{ height: '14px', width: '14px' }}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={TOOLTIP[name]}
                                aria-label="Image" />
                        </> : ''
                }
            </Col>
            <Col xl={8} md={8} xs={7} className="text-success">
                <Row>
                    <Col className={displayName === 'Name' ? "text-truncate" : ""}>
                        {renderData}
                    </Col>
                    {
                        (displayName === 'Name' && this.props.profile.isEtf) ?
                            <Col xl="auto" md="auto" xs="auto"><Badge color="primary" pill>ETF</Badge></Col> : ''
                    }

                    {name === 'mktCap' ? <Col xl="auto" md="auto" xs="auto">{indexBadges}</Col> :
                        name === 'companyName' ? <Col xl="auto" md="auto" xs="auto">
                            {
                                watchListName !== '' ?
                                    <Badge color="success" pill>{watchListName}</Badge>
                                    : menu_items.length > 0 ?
                                        <LucidDropDownMenu image_url={IMG_CONTAINER + IMAGE_URLS.ADD}
                                            menu_items={menu_items}
                                            height="18px"
                                            onClickFunction={this.props.addTickerToWatchList}
                                            selected_menu_item_name="" />

                                        : ''
                            }
                        </Col> : ''
                    }                    
                    {
                        name === 'earnings_date' && earningsDateMessage !== null ?
                            <Col xl="auto" md="auto" xs="auto">
                                <Row> <Col> <Badge color="danger" pill>{earningsDateMessage}</Badge></Col></Row>
                            </Col> : ""

                    }
                </Row>
            </Col>
        </Row>;
    }

    getIndexBadges = (data) => {
        let found = false;
        for (let i = 0; i < INDEX_GROUPS.length; i++) {
            if (data[INDEX_GROUPS[i]]) {
                found = true;
            }
        }
        if (!found)
            return '';
        let res = INDEX_GROUPS.map((index_grp, i) => {
            return data[index_grp] ? <><span> </span><Badge color={INDEX_BADGE_COLORS[i]}>{INDEX_GROUPS[i]}</Badge></> : '';
        });
        return <Col xl="auto" md="auto" xs={12} className="px-0">{res}</Col>;
    };

    renderRows = (data) => {
        let newslink = 'https://news.google.com/search?q=' + this.props.ticker + '+stock';
        let metrics = PROFILE_METRICS.filter(x => Object.keys(data).includes(x));
        metrics = metrics.filter(x => data[x] !== '');        

        if (metrics.length > 0) {
            let indexBadges = this.getIndexBadges(data);
            let rows = metrics.map(metric => (
                <Col xl={12} md={12} xs={12}>
                    {this.displayMetric(metric, data[metric],
                        metric === 'companyName' && Object.keys(data).includes('website') ? data['website'] :
                            metric === 'sector' && Object.keys(data).includes('yahoo link') ? data['yahoo link'] : 
                            metric === 'industry' ? newslink : null,
                        indexBadges)}
                </Col>
            ));

            return <Row>{rows}</Row>;
        }
    }

    render = () => {
        if (this.state.show_spinner)
            return <div align="center">
                <Spinner color="primary" animation="border" role="status" />
            </div>;

        let data = this.props.profile;
        let header = <Col xl="auto" md="auto" xs={"auto"} ><Row><Col>Duration</Col></Row><Row><Col>Return</Col></Row></Col>;
        if ((data || null) && Object.keys(data).length > 0) {
            let getReturnTable = () => {
                if (!('range' in data)) {
                    return '';
                }
                let rangeMap = data['range'];
                if (Object.keys(rangeMap).length < 1)
                    return '';
                let res = PRICE_RANGE_PERIODS.map(period => {
                    if (!(period in rangeMap))
                        return '';
                    let value = rangeMap[period];
                    let className = value > 0 ? "text-success" : "text-danger";
                    let displayValue = (value * 100.0).toFixed(isMobile ? 0 : 1) + '%';
                    return <Col className="px-0">
                        <Row><Col>{isMobile ? <small>{period}</small> : period}</Col></Row>
                        <Row><Col className={className}>{isMobile ? <small>{displayValue}</small> : displayValue}</Col>
                        </Row></Col>;
                });
                return <>{header}{res}</>;
            };

            let returnTable = getReturnTable();
            return <Row className={"border rounded text-primary px-0 mt-1 py-2 " + (isMobile ? "" : " mx-0")}>
                <Col>
                    {this.renderRows(data)}
                    <Row className="border rounded">{returnTable}</Row>
                </Col>
            </Row>;
        }

        return <Row className={"border rounded text-primary px-0 mt-1 py-2 " + (isMobile ? "" : " mx-0")}>
            <Col>
                <Row>
                    {PROFILE_METRICS.map(metric => (
                        <Col xl={12} md={12} xs={12}>
                            <Row>
                                <Col className="text-dark mr-1"><small>{metric}</small></Col>
                            </Row>
                        </Col>
                    ))}
                </Row>
                <Row>{header}</Row>
            </Col>
        </Row>;
    }
}