import React, { Component } from 'react';
import { Row, Col, Card, CardText } from 'reactstrap';
import { isMobile } from "react-device-detect";

export default class NewsDisplay extends Component {

    ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 1
            }}
        />
    );

    render = () => {
        let news = this.props.news;
        if (!(news || null) || !Array.isArray(news) || news.length < 1)
            return '';
        let newsRender = news.map(newsItem => (
            <Row className="border">
                <Col xl="auto" md="auto" xs={4} className="my-auto mx-auto">
                    <Row><Col xl="auto" xs="auto" md="auto" sm="auto" lg="auto" className="mx-auto">
                        <object type="image/jpg" data={newsItem.image}
                        style={{ maxHeight: '100px', maxWidth: '100px' }} aria-label="News" />
                    </Col></Row>
                </Col>
                {
                    isMobile ?
                        <>
                            <Col xs={8} className="text-success">
                                <a href={newsItem.url} target="_blank" rel="noopener noreferrer">
                                    {newsItem.title}
                                </a>
                            </Col>
                            <Col xs={12} className="text-primary">
                                {newsItem.text}
                            </Col>
                        </>
                        :
                        <Col>
                            <Row>                                
                                <Col xl={12} md={12} xs={10}>
                                    <a href={newsItem.url} target="_blank" rel="noopener noreferrer">
                                        <span className="text-success">{newsItem.title}</span>
                                    </a>
                                </Col>
                                <Col xl={12} md={12} xs={12} >
                                    <small className="text-muted">{newsItem.site}{' > '}{ newsItem.publishedDate.slice(0, -3) }</small>
                                </Col>
                                <Col xl={12} md={12} xs={12}>
                                    {newsItem.text}
                                </Col>
                            </Row>
                        </Col>
                }
            </Row>
        ));
        return <Row className={"border rounded text-primary px-0 mt-1 py-0 mx-0"}>
            <Col xl={12} md={12} sm={12} xs={12} className="mx-0 px-0">
                <Card className={"mb-1 h-100% w-100% bg-secondary"}>
                    <CardText className="px-1 py-0 text-center text-white">
                        Latest News
                    </CardText>
                </Card>
            </Col>
            <Col style={{ "overflow-y": "scroll", "height": "250px" }}>{newsRender}</Col>
        </Row>;
    }
}