import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Row, Col, Container } from "reactstrap";
import Home from './pages/home/home';
import Movers from './pages/movers/movers';
import WatchList from './pages/watchlist/watchlist';
import Research from './pages/research/research';
import Earnings from './pages/earnings/earnings';
import Menu from './pages/menu/menu';
import Login from './pages/auth/login';
import Index from './pages/index/index';
import { GET_USER_DETAILS_URL } from './common/url';
import { executeRestRequest } from './common/restService';
import Logout from './pages/auth/logout';
import { setAmplitudeUserId } from './common/amplitude';
import Alerts from './pages/alerts/alerts';
import VPortfolio from './pages/vportfolio/vportfolio';
import IndexStocksDisplay from './components/stockscreener/indexStocksDisplay';

class App extends React.Component {

    state = {
      isLoggedIn: false,
      watchLists: [],
      alerts: {},
      loginData: {}
    }


  componentDidMount = async () => {
    await this.loadUserData();
  }

  login = async (loginData) => {
    await this.loadUserData();
  }

  loadUserData = async () => {
    let stateObjToSet = {};
    let resp = await executeRestRequest({ url: GET_USER_DETAILS_URL, method: 'GET' });
    if ((resp || null) && resp['data'] && resp.data['profile']) {
      stateObjToSet['isLoggedIn'] = true;
      stateObjToSet['loginData'] = resp.data.profile;
      setAmplitudeUserId(resp.data.profile.email);
      if (resp.data['watchlists']) {
        stateObjToSet['watchLists'] = resp.data.watchlists;
      }
      if (resp.data['alerts']) {
        stateObjToSet['alerts'] = resp.data.alerts;
      }
    }
    if (Object.keys(stateObjToSet).length > 0) {
      this.setState(stateObjToSet);
    }
  }

  logout = () => {
    this.setState({ isLoggedIn: false, loginData: {}, watchLists: [], alerts: [] });
  }

  refreshWatchList = (watchLists) => {
    if (this.state.isLoggedIn) {
      this.setState({ watchLists: watchLists });
    }
  }

  refreshAlerts = (alerts) => {
    if (this.state.isLoggedIn) {
      this.setState({ alerts: alerts });
    }
  }

  render() {
    //let url = window.location.pathname;

    return (<Container fluid={true}>
      <Router>
          <Menu isLoggedIn={this.state.isLoggedIn} loginData={this.state.loginData} />
        <Row>
          <Col xl={1} md={12} className="mx-0">
            <Index />
          </Col>
          <Col xl={10} md={12} >
          <Routes>
            <Route exact path="/" element={<Home watchLists={this.state.watchLists}
                refreshWatchList={this.refreshWatchList} />}
            />

            <Route exact path="/home" element={<Home watchLists={this.state.watchLists}
                refreshWatchList={this.refreshWatchList} />}
            />

            <Route exact path="/movers" element={<Movers/>}
            />

            <Route exact path="/index" element={<IndexStocksDisplay />}
            />

            <Route exact path="/index/:exchange" element={<IndexStocksDisplay/>}
            />

            <Route exact path="/home/:ticker"
            element={<Home watchLists={this.state.watchLists}
                refreshWatchList={this.refreshWatchList} />}
            />

            <Route path="/watchlist/:name?"
            element={<WatchList/>} loader={(props) =>
              <WatchList refreshWatchList={this.refreshWatchList}
                {...props} />}
            />

            <Route path="/research"
            element={<Research watchLists={this.state.watchLists}
                refreshWatchList={this.refreshWatchList} />}
            />

            <Route path="/earnings"
            element={<Earnings watchLists={this.state.watchLists} />}
            />

            <Route path="/alerts"
            element={<Alerts alerts={this.state.alerts}
            refreshAlerts={this.refreshAlerts}
                isLoggedIn={this.state.isLoggedIn}/>}
            />

            <Route path="/vportfolios"
            element={<VPortfolio isLoggedIn={this.state.isLoggedIn} />}

            />

            <Route path="/login"
            element={<Login isLoggedIn={this.state.isLoggedIn}
                loginData={this.state.loginData}
                login={this.login}
                prevPath={this.state.prevPath} />}
            />

            <Route path="/logout"
            element={<Logout logout={this.logout}/>}
            />

            </Routes>

          </Col>
          <Col xl={1} md={1} > </Col>
        </Row>
      </Router>
    </Container>
    );
  }
}

export default App;
