import React, { Component } from "react";
import { AMPLITUDE_EVENT_TYPES, sendAmplitudeData } from "../../common/amplitude";
import { PAGE_NAMES } from "../../common/constants";
import { Input, Row, Col, Button, Badge, Label } from "reactstrap";
import { createVportfolio, submitPortfolioTransaction, getUserPortfolios, fetchRealtimePrices, fetchRealtimeData } from "../../common/dataFetch";
import { formatNumber } from "../../common/numberUtil";
import { IMG_CONTAINER, IMAGE_URLS } from "../../common/url";
import { getStockNames } from "../../common/storageUtil";
import TickerAutoComplete from "../../components/tickerAutoComplete/tickerAutoComplete";
import CreatePortfolio from "./createPortfolio";
import { withRouter } from '../../common/router';

class VPortfolio extends Component {

    constructor(props, context) {
        super(props, context);
        sendAmplitudeData(AMPLITUDE_EVENT_TYPES.PAGE_VIEW, { 'name': PAGE_NAMES.VPORTFOLIO });
        this.state = {
            stockNames: {},
            ticker: '',
            quantity: 1,
            portfolios: null,
            active_portfolio_id: '',
            displayCreatePortfolioWindow: false,
            latest_price: {}
        }
    }

    componentDidMount = async () => {
        let stateObjToSet = await this.getPortfolios();
        let stockNames = await getStockNames();
        stateObjToSet['stockNames'] = stockNames;
        this.setState(stateObjToSet);
    }

    getPortfolios = async () => {
        let res = await getUserPortfolios();
        res = res.data;
        var i;
        var portfolio;
        let tickers = [];
        let first_portfolio_id = null;
        for (var j = 0; j < res.length; j++) {
            if (j === 0) {
                first_portfolio_id = res[j].id;
            }
            portfolio = res[j]['active stocks'];
            //portfolio.map(x => tickers.push(x.ticker));

            for (i = 0; i < portfolio.length; i++) {
                //alert(JSON.stringify(portfolio[i]['ticker']));
                tickers.push(portfolio[i]['ticker']);
            }
        }
        //alert(tickers);

        //var str1 = obj.slice(0, -1);
        //alert(str1);
        //alert(JSON.stringify(res));
        let realtime_price = await fetchRealtimePrices(tickers);
        //alert(JSON.stringify(realtime_price));
        let stateObjToSet = {
            'portfolios': res,
            'latest_price': realtime_price.tickers
        };
        if (this.state.active_portfolio_id === '') {
            stateObjToSet['active_portfolio_id'] = first_portfolio_id;
        }
        return stateObjToSet;
    }

    createPortfolio = async (portfolioName, portfolioAmount) => {
        let data = {
            name: portfolioName,
            amount: portfolioAmount,

        }
        let res = await createVportfolio(data);
        //alert(JSON.stringify(res));
        this.setState({ 'portfolios': res.data['portfolios'] });
        this.setState({ 'displayCreatePortfolioWindow': false });
    }

    submitTransaction = async (transaction_type) => {
        let ticker = this.state.ticker;
        let data = {
            ticker: ticker,
            quantity: parseInt(this.state.quantity),
            'transaction type': transaction_type
        }
        let res = await submitPortfolioTransaction(this.state.active_portfolio_id, data);
        let tickers = Object.keys(this.state.latest_price);
        if (!tickers.includes(ticker))
            tickers.push(ticker);
        let realtime_price = await fetchRealtimePrices(tickers);
        this.setState({
            'portfolios': res.data.portfolios,
            'latest_price': realtime_price.tickers,
            'ticker': '',
            'price': '',
        });
    }

    tickerSubmit = async (symbol) => {
        let ticker = symbol.toUpperCase();
        let data = await fetchRealtimeData(ticker);
        let price = data['Latest Price'];
        this.setState({ ticker: ticker, price: price });
    }

    checkQuantity = () => {

        if (this.state.portfolios) {

            for (var j = 0; j < this.state.portfolios.length; j++) {
                if (this.state.portfolios[j].id === this.state.active_portfolio_id) {
                    let portfolio = this.state.portfolios[j]['active stocks'];
                    for (var i = 0; i < portfolio.length; i++) {
                        if (portfolio[i]['ticker'] === this.state.ticker) {
                            if (portfolio[i]['net_quantity'] < this.state.quantity) {
                                return true;
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    checkBalance = () => {
        if (this.state.portfolios) {

            for (var j = 0; j < this.state.portfolios.length; j++) {
                if (this.state.portfolios[j].id === this.state.active_portfolio_id) {
                    //alert(JSON.stringify(this.state.portfolios[j]))
                    var amount = this.state.portfolios[j]['amount'];
                }
            }
            if (amount < this.state.quantity * this.state.price) {
                return true;
            }

        }
        return false;
    }

    closeCreatePortfolioWindow = async () => {
        this.setState({ displayCreatePortfolioWindow: false })
    }


    render = () => {
        let portfolios = this.state.portfolios;
        let portfolioNameDisplay = "";
        let activeStocksDisplay = "";
        let headerRows = ['Logo', 'Ticker', 'Name', 'Current Price', 'Quantity', 'Cost', 'Current Value', 'Profit/Loss', 'Transact'];
        let latest_price = this.state.latest_price;

        let get_portfolio_row = (tickerDetails) => {
            let ticker = tickerDetails['ticker'];
            let logo = <img src={IMG_CONTAINER + ticker + '.jpg'} style={{ maxHeight: '20px', maxWidth: '100%', cursor: 'pointer' }}
                alt={ticker}
                onClick={() => this.props.history.push('/home/' + ticker)} />;
            let tickerRender = <a href={window.location.protocol + '/home/' + ticker} target="_blank" rel="noopener noreferrer">
                <strong>{ticker}</strong>
            </a>;
            let name = this.state.stockNames[ticker];
            let price = latest_price[ticker]['realtime']['Latest Price'];
            let quantity = tickerDetails['net_quantity'];
            let cost = tickerDetails['purchase price'];
            let currentVal = price * quantity;
            let profitLoss = currentVal - cost;
            let transactCol = <Row><Col><Badge>Buy More</Badge></Col><Col><Badge>Sell</Badge></Col></Row>
            let renderItems = [logo, tickerRender, name, price, quantity, cost, currentVal, profitLoss, transactCol];
            let formattedItems = [logo, tickerRender, name, formatNumber(price), quantity,
                formatNumber(cost), formatNumber(currentVal), formatNumber(profitLoss), transactCol];
            //alert(renderItems);
            return <Row>
                {formattedItems.map((x, i) => (
                    <Col className={(!isNaN(renderItems[i]) ?
                        renderItems[i] < 0 ? "text-danger" : "text-success"
                        : "text-primary") + " border text-truncate"}>
                        {x}</Col>
                ))}
            </Row>;
        };

        let createPortfolioWindow = this.state.displayCreatePortfolioWindow ?
                <CreatePortfolio
                    createPortfolio={this.createPortfolio}
                    closeCreatePortfolioWindow={this.closeCreatePortfolioWindow}
                /> : '';

        if ((portfolios || null) && Object.keys(portfolios).length > 0) {
            portfolioNameDisplay = <Row>
                {portfolios.map((portfolio, i) => (
                    <Col className="text-center text-primary">
                        <Badge color={this.state.active_portfolio_id === portfolio.id ? "primary" : ''}
                            onClick={(e) => this.setState({ active_portfolio_id: portfolio.id })} style={{ "cursor": "pointer" }}>
                            <Row className="px-1 mx-1">
                                <Col>{portfolio['name']}</Col>
                                <Col> {formatNumber(portfolio['amount'])}</Col>
                            </Row>
                        </Badge>
                    </Col>))}
                <Col className="text-center text-primary">
                    <Col size="auto" className="pl-1 pr-0">
                        <object type="image/jpg" onClick={() => this.setState({ displayCreatePortfolioWindow: true })} className="float-right"
                            data={IMG_CONTAINER + IMAGE_URLS.ADD} style={{ maxHeight: '20px', maxWidth: '20px', cursor: 'pointer' }} aria-label="Add" />
                    </Col>
                </Col>

            </Row>;
            //let quantity =0;
            activeStocksDisplay = <Row className="my-3">
                <Col>
                    <Row>
                        {headerRows.map(headerVal => (
                            <Col className="text-success border"><h6>{headerVal}</h6></Col>
                        ))}
                    </Row>
                    {
                        portfolios.map((portfolio, i) => (
                            this.state.active_portfolio_id === portfolio.id ?
                                portfolio['active stocks'].map((tickerDetails, i) => (
                                    get_portfolio_row(tickerDetails)
                                ))
                                : ''))
                    }
                </Col>
            </Row>;

        }
        else {
            return <Row className="my-3">
                { createPortfolioWindow }
                <Col className="my-3 text-center">
                    <Button className="bg-primary" onClick={() => this.setState({ displayCreatePortfolioWindow: true })} style={{ cursor: 'pointer' }}>
                        You do not have any portfolios. Click here to create a portfolio.</Button>
                </Col>
            </Row>;
        }
        return <>{portfolioNameDisplay} {activeStocksDisplay}
            <Row>
                <Col>
                    <Row className="border"><Col>
                        <Row className="mt-3">
                            <Col xl="6" className="mx-auto">
                                <TickerAutoComplete onSubmit={this.tickerSubmit} />
                            </Col></Row>
                        {
                            isNaN(this.state.price) || this.state.ticker === '' ? '' :
                                <Row><Col xl="2" className="mx-auto"><Row>
                                    <Col><Label>{this.state.stockNames[this.state.ticker]}</Label></Col>
                                    <Col><Label>{formatNumber(this.state.price)}</Label></Col>
                                </Row></Col></Row>
                        }
                        <Row className="mt-3">
                            <Col xl="auto" className="mx-auto">
                                <Input type='number' size="sm" placeholder='Quantity' value={this.state.quantity}
                                    onChange={(e) => { this.setState({ quantity: e.target.value }) }} />
                            </Col>
                        </Row>
                        {
                            isNaN(this.state.price) || isNaN(this.state.quantity) ? '' :
                                <Row className="mt-3">
                                    <Col xl="4" className="mx-auto">
                                        <Row>
                                            <Col><Label>Amount Needed: </Label></Col>
                                            <Col><Label>{formatNumber(this.state.price * this.state.quantity)}</Label></Col>
                                        </Row>
                                    </Col>
                                </Row>
                        }
                        { createPortfolioWindow }
                        <Row className="mt-3">
                            <Col xl="auto" className="mx-auto">
                                <Button color="primary" onClick={(e) => this.submitTransaction('buy')} disabled={this.checkBalance()}>Buy</Button>
                            </Col>
                            <Col xl="auto" className="mx-auto">
                                <Button color="primary" onClick={(e) => this.submitTransaction('sell')} disabled={this.checkQuantity()} >Sell</Button>
                            </Col>
                        </Row>
                    </Col>
                    </Row>
                </Col>
            </Row >
        </>;
    }
}

export default withRouter(VPortfolio);