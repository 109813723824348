import React, { Component } from "react";
import { sendAmplitudeData, AMPLITUDE_EVENT_TYPES } from "../../common/amplitude";
import { PAGE_NAMES, INDEX_GROUPS, INDEX_BADGE_COLORS } from "../../common/constants";
import { Row, Col, Card, Badge, Spinner } from "reactstrap";
import { getStockNames, getEarningsData, getIndexTickers } from "../../common/storageUtil";
import { addDaystoDate } from "../../common/timeUtil";
import { IMG_CONTAINER, REALTIME_URL, PRE_POST_REALTIME_PRICE_URL, MARKET_STATUS_URL } from "../../common/url";
import { EARNINGS_TIME_LOOKUP, WEEK_DAYS_SHORT, WEEK_DAYS } from "./constants";
import { isMobile } from "react-device-detect";
import { number_full_to_short_form } from "../../common/numberUtil";
import { Helmet } from "react-helmet";
import { withRouter } from '../../common/router';
import { withNavigateHook } from '../../common/navigate';
import { executeRestRequest } from "../../common/restService";

class Earnings extends Component {

    constructor(props, context) {
        super(props, context);
        sendAmplitudeData(AMPLITUDE_EVENT_TYPES.PAGE_VIEW, { 'name': PAGE_NAMES.EARNINGS });
        this.state = {
            show_spinner: false,
            data: {},
            indexTickers: {},
            stockNames: {},
            dayFilters: [],
            dayIndex: 0
        }
    }

    componentDidMount = async () => {
        this.setState({ show_spinner: true });
        let res = await getEarningsData();
        if (res.data || null) {
            let stockNames = await getStockNames();
            //const data = Object.fromEntries(Object.entries(res.data).filter(([k, v]) => stockNames[k]));
            const indexTickers = await getIndexTickers();
            let objToSet = { data: res.data, stockNames: stockNames, indexTickers: indexTickers.data };
            let dayFilters = this.getFiveDaysArr();
            objToSet.dayFilters = dayFilters
            this.setState(objToSet);
        }
        this.setState({ show_spinner: false });
        await this.fetchTickerPrices(0, true);

        // Execute only when market is closed
        let marketStatusResponse = await executeRestRequest({ url: MARKET_STATUS_URL, method: 'GET' });
        let marketStatusData = marketStatusResponse.data;
        if ((marketStatusData || null) && !marketStatusData['is open']) {
            await this.fetchTickerPrices(0);
        }
    }

    updateDay = async (dayIndex) => {
        await this.fetchTickerPrices(dayIndex, true);
        this.fetchTickerPrices(dayIndex);
    }

    fetchTickerPrices = async (dayIndex, initialRequest = false) => {
        if (dayIndex < 0) {
            dayIndex = this.state.dayIndex;
        }
        let dayFilters = this.state.dayFilters;
        let dt = Object.values(dayFilters[dayIndex])[0];
        let data = this.state.data;
        const currentData = data.dates[dt];

        let url = REALTIME_URL;
        let marketStatusResponse = await executeRestRequest({ url: MARKET_STATUS_URL, method: 'GET' });
        let marketStatusData = marketStatusResponse.data;
        if (!initialRequest && (marketStatusData || null) && !marketStatusData['is open']) {
            url = PRE_POST_REALTIME_PRICE_URL;
        }
        const tickers = currentData.map(x => x.symbol);
        let batch_size = 10;
        for (let k = 0; k < tickers.length; k += batch_size) {
            let batch_tickers = tickers.slice(k, k + batch_size);
            let requestData = {
                url: url + batch_tickers.join(","),
                method: 'GET'
            };

            let prices = await executeRestRequest(requestData);
            let maxSize = Math.min(k + batch_size, tickers.length);
            for (let i = k; i < maxSize; i++) {
                let ticker = currentData[i]['symbol'];
                let ticker_data = prices.data.tickers[ticker];
                if ('pre-post-market' in ticker_data)
                    currentData[i]['pre-post-market'] = prices.data.tickers[ticker]['pre-post-market'];
                else
                    currentData[i]['pre-post-market'] = 'mkt-open'
                currentData[i]['realtime'] = prices.data.tickers[ticker]['realtime'];
            }
            this.setState({ data: data, dayIndex: dayIndex });
        }
    }

    getFiveDaysArr = () => {
        let returnArr = [];
        let dayNames = isMobile ? WEEK_DAYS_SHORT : WEEK_DAYS;
        let today = new Date();
        let day = new Date().getDay(); // 0 is Sun
        if (![0, 6].includes(day)) {
            dayNames = ['Today', ...dayNames.slice(day), ...dayNames.slice(0, day - 1)];
        }
        let daysToAdd = day === 0 ? 1 : day === 6 ? 2 : 0;
        let firstDate = daysToAdd > 0 ? addDaystoDate(today, 1) : today;
        let datesArr = [firstDate];
        for (let i = 0; i < 4; i++) {
            let nextDate = addDaystoDate(datesArr[i], 1);
            while ([0, 6].includes(nextDate.getDay())) {
                nextDate = addDaystoDate(nextDate, 1);
            }
            datesArr[i + 1] = nextDate;
        }
        for (let i = 0; i < dayNames.length; i++) {
            let x = {};
            let dt = datesArr[i];
            x[dayNames[i]] = dt.getFullYear() + '-' + (dt.getMonth() + 1).toString().padStart(2, '0') + '-' + dt.getDate().toString().padStart(2, '0');
            returnArr.push(x);
        }
        return returnArr;
    }

    getIndexBadges = (tickers) => {
        let indexData = this.state.indexTickers;
        let res = [];
        for (let j = 0; j < tickers.length; j++) {
            let badge = '';
            for (let i = 0; i < INDEX_GROUPS.length; i++) {
                if (indexData[INDEX_GROUPS[i]].includes(tickers[j])) {
                    badge = <Badge color={INDEX_BADGE_COLORS[i]}>{INDEX_GROUPS[i]}</Badge>;
                    break;
                }
            }
            res.push(badge);
        }
        return res;
    };

    getWatchListBadges(tickers) {
        let watchLists = this.props.watchLists;
        let res = [];
        if ((tickers || null) && (watchLists || null)) {
            for (let j = 0; j < tickers.length; j++) {
                let badge = '';
                for (let i = 0; i < watchLists.length; i++) {
                    if (watchLists[i].tickers.includes(tickers[j])) {
                        badge = <Badge color="info" pill>{watchLists[i].name}</Badge>;
                        break;
                    }
                }
                res.push(badge);
            }
        }
        return res;
    };

    get_ticker_price(ticker_data) {
        if (typeof ticker_data === 'undefined') {
            return <Col xl={2} xs={2}></Col>
        }

        let price = '';
        let change_value = 0;
        let changePercentText = '(0%)';
        let changeText = '';

        if ('realtime' in ticker_data) {
            price = ticker_data['realtime']['Latest Price'];;
            change_value = ticker_data['realtime']['Latest Change'];
        }

        if (!isNaN(price)) {
            changeText = change_value.toFixed(2);

            let change_percent = change_value / (price - change_value) * 100.0;
            change_percent = change_percent.toFixed(1);
            if (change_value !== 0)
                changePercentText = "(" + change_percent + "%)";
        }

        let className = change_value < 0 ? "px-2 text-danger" : "px-2 text-success";
        changeText = change_value > 0 ? "+" + changeText : changeText;

        let res = <Row>
            <Col xs="auto" className="px-0">{price}</Col>
            <Col className={className} xs="auto">{changeText}<small>{changePercentText}</small></Col>
        </Row>;

        return <Col xl={2} xs={2}>{res}</Col>;
    }

    get_ticker_pre_post_price(ticker_data) {
        if (typeof ticker_data === 'undefined' || ticker_data === null ||
            !('pre-post-market' in ticker_data) || ticker_data['pre-post-market'] === null) {
            return <Col xl={2} xs={2}></Col>
        }

        let realtime_price = 0;
        let price = '';
        let changePercentText = '(0%)';
        let change_value = 0;
        let changeText = '';

        if ('realtime' in ticker_data) {
            realtime_price = ticker_data['realtime']['Latest Price'];
            price = realtime_price;
            change_value = ticker_data['realtime']['Latest Change'];
        }



        if ('pre-post-market' in ticker_data) {
            if (ticker_data['pre-post-market'] === 'mkt-open') {
                return <Col xl={2} xs={2}><Spinner color="secondary" type="grow" size="sm" /></Col>;
            }
            price = ticker_data['pre-post-market']['price'];
            change_value = price - realtime_price;
            changeText = change_value.toFixed(2);
        } else {
            return <Col xl={2} xs={2}><Spinner color="secondary" type="grow" size="sm" /></Col>;
        }

        if (!isNaN(price)) {
            let change_percent = change_value / (price - change_value) * 100.0;
            if (change_percent < -70 || change_percent > 70) {
                return <Col xl={2} xs={2}></Col>;
            }
            change_percent = change_percent.toFixed(1);
            if (change_value !== 0)
                changePercentText = "(" + change_percent + "%)";
        }

        let className = change_value < 0 ? "px-2 text-danger" : "px-2 text-success";
        changeText = change_value > 0 ? "+" + changeText : changeText;

        let res = <Row>
            <Col xs="auto" className="px-0">{price}</Col>
            <Col className={className} xs="auto">{changeText}<small>{changePercentText}</small></Col>
        </Row>;

        return <Col xl={2} xs={2}>{res}</Col>;
    }

    render = () => {
        if (this.state.show_spinner)
            return <Row>
                <Col className="mx-1" xl={12} md={12} xs={12} align="center">
                    <div height="500px" align="center">
                        <Spinner color="primary" animation="border" role="status" />
                    </div>
                </Col>
            </Row>;


        let data = this.state.data;
        if (Object.keys(data).length < 1)
            return '';
        let stockNames = this.state.stockNames;
        let dayFilters = this.state.dayFilters;
        let dayIndex = this.state.dayIndex;
        let dt = Object.values(dayFilters[dayIndex])[0];
        const renderData = data.dates[dt];
        renderData.sort(function (a, b) {
            return b.mktCap - a.mktCap;
        });
        const tickers = renderData.map(x => x.symbol);

        let getEarningsTimeBadge = val => {
            let badgeColor = val === 'bmo' ? 'primary' : val === 'amc' ? 'info' : 'secondary';
            return <Badge color={badgeColor}>{EARNINGS_TIME_LOOKUP[val]}</Badge>;
        }
        let indexBadges = this.getIndexBadges(tickers);
        let watchListBadges = this.getWatchListBadges(tickers);
        const metaDesc = "Information on earnings of listed companies scheduled over next 5 business days including timings and estimated EPS.";
        return <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Week's earnings calendar.</title>
                <meta name="description" content={metaDesc} />
            </Helmet>
            <Row className="mx-1 my-2"><Col className="bg-primary text-white text-center rounded"><h5>Earnings Calendar</h5></Col></Row>
            <Card className="px-3 py-2">
                <Row className="my-0"><Col xl="auto" xs="auto" md="auto" sm="auto" lg="auto" className="mx-auto">
                    {dayFilters.map((obj, i) => (
                        <><Badge style={{ cursor: 'pointer' }} onClick={() => this.updateDay(i)}
                            color={this.state.dayIndex === i ? "success" : "secondary"} pill>{Object.keys(obj)[0]}</Badge><span> </span></>
                    ))}
                </Col></Row>
            </Card>
            <Card className="px-3 py-2">
                {
                    isMobile ? '' :
                        <h6><Row className="text-success mb-2 px-0 font-weight-bold">
                            <Col xl={1} xs={2} className="pr-0">Logo</Col>
                            <Col xl={1} xs={3}>Ticker</Col>
                            <Col xl={2} xs={3}>Price</Col>
                            <Col xl={2} xs={3}>After Hours</Col>
                            <Col xl={1} xs={3}>Time</Col>
                            <Col xl={1} xs={3} className="px-0">EPS Est{isMobile ? '.' : 'd.'}</Col>
                            <Col xl={1} xs={3}>Watchlist</Col>
                            <Col xl={1} className="px-0" xs={3}>MktCap</Col>
                            <Col xl={2} xs={12}>Name</Col>
                        </Row></h6>
                }
                {
                    isMobile ? '' :
                        renderData.map((ticker_info, i) => (
                            <><Row className="text-primary mb-2 mx-1 px-0">

                                <Col xl={1} xs={2} className="px-0 py-0">
                                    <img src={IMG_CONTAINER + ticker_info.symbol + '.jpg'} style={{ maxHeight: '20px', maxWidth: '100%', cursor: 'pointer' }}
                                        alt={ticker_info.symbol}
                                        onClick={() => this.props.navigation('/home/' + ticker_info.symbol)} />
                                </Col>
                                <Col xl={1} xs={2} className="px-0">
                                    <a href={window.location.protocol + '/home/' + ticker_info.symbol} target="_blank" rel="noopener noreferrer">
                                        <strong>{ticker_info.symbol}</strong>
                                    </a>
                                </Col>
                                {this.get_ticker_price(ticker_info)}
                                {this.get_ticker_pre_post_price(ticker_info)}
                                <Col xl={1} className="px-0" xs={2}>{getEarningsTimeBadge(ticker_info.time)}</Col>
                                <Col xl={1} className="px-0" xs={2}>{ticker_info.epsEstimated}</Col>
                                <Col xl={1} className="px-0" xs={2}>{watchListBadges[i]}</Col>
                                <Col xl={1} className="pl-2" xs={2}>
                                    {ticker_info.mktCap === 0 ? '' : number_full_to_short_form(ticker_info.mktCap)}{indexBadges[i]}
                                </Col>
                                <Col xl={2} xs={12} className="text-truncate text-secondary">
                                    {stockNames[ticker_info.symbol]}
                                </Col>
                            </Row>
                                <hr className="my-0" /></>
                        ))
                }
                {
                    isMobile ?
                        renderData.map((ticker_info, i) => (
                            <>
                                <Row className="text-primary mb-2 mx-0 px-0">
                                    <Col xs={6} className="px-0 py-0 mb-1">
                                        <img src={IMG_CONTAINER + ticker_info.symbol + '.jpg'} style={{ maxHeight: '20px', maxWidth: '100%', cursor: 'pointer' }}
                                            alt={ticker_info.symbol}
                                            onClick={() => this.props.navigation('/home/' + ticker_info.symbol)} />
                                    </Col>
                                    <Col xs={6} className="px-0">
                                        <a href={window.location.protocol + '/home/' + ticker_info.symbol} target="_blank" rel="noopener noreferrer">
                                            <strong>{ticker_info.symbol}</strong>
                                        </a>
                                    </Col>
                                    <Col xs={12} className="text-truncate text-secondary px-0">
                                        <small>{stockNames[ticker_info.symbol]}</small>
                                    </Col>
                                    <Col xs={3} className="px-0 text-success">EPS Estd.:</Col>
                                    <Col className="px-0" xs={3}>{ticker_info.epsEstimated}</Col>
                                    <Col xs={3} className="px-0 text-success">MktCap:</Col>
                                    <Col xs={3} className="px-0">
                                        {ticker_info.mktCap === 0 ? '' : number_full_to_short_form(ticker_info.mktCap)}
                                    </Col>
                                    <Col xs={4} className="px-0">{getEarningsTimeBadge(ticker_info.time)}</Col>
                                    <Col xs={4}>{indexBadges[i]}</Col>
                                    <Col xs={4}>{watchListBadges[i]}</Col>
                                </Row>
                                <hr className="my-0" />
                            </>
                        ))
                        : ''
                }
            </Card>
        </>;
    }
}

export default withNavigateHook(withRouter(Earnings));