import React, { Component } from 'react';
import { Row, Col, Badge } from 'reactstrap';
import { formatNumber } from '../../common/numberUtil';
import { IMG_CONTAINER } from '../../common/url';
import { isMobile } from "react-device-detect";

export default class PriceDisplay extends Component {

    render = () => {
        let data = this.props.tickerPriceObj;
        if (!(data || null)) {
            return '';
        }
        let price = data['Latest Price'];
        let showPrePost = false;
        let PrePostPriceText = '';
        let PrePostChangePercentText = '(0%)';
        let PrePostChangeText = '0';
        let PrePostChangeClass = "py-1 my-auto h-100% w-100% ";
        if ('PrePostPrice' in data) {

            let PrePostPrice = data['PrePostPrice'];
            if ((PrePostPrice || null) && !isNaN(PrePostPrice)) {
                PrePostPriceText = formatNumber(PrePostPrice);
            }

            if (!isNaN(price) && !isNaN(PrePostPrice)) {
                let PrePostChangeValue = PrePostPrice - price;
                if (!isNaN(PrePostChangeValue)) {
                    PrePostChangeValue = Number(PrePostChangeValue);
                    PrePostChangeText = PrePostChangeValue.toFixed(2);
                    if (PrePostChangeValue > 0) {
                        PrePostChangeText = "+" + PrePostChangeText;
                        PrePostChangeClass += "bg-success text-white";
                    } else if (PrePostChangeValue < 0) {
                        PrePostChangeClass += "bg-danger text-white";
                    }
                    let PrePostChangePercent = PrePostChangeValue / price * 100.0;
                    PrePostChangePercent = PrePostChangePercent.toFixed(1);
                    if (PrePostChangeValue !== 0)
                        PrePostChangePercentText = "(" + PrePostChangePercent + "%)";
                    if (PrePostChangePercent > 0.5 || PrePostChangePercent < -0.5)
                        showPrePost = true;
                }
            }
        }

        let priceText = (price || null) ? price : '';
        if ((price || null) && !isNaN(price)) {
            priceText = formatNumber(price);
        }
        let changeText = '0';
        let changePercentText = '0%';
        let change_value = data['Latest Change'];
        let changeClass = "py-1 my-auto h-100% w-100% "
        if (change_value || null) {
            changeText = change_value;
            if (!isNaN(change_value)) {
                change_value = Number(change_value);
                changeText = change_value.toFixed(2);
                if (change_value > 0) {
                    changeText = "+" + changeText;
                    changeClass += "bg-success text-white";
                } else if (change_value < 0) {
                    changeClass += "bg-danger text-white";
                }
                if (!isNaN(price)) {
                    let change_percent = change_value / (price - change_value) * 100.0;
                    change_percent = change_percent.toFixed(1);
                    changePercentText = "(" + change_percent + "%)";
                }
            }
        };

        let imgSource = IMG_CONTAINER + data.symbol + '.jpg';

        let img_col_size = 2;
        let symbol_col_size = 3;
        let price_col_size = 3;
        let price_change_col_size = 4;
        let prePostRender = '';
        if (showPrePost) {
            //img_col_size = 2;
            //symbol_col_size = 2;
            //price_col_size = 2;
            //price_change_col_size = 2;

            prePostRender = <Row className='py-1'>
                <Col xl={6}></Col>
                <Col xl={6} className='px-0'>
                    <Row className='px-0'>
                        <Col xs="auto" className="text-end text-secondary">
                            <small>After Hours</small>
                        </Col>
                        <Col xs="auto" className="text-start my-auto text-secondary px-1">
                            <h6>{PrePostPriceText}</h6>
                        </Col>
                        <Col xs="auto" className='px-0 ms-0 ms-auto me-auto'>
                            <Row className={PrePostChangeClass + ' rounded'} >
                                <Col className="text-center my-auto">
                                    <h6>{PrePostChangeText}<span> </span><small>{PrePostChangePercentText}</small></h6>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        }

        let imgRender = <Col xl={img_col_size} md={2} xs={6} className="my-auto mx-auto">
            <Row><Col xl="auto" xs="auto" md="auto" sm="auto" lg="auto" className="mx-auto">
                <object type="image/jpg" data={imgSource} style={{ maxHeight: '30px', maxWidth: '60px' }} aria-label="Image" />
            </Col></Row>
        </Col>;

        let symbolRender = <Col xl={symbol_col_size} md={3} xs={6} className="text-center text-primary my-auto">
            <h4>{data.symbol}</h4>
        </Col>;

        let priceRender = <Col xl={price_col_size} md={3} xs={6} className="my-auto text-center    ">
            <h4>{priceText}</h4>
        </Col>;


        let priceChangeRender = <Col xl={price_change_col_size} md={4} xs={6} >
            <Row className={changeClass + ' rounded'} >
                <Col className="text-center my-auto">
                    <h5>{changeText}<span> </span><small>{changePercentText}</small></h5>
                </Col>
            </Row>
        </Col>;

        if (this.props.delisted)
            priceChangeRender = <Col xl={4} md={4} xs={6} className="my-auto"><Badge color="danger" pill>Delisted</Badge></Col>;

        let mobileRender = <Row className="border rounded mb-1">{imgRender}{priceRender}{symbolRender}{priceChangeRender}</Row>;


        let desktopRender = <Row className="border rounded mb-1 mx-0">
            <Col><Row>{imgRender}{symbolRender}{priceRender}{priceChangeRender}</Row>
                {prePostRender}
            </Col>
        </Row>;

        return <>{isMobile ? mobileRender : desktopRender}</>;
    }
}