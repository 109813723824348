import React, { Component } from 'react';
import StockScreen from '../../components/stockscreener/stockScreener';
import { AMPLITUDE_EVENT_TYPES, sendAmplitudeData } from '../../common/amplitude';
import { PAGE_NAMES } from '../../common/constants';
import { setWindowTitle } from "../../common/apiUtil";
import { INVEST_LUCID_TITLE } from "../../common/constants";
import { Helmet } from "react-helmet";
import { withRouter } from '../../common/router';

class Research extends Component {

    constructor(props, context) {
        super(props, context);
        sendAmplitudeData(AMPLITUDE_EVENT_TYPES.PAGE_VIEW, { 'name': PAGE_NAMES.RESEARCH });
    }

    componentDidMount = async () => {
        setWindowTitle(INVEST_LUCID_TITLE);
    }

    componentDidUpdate = async () => {
        setWindowTitle(INVEST_LUCID_TITLE);
    }

    render = () => {
        const metaDesc = "Research stocks and filter based on various criteria " +
            "like returns, market capitalization, beta, revenue, net income, price to " +
            "earnings (p/e), price to sales (p/s), index stocks, sector and industry.";
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Research and filter stocks on various criteria.</title>
                    <meta name="description" content={metaDesc} />
                </Helmet>
                <StockScreen refreshWatchList={this.refreshWatchList}
                    {...this.props} />
            </>
        );
    }
}

export default withRouter(Research);