import { SERVER, LOCAL_SERVER } from "./url";

export const FULL_LIST_KEY = "stock_full_list";
export const EARNINGS_KEY = "earnings";
export const INDEX_TICKERS_KEY = "index-tickers";

export const GOOGLE_DEV_CLIENT_ID = "957314126866-o9q5s8bn55s5l3bi5m078op4ceogg5v1";
export const GOOGLE_PROD_CLIENT_ID = "957314126866-vvrp3kmfib7mhuoph6h9me8gqbr8k5su";
export const GOOGLE_CLIENT_ID = (SERVER === LOCAL_SERVER) ? GOOGLE_DEV_CLIENT_ID : GOOGLE_PROD_CLIENT_ID;

export const percent_fields = ['Revenue Growth', 'Gross Margin', 'EBITDA Margin', 'EBIT Margin', 'Profit Margin', 'Free Cash Flow margin',
    'Earnings Before Tax Margin', 'Net Profit Margin', 'Net Cash/Marketcap'];

export const CURRENCY_FORMAT_METRICS = ['net income ttm', 'revenue ttm', 'mktCap', 'begin price',
    'begin price 1 yr', 'end price', 'lastDiv', 'eps'];
export const NUMERIC_FORMAT_METRICS = ['volAvg', 'fullTimeEmployees'];
export const PERCENT_FORMAT_METRICS = ['return', 'return 1 yr'];
export const DECIMAL_FORMAT_METRICS = ['beta', 'p/e', 'p/s'];
export const GRAPH_TIME_PERIODS = ['1D', '1W', '4W', '8W', '6M', 'YTD', '1Y', '3Y', '5Y', 'MAX'];

export const MKT_CAP_RANGES = {
    'Small Cap': {
        low: 1,
        high: 2000000000,
        color: 'light'
    },

    'Mid Cap': {
        low: 2000000000,
        high: 10000000000,
        color: 'info'
    },

    'Large Cap': {
        low: 25000000000,
        high: 200000000000,
        color: 'primary'
    },
    'Mega Cap': {
        low: 200000000000,
        high: 9000000000000,
        color: 'success'
    }
};

export const INDUSTRIES = ['Asset Management', 'Banks', 'Biotechnology', 'Application Software', 'REITs', 'Computer Hardware', 'Industrial Products',
    'Retail - Apparel & Specialty', 'Oil & Gas - E&P', 'Semiconductors', 'Chemicals', 'Steel', 'Conglomerates', 'Metals & Mining', 
    'Transportation & Logistics', 'Consumer Packaged Goods', 'Utilities - Regulated', 'Communication Services', 'Insurance - Life',
    'Drug Manufacturers', 'Business Services', 'Autos', 'Travel & Leisure', 'Communication Equipment', 'Medical Devices', 
    'Medical Diagnostics & Research', 'Online Media', 'Oil & Gas - Services','Insurance - Property & Casualty', 'Retail - Defensive', 
    'Engineering & Construction', 'Oil & Gas - Midstream', 'Medical Instruments & Equipment', 'Restaurants', 'Entertainment',
    'Insurance - Specialty', 'Brokers & Exchanges', 'Employment Services', 'Manufacturing - Apparel & Furniture',  'Education',
    'Aerospace & Defense', 'Consulting & Outsourcing', 'Credit Services', 'Real Estate Services', 'Homebuilding & Construction',
    'Building Materials',  'Health Care Providers', 'Homebuilding & Construction', 'Oil & Gas - Refining & Marketing', 'Oil & Gas - Integrated', 'Airlines',
    'Waste Management',
    'Advertising & Marketing Services', 'Packaging & Containers', 'Industrial Distribution', 'Forest Products', 
    'Utilities - Independent Power Producers', 'Oil & Gas - Drilling', 'Coal', 'Beverages - Non-Alcoholic', 'Insurance', 'Publishing',
    'Agriculture', 'Beverages - Alcoholic', 'Farm & Construction Machinery', 'Health Care Plans', 'Personal Services', 'Tobacco Products',
    'Medical Distribution', 'Truck Manufacturing'];

export const SECTORS = ['Financial Services', 'Healthcare', 'Technology', 'Industrials', 'Consumer Cyclical', 'Energy', 'Basic Materials',
    'Real Estate', 'Consumer Defensive', 'Utilities', 'Communication Services'];

export const INDEX_GROUPS = ['dow 30', 'nasdaq 100', 's&p 500', 'russell 2000'];

export const INDEX_GROUP_URLS = ['dow', 'nasdaq', 'sp500', 'russell'];

export const INDEX_BADGE_COLORS = ['success', 'primary', 'info', 'light'];

export const MULTI_VALUE_METRIC_LIST = ['index_group', 'sector', 'industry'];

export const SEARCH_OBJECT_KEY = 'ticker_search_obj';

export const TWELVE_HOURS_IN_SECONDS = 12 * 60 * 60;

export const ONE_HOUR_IN_SECONDS = 60 * 60;

export const THREE_DAYS_IN_SECONDS = 3 * 24 * 60 * 60;

export const THIRTY_DAYS_IN_SECONDS = 30 * 24 * 60 * 60;

export const NAME_TO_NUMBER_FORMAT_METRICS = ['mktCap', 'revenue ttm', 'net income ttm'];

export const DISPLAY_NAME_MAP = {
    'lastDiv': 'Dividend TTM',
    'p/e': 'P/E',
    'p/s': 'P/S',
    'return': '1 Yr Return',
    'mktCap': 'Market Cap',
    'companyName': 'Name',
    'revenue ttm': 'Revenue TTM',
    'net income ttm': 'Net Income TTM',
    'beta': 'Beta',
    'exchange': 'Exchange',
    'volAvg': 'Average Volume',
    'sector': 'Sector',
    'industry': 'Industry',
    'ceo': 'CEO',
    'day range': 'Day\'s Range',
    'summary': 'Summary',
    'income-statement': 'Income',
    'balance-sheet-statement': 'Balance Sheet',
    'cash-flow-statement': 'Cash Flow',
    'earnings_date': 'Earnings Date',
    'eps': 'EPS',
    'fullTimeEmployees': 'Employees',
    'ipoDate': 'Listing Date'
};

export const TOOLTIP = {
    'mktCap': "Market capitalization refers to the total dollar market value of a company's outstanding shares of stock. It is calculated by multiplying the total number of a company's outstanding shares by the current market price of one share.",
    'p/e': "Price-to-earnings ratio (P/E ratio) is the ratio for valuing a company that measures its current share price relative to its per-share earnings (EPS). It is calculated by dividing current stock price by EPS.",
    'p/s': "Price-to-sales (P/S) ratio is a valuation ratio that compares a company’s stock price to its revenues. It is calculated by dividing the company’s market capitalization by its total sales over a designated period – usually twelve months.",
    'lastDiv': "Total of dividends distributed per share over last 12 months. Yield is calculated by dividing dividends by current stock price.",
    'revenue ttm': "Total revenue generated by company over last 12 months.",
    'net income ttm': "Total profits generated by company over last 12 months.",
    'beta': "Beta coefficient is a measure of the volatility, or systematic risk, of an individual stock in comparison to the unsystematic risk of the entire market. It describes the activity of a stock's returns responding to swings in the market. Beta of 1.0 indicates that its price activity is strongly correlated with the market. Higher beta indicates higher volatility and risk and lower beta indicates lower volatility."
};

export const PAGE_NAMES = {
    HOME: 'Home',
    WATCHLIST: 'Watchlist',
    RESEARCH: 'Research',
    INDEX: 'Index',
    COVID: 'Covid19',
    EARNINGS: 'Earnings',
    ALERTS: 'Alerts',
    VPORTFOLIO: 'Virtual Portfolio',
    MOVERS: 'movers',
    INSIGHTS: 'insights'
}

export const INVEST_LUCID_TITLE = "Invest Lucid";

export const API_KEY = 'AIzaSyDjAEXrqk5U1QBPy9rQhi6EUEm53H-l9gk';

export const COUNTRY_CODES = {
'AX': 'Aland Islands',
'AL': 'Albania',
'DZ': 'Algeria',
'AS': 'American Samoa',
'AD': 'Andorra',
'AO': 'Angola',
'AI': 'Anguilla',
'AQ': 'Antarctica',
'AG': 'Antigua and Barbuda',
'AR': 'Argentina',
'AM': 'Armenia',
'AW': 'Aruba',
'AU': 'Australia',
'AT': 'Austria',
'AZ': 'Azerbaijan',
'BS': 'Bahamas',
'BH': 'Bahrain',
'BD': 'Bangladesh',
'BB': 'Barbados',
'BY': 'Belarus',
'BE': 'Belgium',
'BZ': 'Belize',
'BJ': 'Benin',
'BM': 'Bermuda',
'BT': 'Bhutan',
'BO': 'Bolivia',
'BA': 'Bosnia and Herzegovina',
'BW': 'Botswana',
'BV': 'Bouvet Island',
'BR': 'Brazil',
'VG': 'British Virgin Islands',
'IO': 'British Indian Ocean Territory',
'BN': 'Brunei Darussalam',
'BG': 'Bulgaria',
'BF': 'Burkina Faso',
'BI': 'Burundi',
'KH': 'Cambodia',
'CM': 'Cameroon',
'CA': 'Canada',
'CV': 'Cape Verde',
'KY': 'Cayman Islands ',
'CF': 'Central African Republic',
'TD': 'Chad',
'CL': 'Chile',
'CN': 'China',
'HK': 'Hong Kong, SAR China',
'MO': 'Macao, SAR China',
'CX': 'Christmas Island',
'CC': 'Cocos (Keeling) Islands',
'CO': 'Colombia',
'KM': 'Comoros',
'CG': 'Congo (Brazzaville)',
'CD': 'Congo, (Kinshasa)',
'CK': 'Cook Islands ',
'CR': 'Costa Rica',
'CI': 'Côte dIvoire',
'HR': 'Croatia',
'CU': 'Cuba',
'CY': 'Cyprus',
'CZ': 'Czech Republic',
'DK': 'Denmark',
'DJ': 'Djibouti',
'DM': 'Dominica',
'DO': 'Dominican Republic',
'EC': 'Ecuador',
'EG': 'Egypt',
'SV': 'El Salvador',
'GQ': 'Equatorial Guinea',
'ER': 'Eritrea',
'EE': 'Estonia',
'ET': 'Ethiopia',
'FK': 'Falkland Islands (Malvinas) ',
'FO': 'Faroe Islands',
'FJ': 'Fiji',
'FI': 'Finland',
'FR': 'France',
'GF': 'French Guiana',
'PF': 'French Polynesia',
'TF': 'French Southern Territories',
'GA': 'Gabon',
'GM': 'Gambia',
'GE': 'Georgia',
'DE': 'Germany',
'GH': 'Ghana',
'GI': 'Gibraltar ',
'GR': 'Greece',
'GL': 'Greenland',
'GD': 'Grenada',
'GP': 'Guadeloupe',
'GU': 'Guam',
'GT': 'Guatemala',
'GG': 'Guernsey',
'GN': 'Guinea',
'GW': 'Guinea-Bissau',
'GY': 'Guyana',
'HT': 'Haiti',
'HM': 'Heard and Mcdonald Islands',
'VA': 'Holy See (Vatican City State)',
'HN': 'Honduras',
'HU': 'Hungary',
'IS': 'Iceland',
'IN': 'India',
'ID': 'Indonesia',
'IR': 'Iran, Islamic Republic of',
'IQ': 'Iraq',
'IE': 'Ireland',
'IM': 'Isle of Man ',
'IL': 'Israel',
'IT': 'Italy',
'JM': 'Jamaica',
'JP': 'Japan',
'JE': 'Jersey',
'JO': 'Jordan',
'KZ': 'Kazakhstan',
'KE': 'Kenya',
'KI': 'Kiribati',
'KP': 'Korea (North)',
'KR': 'Korea (South)',
'KW': 'Kuwait',
'KG': 'Kyrgyzstan',
'LA': 'Lao PDR',
'LV': 'Latvia',
'LB': 'Lebanon',
'LS': 'Lesotho',
'LR': 'Liberia',
'LY': 'Libya',
'LI': 'Liechtenstein',
'LT': 'Lithuania',
'LU': 'Luxembourg',
'MK': 'Macedonia, Republic of',
'MG': 'Madagascar',
'MW': 'Malawi',
'MY': 'Malaysia',
'MV': 'Maldives',
'ML': 'Mali',
'MT': 'Malta',
'MH': 'Marshall Islands',
'MQ': 'Martinique',
'MR': 'Mauritania',
'MU': 'Mauritius',
'YT': 'Mayotte',
'MX': 'Mexico',
'FM': 'Micronesia, Federated States of',
'MD': 'Moldova',
'MC': 'Monaco',
'MN': 'Mongolia',
'ME': 'Montenegro',
'MS': 'Montserrat',
'MA': 'Morocco',
'MZ': 'Mozambique',
'MM': 'Myanmar',
'NA': 'Namibia',
'NR': 'Nauru',
'NP': 'Nepal',
'NL': 'Netherlands',
'AN': 'Netherlands Antilles',
'NC': 'New Caledonia',
'NZ': 'New Zealand',
'NI': 'Nicaragua',
'NE': 'Niger',
'NG': 'Nigeria',
'NU': 'Niue ',
'NF': 'Norfolk Island',
'MP': 'Northern Mariana Islands',
'NO': 'Norway',
'OM': 'Oman',
'PK': 'Pakistan',
'PW': 'Palau',
'PS': 'Palestinian Territory',
'PA': 'Panama',
'PG': 'Papua New Guinea',
'PY': 'Paraguay',
'PE': 'Peru',
'PH': 'Philippines',
'PN': 'Pitcairn',
'PL': 'Poland',
'PT': 'Portugal',
'PR': 'Puerto Rico',
'QA': 'Qatar',
'RE': 'Réunion',
'RO': 'Romania',
'RU': 'Russian Federation',
'RW': 'Rwanda',
'BL': 'Saint-Barthélemy',
'SH': 'Saint Helena',
'KN': 'Saint Kitts and Nevis',
'LC': 'Saint Lucia',
'MF': 'Saint-Martin (French part)',
'PM': 'Saint Pierre and Miquelon ',
'VC': 'Saint Vincent and Grenadines',
'WS': 'Samoa',
'SM': 'San Marino',
'ST': 'Sao Tome and Principe',
'SA': 'Saudi Arabia',
'SN': 'Senegal',
'RS': 'Serbia',
'SC': 'Seychelles',
'SL': 'Sierra Leone',
'SG': 'Singapore',
'SK': 'Slovakia',
'SI': 'Slovenia',
'SB': 'Solomon Islands',
'SO': 'Somalia',
'ZA': 'South Africa',
'GS': 'South Georgia and the South Sandwich Islands',
'SS': 'South Sudan',
'ES': 'Spain',
'LK': 'Sri Lanka',
'SD': 'Sudan',
'SR': 'Suriname',
'SJ': 'Svalbard and Jan Mayen Islands ',
'SZ': 'Swaziland',
'SE': 'Sweden',
'CH': 'Switzerland',
'SY': 'Syrian Arab Republic (Syria)',
'TW': 'Taiwan, Republic of China',
'TJ': 'Tajikistan',
'TZ': 'Tanzania, United Republic of',
'TH': 'Thailand',
'TL': 'Timor-Leste',
'TG': 'Togo',
'TK': 'Tokelau ',
'TO': 'Tonga',
'TT': 'Trinidad and Tobago',
'TN': 'Tunisia',
'TR': 'Turkey',
'TM': 'Turkmenistan',
'TC': 'Turks and Caicos Islands ',
'TV': 'Tuvalu',
'UG': 'Uganda',
'UA': 'Ukraine',
'AE': 'United Arab Emirates',
'GB': 'United Kingdom',
'US': 'United States of America',
'UM': 'US Minor Outlying Islands',
'UY': 'Uruguay',
'UZ': 'Uzbekistan',
'VU': 'Vanuatu',
'VE': 'Venezuela (Bolivarian Republic)',
'VN': 'Viet Nam',
'VI': 'Virgin Islands, US',
'WF': 'Wallis and Futuna Islands ',
'EH': 'Western Sahara',
'YE': 'Yemen',
'ZM': 'Zambia',
'ZW': 'Zimbabwe'}