import React, { Component } from "react";
import { DropdownMenu, UncontrolledDropdown, DropdownToggle, DropdownItem, Navbar, Nav } from "reactstrap";

export default class LucidDropDownMenu extends Component {

    render = () => {
        let image_url = this.props.image_url;
        let height = this.props.height;
        let width = this.props.width;
        let menu_items = this.props.menu_items;
        let selected_menu_item_name = this.props.selected_menu_item_name;
        let position = this.props.position;
        if ( !(position || null) ){
            position = "right";
        }

        return <Navbar className="navigation__navbar navbar-dark bg-white float-right py-0 px-0" light expand="md">
            <Nav className="mr-auto" navbar>
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle className="text-primary py-0 px-0" nav>
                        <object type="image/jpg"
                            data={image_url} style={{ height: height, width: width, cursor: 'pointer' }} aria-label="Drop down menu" />
                    </DropdownToggle>
                    {position === 'right' ?
                        <DropdownMenu right >
                            {menu_items.map((menu_item, index) => (
                                <DropdownItem>
                                    <div onClick={e => this.props.onClickFunction(menu_item.parammeters)}
                                        className={selected_menu_item_name === menu_item.item_name ? 'text-success' : 'text-primary'} >
                                        {menu_item.item_name}
                                    </div>
                                </DropdownItem>
                            ))}
                        </DropdownMenu> :
                        <DropdownMenu left >
                            {menu_items.map((menu_item, index) => (
                                <DropdownItem>
                                    <div onClick={e => this.props.onClickFunction(menu_item.parammeters)}
                                        className={selected_menu_item_name === menu_item.item_name ? 'text-success' : 'text-primary'} >
                                        {menu_item.item_name}
                                    </div>
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    }
                </UncontrolledDropdown>
            </Nav>
        </Navbar>;

    }

}