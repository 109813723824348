
//import amplitude from 'amplitude-js/amplitude.js';
import * as amplitude from "@amplitude/analytics-browser";


export const initAmplitude = () => {
  amplitude.init('b48b62002823920a1b4db08a2d6b6f9d');
};

export const setAmplitudeUserDevice = installationToken => {
  amplitude.setDeviceId(installationToken);
};

export const setAmplitudeUserId = userId => {
  amplitude.setUserId(userId);
};

export const setAmplitudeUserProperties = properties => {
  const identify = new amplitude.Identify()
  identify.set(properties)
  amplitude.identify(identify);
};

export const sendAmplitudeData = (eventType, eventProperties) => {
  amplitude.logEvent(eventType, eventProperties);
};

export const logAmplitudeEvent = eventName => {
    amplitude.logEvent(eventName);
}

export const AMPLITUDE_EVENT_TYPES = {
    PAGE_VIEW: 'page view',
    API_CALL: 'api call',
    EVENT: 'event'
};




