import React, { Component } from "react";
import { AMPLITUDE_EVENT_TYPES, sendAmplitudeData } from "../../common/amplitude";
import { PAGE_NAMES } from "../../common/constants";
import { Row, Col, Input, Badge } from "reactstrap";
import TickerAutoComplete from "../../components/tickerAutoComplete/tickerAutoComplete";
import { fetchRealtimeData, addTickerAlert, deleteTickerAlert } from "../../common/dataFetch";
import { getStockNames } from "../../common/storageUtil";
import { REALTIME_URL, IMG_CONTAINER } from "../../common/url";
import { executeRestRequest } from "../../common/restService";
import { formatNumber, formatPercent } from '../../common/numberUtil';
import { withRouter } from '../../common/router';

class Alerts extends Component {

    constructor(props, context) {
        super(props, context);
        sendAmplitudeData(AMPLITUDE_EVENT_TYPES.PAGE_VIEW, { 'name': PAGE_NAMES.ALERTS });
        this.state = {
            ticker: '',
            price: 0,
            stockNames: {},
            ticker_prices: {}
        };
    }

    componentDidMount = async () => {
        let stockNames = await getStockNames();
        this.setState({ stockNames: stockNames });
    }

    tickerSubmit = async (symbol) => {
        let ticker = symbol.toUpperCase();
        let data = await fetchRealtimeData(ticker);
        let price = data['Latest Price'];
        this.setState({ ticker: ticker, price: price });
    }

    addAlert = async (alertType) => {
        let ticker = this.state.ticker;
        let price = this.state.price;
        let alert_data = {
            data: [
                {
                    "ticker": ticker,
                    "target_price": price
                }
            ]
        };
        let response_data = await addTickerAlert(alertType, alert_data);
        let data = response_data.data.alert_data;
        this.setState({ ticker: '' });
        this.props.refreshAlerts(data);
    }

    deleteAlert = async (alertType, ticker) => {
        let alert_data = {
            ticker_list: [ticker]
        };
        let response_data = await deleteTickerAlert(alertType, alert_data);
        let data = response_data.data.alert_data;
        this.props.refreshAlerts(data);
    }

    refreshTickerPrices = async (ticker_prices) => {
        let ticker_list = Object.keys(ticker_prices);
        if (ticker_list.length > 0) {
            let requestDetails = {
                method: 'GET',
                url: REALTIME_URL + ticker_list.join(',')
            }
            let data = (await executeRestRequest(requestDetails))['data'];
            if ((data || null) && data['tickers']) {
                let tickers = data.tickers;
                for (let i = 0; i < ticker_list.length; i++) {
                    let ticker = ticker_list[i];
                    if (Object.keys(tickers).includes(ticker)) {
                        ticker_prices[ticker] = {}
                        ticker_prices[ticker]['price'] = tickers[ticker].realtime['Latest Price'];
                        ticker_prices[ticker]['change'] = tickers[ticker].realtime['Latest Change'];
                    }
                }
                this.setState({ ticker_prices: ticker_prices });
            }
        }
    }

    render = () => {
        if (!this.props.isLoggedIn) {
            return <Row><Col xl="auto" className="mx-auto text-primary" ><h3>Please login to use this feature...</h3></Col></Row>;
        }
        let tickerAlertData = this.props.alerts;
        let tickerAlerts = [];
        let alertTypes = [];

        if ('buy' in tickerAlertData && tickerAlertData['buy'].length > 0) {
            tickerAlerts.push(tickerAlertData['buy']);
            alertTypes.push('Buy Alerts');
        }
        if ('sell' in tickerAlertData && tickerAlertData['sell'].length > 0) {
            tickerAlerts.push(tickerAlertData['sell']);
            alertTypes.push('Sell Alerts');
        }
        let stockNames = this.state.stockNames;
        let refresh = false;
        let ticker_prices = this.state.ticker_prices;
        let priceTickers = Object.keys(ticker_prices);
        for (let i = 0; i < tickerAlerts.length; i++) {
            let tickerAlert = tickerAlerts[i];
            for (let j = 0; j < tickerAlert.length; j++) {
                let ticker = tickerAlert[j]['ticker'];
                tickerAlert[j]['company_name'] = stockNames[ticker];
                if (priceTickers.includes(ticker)) {
                    tickerAlert[j]['current_price'] = ticker_prices[ticker]['price'];
                    let alertType = alertTypes[i];
                    if (alertType === 'Buy Alerts') {
                        tickerAlert[j]['difference'] = (tickerAlert[j]['target_price'] - tickerAlert[j]['current_price']) / (tickerAlert[j]['current_price'])
                        if ((tickerAlert[j]['current_price'] - tickerAlert[j]['target_price']) <= 0) {
                            tickerAlert[j]['success'] = 1;
                        } else {
                            tickerAlert[j]['success'] = 0;
                        }
                    } else {
                        tickerAlert[j]['difference'] = (tickerAlert[j]['current_price'] - tickerAlert[j]['target_price']) / (tickerAlert[j]['target_price'])
                        if ((tickerAlert[j]['current_price'] - tickerAlert[j]['target_price']) >= 0) {
                            tickerAlert[j]['success'] = 1;
                        } else {
                            tickerAlert[j]['success'] = 0;
                        }
                    }
                    tickerAlert[j]['current_price'] = formatNumber(tickerAlert[j]['current_price']) +
                        ' (' + ticker_prices[ticker]['change'] + ')';
                } else {
                    refresh = true;
                    ticker_prices[ticker] = 0;
                }
            }
        }
        if (refresh) {
            this.refreshTickerPrices(ticker_prices);
        } else {
            for (let i = 0; i < tickerAlerts.length; i++) {
                tickerAlerts[i].sort((a, b) => {
                    return b.difference - a.difference;
                })
            }
        }

        let columnList = [
            { name: "ticker" },
            { name: "company_name" },
            { name: "current_price", type: "currency" },
            { name: "target_price", type: "currency" },
            { name: "difference", type: "percentage" }
        ];

        return <Row className="px-2 py-2">
            <Col>
                <Row className="mb-4">
                    <Col xl={6} className="mx-auto my-2"><TickerAutoComplete onSubmit={this.tickerSubmit} /></Col>
                </Row>
                {
                    this.state.ticker === '' ? '' :
                        <Row className="my-2">
                            <Col xl="auto" className="mx-auto" ><Row>
                                <Col xl="auto" className="text-primary my-auto"><strong>{this.state.ticker}</strong></Col>
                                <Col xl="auto" className="text-primary my-auto">{this.state.stockNames[this.state.ticker]}</Col>
                                <Col xl="auto" ><Input type='number' size="sm" placeholder='Target Price' value={this.state.price}
                                    onChange={(e) => { this.setState({ price: e.target.value }) }} /></Col>
                                <Col xl="auto" className="my-auto"><Row>
                                    {
                                        [['buy', 'Buy'], ['sell', 'Sell']].map(x => (
                                            <Col xl="auto">
                                                <Badge color="primary" onClick={(e) => this.addAlert(x[0])}
                                                    style={{ cursor: 'pointer' }}>{x[1]}</Badge>
                                            </Col>
                                        ))
                                    }
                                </Row></Col>
                            </Row></Col>
                        </Row>
                }
                {
                    tickerAlerts.map((x, i) => (
                        <Row className="mb-4">
                            <Col xl={10} className="bg-primary text-white text-center rounded mx-auto">{alertTypes[i]}</Col>
                            <Col xl={10} className="my-2 border rounded mx-auto">
                                <TableHeader />
                                {
                                    x.map(y => (
                                        <LucidRow columnList={columnList}
                                            record={y}
                                            deleteAlert={this.deleteAlert}
                                            alertType={i === 0 ? 'buy' : 'sell'}
                                            is_success={y['success'] === 1 ? true : false}
                                        />
                                    ))
                                }
                            </Col>
                        </Row>
                    ))
                }
            </Col>
        </Row>;
    }
}

class LucidRow extends Component {
    render() {
        var columnList = this.props.columnList;
        var record = this.props.record;
        var is_success = this.props.is_success;

        return <>
            <Row className={is_success ? "text-success" : "text-info"}>
                {
                    columnList.map((column, i) => (
                        <LucidColumn
                            columnValue={column.name in record ? record[column.name] : ""}
                            columnType={'type' in column ? column.type : ""}
                            is_success={is_success}
                            ticker={i === 0 ? true : false}
                        />
                    ))
                }
                <Col><object type="image/jpg" data={IMG_CONTAINER + 'delete.png'} className="rounded-circle"
                    onClick={(e) => this.props.deleteAlert(this.props.alertType, record.ticker)}
                    style={{ maxHeight: '15px', maxWidth: '15px', float: 'right', cursor: 'pointer' }} aria-label="Delete" />
                </Col>
            </Row>
            <hr className="my-0" />
        </>;
    }
}

class LucidColumn extends Component {
    render() {
        let columnValue = this.props.columnValue;
        let columnType = this.props.columnType;
        let is_success = this.props.is_success;

        columnValue = columnType === "currency" ? formatNumber(columnValue) : formatPercent(columnValue);
        if (this.props.ticker) {
            columnValue = <a href={window.location.protocol + '/home/' + columnValue} target="_blank" rel="noopener noreferrer">
                {columnValue}
            </a>;
        }
        return <Col className="text-truncate">
            {is_success ? <strong>{columnValue}</strong> : <>{columnValue}</>}
        </Col>;
    }
}

class TableHeader extends Component {
    render() {
        return (
            <strong>
                <Row className="text-primary">
                    <Col>Ticker</Col>
                    <Col>Name</Col>
                    <Col>Current Price</Col>
                    <Col>Target Price</Col>
                    <Col>Difference</Col>
                    <Col></Col>
                </Row>
            </strong>
        );
    }
}

export default withRouter(Alerts);