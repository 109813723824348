import React, { Component } from 'react';
import { Row, Col, Card, CardText } from 'reactstrap';
import { isMobile } from "react-device-detect";

export default class RatingsDisplay extends Component {

    ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 1
            }}
        />
    );
    
    topRatings = ['outperform', 'overweight', 'buy', 'positive', 'strong buy'];
    bottomRatings = ['reduce', 'sell', 'underperform', 'underweight', 'strong sell', 'negative'];

    render = () => {
        let ratings = this.props.ratings;
        if (!(ratings || null) || !Array.isArray(ratings) || ratings.length < 1)
            return '';
        let getRatingColor = (rating) => {
            rating = rating.toLowerCase();            
            return this.topRatings.includes(rating) ? 'text-success' :
                this.bottomRatings.includes(rating) ? 'text-danger' : '';
        }
        let ratingsRender = ratings.map(ratingsItem => (
            <Row className={getRatingColor(ratingsItem.newGrade)}>
                <Col className="mx-0 pl-1">
                    {ratingsItem.newGrade}
                </Col>
                <Col className="mx-0 px-0 text-truncate">
                    {ratingsItem.gradingCompany}
                </Col>
                <Col xl="auto" md="auto" xs="auto" className="mx-0 pr-1">
                    {ratingsItem.date.substr(5,)}
                </Col>
            </Row>
        ));
        return <Row className={"border rounded px-1 mt-1 py-0 mx-0" + (isMobile ? "" : " mx-0")}>
            <Col xl={12} md={12} sm={12} xs={12} className="mx-0 px-0">
                <Card className={"mb-1 h-100% w-100% bg-secondary"}>
                    <CardText className="px-0 py-0 text-center text-white">
                        Analyst ratings
                    </CardText>
                </Card>
            </Col>
            <Col style={{ "overflow-y": "scroll", "height": "250px" }}>
                <Row className="text-primary font-weight-bold">
                    <Col className="mx-0 pl-1">Rating</Col>
                    <Col className="mx-0 pl-1">Rated By</Col>
                    <Col xl="auto" md="auto" xs="auto" className="mx-0 pr-1">Date</Col>
                </Row>
                {ratingsRender}
            </Col>
            </Row>;
    }
}