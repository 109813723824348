import React from 'react';
import { IMG_CONTAINER, IMAGE_URLS } from '../../common/url';
export const PAGE_NAMES = ['home', 'watchlist', 'research']; 
export const MAIN_MENU = [
    {
        name: 'Home',
        path: '/home',
        icon: <object type="image/jpg" data={IMG_CONTAINER + IMAGE_URLS.HOME}
            style={{ maxHeight: '20px', maxWidth: '20px', cursor: 'pointer' }} aria-label="Home" />
    },
    {
        name: 'Watchlist',
        path: '/watchlist',
        icon: <object type="image/jpg" data={IMG_CONTAINER + IMAGE_URLS.WATCHLIST}
            style={{ maxHeight: '20px', maxWidth: '20px', cursor: 'pointer' }} aria-label="Watchlist" />
    },
    {
        name: 'Research',
        path: '/research',
        icon: <object type="image/jpg" data={IMG_CONTAINER + IMAGE_URLS.RESEARCH}
            style={{ maxHeight: '20px', maxWidth: '20px', cursor: 'pointer' }} aria-label="Research" />
    },
    { 
        name: 'Earnings',
        path: '/earnings',
        icon: <object type="image/jpg" data={IMAGE_URLS["CALENDAR"]}
            style={{ maxHeight: '20px', maxWidth: '20px', cursor: 'pointer' }} aria-label="Earnings" />
    },
    { 
        name: 'Alerts',
        path: '/alerts',
        icon: <object type="image/jpg" data={IMG_CONTAINER + IMAGE_URLS['ALERT']}
            style={{ maxHeight: '20px', maxWidth: '20px', cursor: 'pointer' }} aria-label="Alerts" />
    },
    { 
        name: 'Movers',
        path: '/movers',
        icon: <object type="image/jpg" data={IMG_CONTAINER + IMAGE_URLS['MOVERS']}
            style={{ maxHeight: '20px', maxWidth: '20px', cursor: 'pointer' }} aria-label="Movers" />
    },
    { 
        name: 'Index',
        path: '/index',
        icon: <object type="image/jpg" data={IMG_CONTAINER + IMAGE_URLS['INDEX']}
            style={{ maxHeight: '20px', maxWidth: '20px', cursor: 'pointer' }} aria-label="Index" />
    }
    /** 
    {
        name: 'Covid19',
        path: '/covid19',
        icon: <object type="image/jpg" data={IMG_CONTAINER + IMAGE_URLS.COVID_19}
            style={{ maxHeight: '20px', maxWidth: '20px', cursor: 'pointer' }} aria-label="" />
    } 
    */
];

export async function get_current_month_name() {
    const monthNames = ["january", "february", "march", "april", "may", "june",
        "july", "august", "september", "october", "november", "december"
    ];
    var today = new Date();
    var current_month_number = today.getMonth();  
    return monthNames[current_month_number].toUpperCase();
}

