import React from 'react';
import { LOGOUT_URL } from '../../common/url';
import { executeRestRequest } from '../../common/restService';
import { withNavigateHook } from '../../common/navigate';

class Logout extends React.Component {

    async componentDidMount() {

        var logoutRequest = {
            url: LOGOUT_URL,
            method: 'GET',        
        }
        await executeRestRequest(logoutRequest);    
        this.props.navigation({ pathname: '/'});
        this.props.logout();  

    }   

    render() {
        return null;
    }
}


export default withNavigateHook(Logout);