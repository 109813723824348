import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { executeRestRequest } from '../../common/restService';
import StockScreenInput from "./stockScreenInput";
import StockScreenResultDisplay from "./stockScreenResultDisplay";
import { SEARCH_OBJECT_KEY, TWELVE_HOURS_IN_SECONDS } from "../../common/constants";
import { CLOSING_PRICE_URL } from '../../common/url';
import { get_object, set_object, remove_Object } from '../../common/localStorage';
import { get_default_filters_deep_clone, get_deep_clone, filter_stock_data, addPriceToSales, getTickerSearchData } from "./util";
import { arrToObject } from "../../common/randomUtil";
import { getStockNames } from "../../common/storageUtil";


export default class StockScreen extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            show_spinner: false,
            returnData: [],
            stockData: [],
            renderData: [],
            stockNames: {},
            previous_request: {}
        }
    }

    componentDidMount = async () => {
        remove_Object(SEARCH_OBJECT_KEY);
        this.setState({ show_spinner: true });
        let stockData = await get_object(SEARCH_OBJECT_KEY);
        let stockNames = await getStockNames();
        if (!(stockData || null)) {
            stockData = await getTickerSearchData({});
            addPriceToSales(stockData);
            set_object(SEARCH_OBJECT_KEY, stockData, TWELVE_HOURS_IN_SECONDS);
        }

        let filters = arrToObject(get_default_filters_deep_clone(true));
        let filteredData = await filter_stock_data(stockData, filters);
        this.setState({
            stockData: stockData,
            renderData: filteredData,
            stockNames: stockNames,
            previous_request: filters,
            show_spinner: false
        });
    }

    onSubmithandler = async (filterCondition, returnEnabled) => {
        if (returnEnabled) {
            // Check current request dates with previous request dates if different then only make request. 
            let previous_request = this.state.previous_request;
            let stockData = this.state.stockData;
            if ('return' in filterCondition) {
                if ('start date' in filterCondition.return) {
                    if (!((previous_request || null) &&
                        'return' in previous_request &&
                        'start date' in previous_request.return &&
                        previous_request.return['start date'] === filterCondition.return['start date'])
                    ) {
                        this.setState({ show_spinner: true });
                        let closing_price_data = await this.getClosingPriceData(filterCondition.return['start date'], false);
                        stockData = await this.update_return_data(stockData, closing_price_data, 'start date');
                    }
                }
                if ('end date' in filterCondition.return) {
                    if (!((previous_request || null) &&
                        'return' in previous_request &&
                        'end date' in previous_request.return &&
                        previous_request.return['end date'] === filterCondition.return['end date'])
                    ) {
                        this.setState({ show_spinner: true });
                        let closing_price_data = await this.getClosingPriceData(filterCondition.return['end date'], true);
                        stockData = await this.update_return_data(stockData, closing_price_data, 'end date');
                    }
                }
            }
            let filteredData = await filter_stock_data(stockData, filterCondition);
            this.setState({
                stockData: stockData,
                renderData: filteredData,
                previous_request: get_deep_clone(filterCondition, false),
                show_spinner: false
            });
        } else { // Filter render data based on condition
            let filteredData = await filter_stock_data(this.state.stockData, filterCondition)
            this.setState({
                renderData: filteredData,
                previous_request: get_deep_clone(filterCondition, false),
                show_spinner: false
            });
        }
    }

    update_return_data = async (stockData, closing_price_data, dt) => {
        stockData.forEach(function (data) {
            if (data.symbol in closing_price_data) {
                if (dt === 'start date')
                    data["begin price"] = closing_price_data[data.symbol];
                else
                    data["end price"] = closing_price_data[data.symbol];
                data["return"] = (data["end price"] - data["begin price"]) / data["begin price"];
            } else {
                if (dt === 'start date')
                    data["begin price"] = null;
                else
                    data["end price"] = null;
                data["return"] = null;
            }
        });
        return stockData;
    }

    async getClosingPriceData(date_str, is_end_date) {
        let is_forward = is_end_date ? false : true
        let closingPriceRequest = {
            url: CLOSING_PRICE_URL + date_str + "?forward=" + is_forward,
            method: 'GET',
        };
        let closingPriceResponse = await executeRestRequest(closingPriceRequest);
        if ('data' in closingPriceResponse) {
            return closingPriceResponse.data.ClosingPriceData;
        }
        return {};
    }

    render() {
        return (
            <>
                <StockScreenInput onSubmit={this.onSubmithandler} />
                {this.state.show_spinner ?
                    <div height="500px" align="center" vAlign="center">
                        <Spinner color="primary" animation="border" role="status" />
                    </div>
                    :
                    <StockScreenResultDisplay renderData={this.state.renderData}
                        stockNames={this.state.stockNames}
                        refreshWatchList={this.refreshWatchList}
                        {...this.props} />
                }
            </>
        );
    }
}