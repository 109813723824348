export const DEFAULT_FILTERS = [
    {
        return: {
            low: -0.8,
            high: 10,
            'start date': new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()),
            'end date': new Date()
        }
    },
    {
        beta: {
            low: 0.4,
            high: 4
        }
    },
    {
        mktCap: {
            low: 5000000000,
            high: 2000000000000
        }
    },
    {
        'revenue ttm': {
            low: 100000000,
            high: 900000000000
        }
    },
    {
        'net income ttm': {
            low: 10000000,
            high: 90000000000
        }
    },
    {
        'p/e': {
            low: 3,
            high: 100
        }
    },
    {
        'p/s': {
            low: 0.05,
            high: 24
        }
    },      
    {
        index_group: []
    },
    {
        sector: []
    },
    {
        industry: []
    }
];

export const RANGE_METRICS_RENDER_MAP = {
    'return': {
        label: 'Return (%)',
        low_id: 'return_low',
        high_id: 'return_high'
    },
    'beta': {
        label: 'Beta',
        low_id: 'beta_low',
        high_id: 'beta_high'
    },
    'mktCap': {
        label: 'Market Cap',
        low_id: 'mkt_cap_low',
        high_id: 'mkt_cap_high'
    },
    'p/e': {
        label: 'P/E',
        low_id: 'pe_low',
        high_id: 'pe_low'
    },
    'p/s': {
        label: 'P/S',
        low_id: 'ps_low',
        high_id: 'ps_low'
    },
    'revenue ttm': {
        label: 'Revenue TTM',
        low_id: 'revenue_ttm_low',
        high_id: 'revenue_ttm_high'
    },
    'net income ttm': {
        label: 'Net Income TTM',
        low_id: 'net_income_low',
        high_id: 'net_income_high'
    },
};

export const LIST_METRICS_DISPLAY_NAMES = {
    'sector': {
        label: 'Sector'
    },
    'index_group': {
        label: 'Index Group'
    },
    'industry': {
        label: 'Industry'
    }
};

export const TAB_NAMES = ['Stock Screener', 'Index Stocks'];

export const METRICS_NAME_LOOKUP = {
    symbol: "Symbol",
    return: "Return",
    'return 1 yr': '1Y return',
    mktCap: "Market Cap",
    'revenue ttm': "Revenue",
    'net income ttm': 'Net Income',
    beta: "Beta",
    'p/e': 'P/E',
    'p/s': 'P/S',
    range: 'Price Range',
    'range 1 yr': '1Y range',
    sector: "Sector/Industry",
    'realtime': 'Price'
};

export const SORTABLE_METRICS = ['symbol', 'return', 'mktCap', 'revenue ttm', 'net income ttm', 'beta', 'p/e', 'p/s'];

export const METRICS_DISPLAY_ORDER = ['symbol', 'realtime', 'p/e', 'return', 'p/s', 'mktCap', 'revenue ttm', 'net income ttm', 'beta', 'sector'];