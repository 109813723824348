import React, { Component } from 'react';
import { Row, Col, Card, Spinner, CardText } from "reactstrap";
import { isMobile } from 'react-device-detect';


export default class RelatedStocks extends Component {

    render() {
        let cardDataArray = [];
        let data = this.props.data;
        if (!(data || null) || Object.keys(data).length < 1)
            return '';

        for (const ticker in data) {
            let cardData = {};
            if (data[ticker] || null) {
                cardData.title = ticker;
                if (data[ticker]['realtime']['Latest Change'] || null) {
                    let change_value = data[ticker]['realtime']['Latest Change'];
                    change_value = (change_value || null) ? change_value.toFixed(1) :
                        change_value.toFixed(1);
                    cardData.cardClass = "mt-2 text-center+ " +
                        (change_value > 0 ? "bg-success" : (change_value === 0 ? "bg-light" : "bg-danger")) +
                        " text-white h-100% w-100%";
                    cardData.change = change_value;
                }
                cardData.current = data[ticker]['realtime']['Latest Price']
                cardDataArray.push(cardData);
            }
        }

        return (
            <Row>
                <Col xl={12} md={12} sm={12} xs={12} className="mx-0 px-0">
                <Card className={"mb-1 h-100% w-100% bg-secondary"}>
                    <CardText className="px-1 py-0 text-center text-white">
                        Similar
                    </CardText>
                </Card>
            </Col>
                {cardDataArray.map((cardData, index) => (
                    <Col xl={12} md={2} sm={4} xs={6} style={{ cursor: 'pointer' }} className="mx-0 px-0"
                        onClick={() => this.props.openTicker(cardData.title)}>
                        <Card className={cardData.cardClass}>
                            {this.createText(cardData)}
                        </Card>
                    </Col>
                ))}
            </Row>
        );
    }

    createText(data) {
        if (!(data || null)) {
            return <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>;
        }
        let change_value = data.change;
        let change_percent = change_value / (data.current - change_value) * 100.0;
        change_percent = change_percent.toFixed(1);
        change_percent = "(" + change_percent + "%)";
        if (change_value > 0) {
            change_value = "+" + change_value
        }
        let colTitle = <Col xl={12} md={12} sm={12} xs={12}>
            <strong>{data.title}</strong>
        </Col>;
        let colValue = <Col xl={12} md={12} sm={12} xs={12}>
            <strong>${data.current.toLocaleString('en')}</strong>
        </Col>;
        let colChange = <Col xl={12} md={12} sm={12} xs={12}>
            {change_value}<small>{' '}{change_percent}</small>
        </Col>;
        return <h6>
            <Row className="px-0 py-0 text-center">
                {isMobile ?
                    <>{colTitle}{colChange}{colValue}</> :
                    <>{colTitle}{colValue}{colChange}</>
                }
            </Row>

        </h6>;
    }
}