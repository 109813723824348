import React, { Component } from 'react';
import { Row, Col, Card, Spinner } from "reactstrap";
import { executeRestRequest } from '../../common/restService';
import { is_market_open } from '../../common/timeUtil';
import { INDEX_URL, MARKET_STATUS_URL } from '../../common/url';
import { registerCall, deregisterCall } from '../../common/timerUtil';
import { isMobile } from 'react-device-detect';


const realtime_refersh_interval_seconds = 20;
const market_open_check_interval_seconds = 3 * 60;


export default class Index extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            index_data: {},
            indexIntervalId: "",
            marketOpenIntevalId: "",
        }
        this.show_index = this.show_index.bind(this);
    }

    stopAutoRefresh = () => {
        if (this.state.indexIntervalId !== "") {
            deregisterCall(this.state.indexIntervalId);

        }
    }

    // Setup the `beforeunload` event listener
    setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            return this.stopAutoRefresh();
        });
    }

    componentDidMount = async () => {
        await this.show_index();
        let response = await executeRestRequest({ url: MARKET_STATUS_URL, method: 'GET' });
        let data = response.data;
        if ((data || null) && data['is open']) {
            let toCloseTime = data['to close time'];
            let indexIntervalId = registerCall(this.show_index, 10, toCloseTime + 5 * 60);
            this.setState({ indexIntervalId: indexIntervalId });
        }
        // Activate the event listener
        //this.setupBeforeUnloadListener();
    }

    componentWillUnmount() {
        if (this.state.indexIntervalId !== "")
            deregisterCall(this.state.indexIntervalId);
    }

    async show_index() {
        let response = await executeRestRequest({ url: INDEX_URL, method: 'GET' });
        let data = response.data;
        if ((data || null) && Object.keys(data).length > 0) {
            this.setState({ index_data: data.index });
        } else {
            // If error then print it.
            /* if ('errorMessage' in data) {
                 console.log(response.errorMessage);
             } */
        }
    }

    async start_auto_update_timers() {

        let is_stock_market_open = await is_market_open();
        let indexIntervalId = null;
        if (is_stock_market_open) {
            try {
                indexIntervalId = setInterval(async () => {
                    this.setState({ indexIntervalId: indexIntervalId });
                    this.show_index();
                }, realtime_refersh_interval_seconds * 1000);
                await this.sleep(500);
            } catch (e) {
                console.log(e);
            }

            let marketOpenIntevalId = setInterval(async () => {
                this.setState({ marketOpenIntevalId: marketOpenIntevalId });
                is_stock_market_open = await is_market_open();
                if (!is_stock_market_open) {
                    clearInterval(indexIntervalId);
                    clearInterval(marketOpenIntevalId);
                }
            }, market_open_check_interval_seconds * 1000);
        }
    }


    render() {
        let requiredIndex = ['.DJI', '.INX', '.IXIC']
        let alt_requiredIndex = ['DJIA', 'S&P 500', 'NASDAQ']
        let indexData = this.state.index_data;
        let cardDataArray = [];

        if (!(indexData || null) || Object.keys(indexData).length < 1)
            return '';
        for (let i = 0; i < requiredIndex.length; i++) {
            let cardData = {};
            let index = requiredIndex[i];
            let data = ((index in indexData) && (indexData[index] || null)) ?
                indexData[index] :
                indexData[alt_requiredIndex[i]];
            if (data || null) {
                cardData.title = alt_requiredIndex[i];
                let change_value = (data.change || null) ? data.change.toFixed(1) : data.changes.toFixed(1);
                cardData.cardClass = "mt-2 text-center+ " +
                    (change_value > 0 ? "bg-success" : (change_value === 0 ? "bg-light" : "bg-danger")) +
                    " text-white h-100% w-100%";
                cardData.current = (data.price || null) ? data.price : data.current;
                cardData.change = change_value;
                cardDataArray.push(cardData);
            }
        }
        //alert(JSON.stringify(cardDataArray));

        return (
            <Row>
                {cardDataArray.map((cardData, index) => (
                    <Col xl={12} md={4} sm={12} xs={12}>
                        <Card className={cardData.cardClass}>
                            {this.createText(cardData)}
                        </Card>
                    </Col>
                ))}
            </Row>
        );
    }

    createText(data) {
        if (!(data || null)) {
            return <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>;
        }
        let change_value = data.change;
        let change_percent = change_value / (data.current - change_value) * 100.0;
        change_percent = change_percent.toFixed(1);
        change_percent = "(" + change_percent + "%)";
        if (change_value > 0) {
            change_value = "+" + change_value
        }
        let colTitle = <Col xl={12} md={6} sm={6} xs={6}>
            <strong>{data.title}</strong>
        </Col>;
        let colValue = <Col xl={12} md={6} sm={6} xs={6}>
            <strong>{data.current.toLocaleString('en')}</strong>
        </Col>;
        let colChange = <Col xl={12} md={6} sm={6} xs={6}>
            {change_value}
        </Col>;
        let colPercent = <Col xl={12} md={6} sm={6} xs={6}>
            <small>{change_percent}</small>
        </Col>;
        return <h6>
            <Row className="px-0 py-0 text-center">
                {isMobile ?
                    <>{colTitle}{colChange}{colValue}{colPercent}</> :
                    <>{colTitle}{colValue}{colChange}{colPercent}</>
                }
            </Row>

        </h6>;
    }
}