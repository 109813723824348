
export function memorySizeOf(obj) {
    var bytes = 0;

    function sizeOf(obj) {
        if(obj !== null && obj !== undefined) {
            switch(typeof obj) {
            case 'number':
                bytes += 8;
                break;
            case 'string':
                bytes += obj.length * 2;
                break;
            case 'boolean':
                bytes += 4;
                break;
            case 'object':
                var objClass = Object.prototype.toString.call(obj).slice(8, -1);
                if(objClass === 'Object' || objClass === 'Array') {
                    for(var key in obj) {
                        if(!obj.hasOwnProperty(key)) continue;
                        sizeOf(obj[key]);
                    }
                } else bytes += obj.toString().length * 2;
                break;
            default: break;
            }
        }
        return bytes;
    };

    function formatByteSize(bytes) {
        if(bytes < 1024) return bytes + " bytes";
        else if(bytes < 1048576) return(bytes / 1024).toFixed(3) + " KiB";
        else if(bytes < 1073741824) return(bytes / 1048576).toFixed(3) + " MiB";
        else return(bytes / 1073741824).toFixed(3) + " GiB";
    };

    return formatByteSize(sizeOf(obj));
};

export const arrToObject = arr => {
    let obj = {};
    for(let i = 0; i < arr.length; i++) {
        Object.assign(obj, arr[i]);
    }
    return obj;
}

export function get_deep_clone(obj) {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }
    // give temporary-storage the original obj's constructor
    var clonedObj = obj.constructor();
    for (var key in obj) {
        clonedObj[key] = get_deep_clone(obj[key]);
    } 
    return clonedObj;
}

export function openInNewTab(url) {
    let finalUrl = url;
    if (finalUrl.substring(0,4).toLowerCase() !== 'http')
        finalUrl = 'https://' + finalUrl;
    alert(finalUrl);
    let win = window.open(finalUrl, '_blank');
    win.focus();
  }

